import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { clevercruitlogo } from '../../assets/imgs';

const Image = () => {
  const [isLogoVisible, setIsLogoVisible] = useState(true);
  const navigate=useNavigate();

//   useEffect(() => {
//     const timer = setTimeout(() => {
//       setIsLogoVisible(false); // Hide the logo after 2 seconds
//       navigate('../interview');
//     }, 2000);

//     return () => clearTimeout(timer); // Clean up the timer if the component unmounts
//   }, []); // Empty dependency array ensures this runs only once when the component mounts

  return (
    <div className='h-screen w-screen flex justify-center items-center backdrop-blur'>
      {isLogoVisible && (
        <img src={clevercruitlogo} alt="Clevercruit Logo" className='w-[906px] h-[140px]'/>
      )}
    </div>
  );
};

export default Image;
