import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getLocal, setLocal } from '../../utils/localStorage';
import {
  activeIcon,
  logout,
  logoutbubble,
  plusIcon,
  profileImage,
  settingLogo2,
  settingLogoutLogo,
  settings,
  userImg,
} from '../../assets/imgs';
import { Modal1 } from './modal';

export const SideBar = ({ setLogout, isShowPopup, setIsShowPopup }) => {
  const location = useLocation();
  const admin = sessionStorage.getItem('notPermited');
  const [isProfilePageVisible, setIsProfilePageVisible] = useState(false);
  const [openMember, setOpenMember] = useState(false);

  const [section, setSection] = useState(() => {
    const storedValue = getLocal('adminSection');
    return storedValue ? JSON.parse(storedValue) : 1;
  });
  const navigate = useNavigate();
  const last_name = getLocal('last_name');
  const first_name = getLocal('first_name');

  useEffect(() => {
    setLocal('adminSection', JSON.stringify(section));
  }, [section]);

  const showProfilePage = () => {
    if (!isProfilePageVisible) {
      navigate('/profile');
      setIsProfilePageVisible(true);
    } else {
      navigate('/admin/dashboard');
      setIsProfilePageVisible(false);
    }
  };

  const showPopup = () => {
    setIsShowPopup(!isShowPopup);
  };

  const openAddMember = () => {
    setOpenMember(true);
  };

  const closeAddMember = () => {
    setOpenMember(false);
  };

  const current_location = location.pathname.split('/')[2];

  useEffect(() => {
     switch (current_location) {
       case 'members':
         setSection(2);
         break;
       case 'dashboard':
         setSection(1);
         break;
       case 'campaign':
         setSection(4);
         break;
       case 'jobrole':
         setSection(3);
         break;
     }
    
  }, [current_location]);

  return (
    <div className="flex flex-col justify-between items-center fill-available-h py-4">
      {openMember && <Modal1 isOpen={openAddMember} onClose={closeAddMember} />}
      <div className="flex flex-col gap-2 ml-[1rem]">
        <div className="relative">
          <img
            src={
              sessionStorage.getItem('profileImage')
                ? sessionStorage.getItem('profileImage')
                : profileImage
            }
            alt="userImg"
            className="w-[8rem] h-[8rem] rounded-full"
          />
          <button
            className="absolute border-2 bg-blue-500 bottom-0 right-0 rounded-full p-2 "
            onClick={showPopup}
          >
            <img
              className="w-[1.8rem] relative"
              src={settings}
              alt="settings"
            />
          </button>
          {isShowPopup && (
            // <img src={logoutbubble}  className="absolute  w-[24rem] h-[24rem]"alt="logout bubble"/>
            <span className="text-[#718096] px-[4rem] py-4 flex flex-col gap-2 justify-around items-center absolute z-10 left-[105%] top-[60%] rounded-2xl border-[2px] bg-white">
              <svg
                className="absolute left-[-0.5rem] top-[1.4rem] "
                width="10"
                height="14"
                xmlns="http://www.w3.org/2000/svg"
              >
                {/* <!-- Triangle with vertices at (50, 150), (100, 50), and (150, 150) --> */}
                <line
                  x1="7"
                  y1="8"
                  x2="7"
                  y2="3"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                />
                <line
                  x1="2"
                  y1="6"
                  x2="7"
                  y2="8"
                  stroke="#e5e7eb"
                  stroke-width="2"
                  stroke-linecap="round"
                />
                <line
                  x1="2"
                  y1="6"
                  x2="7"
                  y2="3"
                  stroke="#e5e7eb"
                  stroke-width="2"
                  stroke-linecap="round"
                />
              </svg>
              <button
                onClick={() => showProfilePage()}
                className="flex w-max gap-2 items-center"
              >
                {' '}
                <img src={settingLogo2} alt="settings" /> Settings
              </button>
              <div className="border-[1px] w-[190%] h-0"></div>
              <button
                onClick={() => {
                  setLogout(true);
                  showPopup();
                }}
                className="flex w-max gap-2 items-center"
              >
                {' '}
                <img src={settingLogoutLogo} alt="settings" /> Logout
              </button>
            </span>
          )}
        </div>

        <div className=" text-center">
          <p className="font-bold text-[24px]">
            {first_name} {last_name}
          </p>
          <p>Designation</p>
        </div>
      </div>

      <div className=" flex flex-col gap-1 cursor-pointer">
        <div className="flex items-center">
          <div
            className={`font-bold flex gap-3 pr-5 p-2 ${
              section === 1
                ? 'text-[#0875F4] border-2 rounded-3xl border-[#0875F4] w-[12rem] ml-[5.5px]'
                : 'text-[#181D3C] pl-[4rem] w-[11rem]'
            }`}
            onClick={() => {
              setSection(1);
              navigate('/admin/dashboard');
              window.location.reload()
            }}
          >
            {section === 1 ? <img src={activeIcon} alt="icon" /> : null}
            <p>Dashboard</p>
          </div>
        </div>

        <div className="flex items-center">
          <div
            className={`font-bold flex gap-3 pr-5 p-2 ${
              section === 3
                ? 'text-[#0875F4] border-2 rounded-3xl border-[#0875F4] w-[12rem] ml-[5.5px]'
                : 'text-[#181D3C] pl-[4rem] w-[11rem]'
            }`}
            onClick={() => {
              setSection(3);
              navigate('/admin/jobrole');
            }}
          >
            {section === 3 ? <img src={activeIcon} alt="icon" /> : null}
            <p>Job Roles</p>
          </div>
        </div>

        <div className="flex items-center">
          <div
            className={`font-bold flex gap-3 pr-5 p-2 ${
              section === 4
                ? 'text-[#0875F4] border-2 rounded-3xl border-[#0875F4] w-[12rem] ml-[5.5px]'
                : 'text-[#181D3C]  pl-[4rem] w-[11rem]'
            }`}
            onClick={() => {
              setSection(4);
              navigate('/admin/campaign');
            }}
          >
            {section === 4 ? <img src={activeIcon} alt="icon" /> : null}
            <p>Campaigns</p>
          </div>
        </div>

        {admin === 'false' && (
          <div className="flex items-center">
            <div
              className={`font-bold flex gap-3 pr-5 p-2 ${
                section === 2
                  ? 'text-[#0875F4] border-2 rounded-3xl border-[#0875F4] w-[12rem] ml-[5.5px]'
                  : 'text-[#181D3C]  pl-[4rem] w-[11rem]'
              }`}
              onClick={() => {
                setSection(2);
                navigate('/admin/members');
              }}
            >
              {section === 2 ? <img src={activeIcon} alt="icon" /> : null}
              <p>Members</p>
            </div>
          </div>
        )}
      </div>
      <div className=" flex flex-col  border-2 border-dashed border-black p-2 rounded-3xl w-[90%] ">
        <div className="bg-blue-400 rounded-3xl gap-3 p-6 flex flex-col items-center ">
          <div className="flex flex-col items-center">
            <p>Add</p>
            <p>Member</p>
          </div>
          <button
            onClick={openAddMember}
            className=" flex items-center justify-center p-2 rounded-full bg-white"
          >
            <img src={plusIcon} alt="+" />
          </button>
        </div>
      </div>
    </div>
  );
};
