import React, { useEffect, useState } from 'react';
import { ScoreCard } from '../../components/AdminComponents/card';
import {
  membersGraph,
  coloredCircle,
  circle,
  campaignGraph,
  shortLogo,
  clockSvg,
  tickIcon,
  watchIcont,
  profileImage,
  optionIcon,
  pencilIcon,
  designbg,
} from '../../assets/imgs/index';
import {
  CampaignProgressBar,
  SingleCircularProgressBar,
  TripleCircularProgressBar,
} from '../../components/AdminComponents/progressBar';
import { handleApiResponse } from '../../API/services';
import { useNavigate } from 'react-router-dom';
import { FaPencilAlt } from 'react-icons/fa';
import { CampaignTable } from '../../components/AdminComponents/table';

export const Dashboard = () => {
  const navigate = useNavigate();
  const [kPIData, setKPIData] = useState(null);
  const [memberData, setMemberData] = useState([]);
  const [campDetails, setCampDetails] = useState([]);
  const [highestNumber, setHighestNumber] = useState(0);
  console.log('first', highestNumber);
  const [viewCampComp, setViewCampComp] = useState(false);
  const callKPI = async () => {
    const reqData = {}; //no data required for this KPI
    const response = await handleApiResponse(
      '/api/v1/interview/data/get_dashboard_kpi/',
      reqData,
      1
    );
    if (response?.code === 'token_not_valid') {
      navigate('/');
      sessionStorage.clear();
      return;
    }
    if (response.data.status_code === 200) {
      console.log(response.data.data);
      setKPIData(response.data.data);
      setCampDetails(response.data.data.campaigns_info);
    }
  };

  const getUser = async () => {
    const reqData = {
      is_deleted: 0,
    };
    const response = await handleApiResponse('/api/v1/userlist/', reqData, 1);
    if (response?.code === 'token_not_valid') {
      navigate('/');
      sessionStorage.clear();
      return;
    }
    console.log(response);
    if (response.data.status_code === 200) {
      //console.log("user displayed", Object.values(response?.data?.data).flat())
      let dataToFilter = response?.data?.data?.data;
      let hightestNumber = dataToFilter.map((ele) => {
        if(ele?.user_type === 3)
        return ele?.created_campaigns;
      });
      hightestNumber = Math.max(...hightestNumber);
      console.log('ih', highestNumber);
      setHighestNumber(hightestNumber);
      setMemberData(dataToFilter.filter((member) => member.user_type === 3));
    }
  };

  useEffect(() => {
    callKPI();
    getUser();
  }, []);

  return (
    <div className="text-[#222850] fill-available flex flex-col gap-4 justify-center ">
      {!viewCampComp && (
        <>
          <section className="flex justify-between gap-4  ">
            <HelperCard2 name={'Campaign Analytics'} KPIData={kPIData} />
            {/* <HelperCard3 name={'Total Campaigns'} number={"206"} KPIData={kPIData} backgroundImage={designbg} />
            <HelperCard3 name={'Completed Campaigns'} number={"206"} KPIData={kPIData} backgroundImage={designbg} /> */}

            <CampaignComposition
              memberData={memberData.filter((ele, index) => {
                if (index < 5) {
                  return ele;
                }
              })}
              number={highestNumber}
              setViewCampComp={setViewCampComp}
            />
          </section>
          <section className="flex justify-between gap-4">
            <div className="w-[65%]">
              <ViewCampaign />
            </div>
            <div className="w-[35%]">
              <ViewMemeber
                memberData={memberData.filter((ele, index) => {
                  if (index < 5) {
                    return ele;
                  }
                })}
              />
            </div>
          </section>
        </>
      )}
      {viewCampComp && (
        <>
          <section className="flex justify-between gap-4  ">
            <BigCampaignComposition
              memberData={memberData}
              number={highestNumber}
              setViewCampComp={setViewCampComp}
            />
          </section>
        </>
      )}
    </div>
  );
};

const CampaignComposition = ({ memberData, number, setViewCampComp }) => {
  console.log('<=>', number);
  const [yAxis, setYAxis] = useState([]);
  const [hover, setHover] = useState(false);

  let highestNumber = 0;
  if (number) {
    highestNumber = number;
  }

  if (highestNumber > 20 && highestNumber % 10 !== 0) {
    highestNumber = highestNumber + (-(highestNumber % 10) + 10);
  }
  console.log('data=>', highestNumber);
  console.log('high', highestNumber <= 20);
  let y_axis = [];
  useEffect(() => {
    if (highestNumber >= 20) {
      console.log('sm', Math.floor(highestNumber / 3.5));
      const dif = Math.floor(highestNumber / 3.9);
      let prev = -dif;
      for (let index = 0; index < 5; index++) {
        console.log('ind', index, '->', prev + dif);
        prev = prev + dif;
        y_axis.push(String(prev).length > 1 ? String(prev) : `0${prev}`);
      }

      setYAxis(y_axis.reverse());
    } else {
      setYAxis(['20', '15', '10', '5', '0']);
    }
    console.log('idx', y_axis);
  }, [highestNumber]);

  return (
    <div className="flex flex-col bg-white w-full px-8 rounded-3xl  ">
      <header className="flex justify-between  py-8">
        <i className="font-bold text-[20px]">Campaign Composition</i>
        <button onClick={() => setViewCampComp(true)}>View all</button>
      </header>
      <main className=" mb-6 relative">

        <div className="flex flex-col gap-4 ">
          {yAxis.length &&
            yAxis.map((ele, index) => {
              return (
                <span className="flex items-center gap-4 " key={index}>
                  <p>{ele}</p>
                 
                  <div
                    className={`border-[1px] fill-available h-0 border-blue-500 border-dashed ${
                      String(ele).length === 1 ? 'ml-2' : null
                    }`}
                  >
                  </div>
                </span>
              );
            })}
        </div>
        {/* 10rem = 20 camp */}
        {/* 0.5 rem = 1 camp */}
        {/* 10 rem = yAxis[0] camp */}
        {/* 1/parseInt(yAxis[0])*10 rem = 1 camp */}
        <div className="flex justify-around fill-available gap-2 pl-12 pr-4 ">
          {memberData.length > 0 &&
            yAxis.length > 0 &&
            memberData?.map((ele,index) => {
              return (
                <div
                  className=" flex justify-center relative items-center mt-[-0.7rem] border-black w-12 h-12"
                  onMouseEnter={() => setHover(index)}
                  onMouseLeave={() => setHover()}
                >
                   <span className={` ${hover === index?'':'hidden'} absolute top-10`}>{memberData[index]?.first_name}</span>
                  <img
                    className="w-7 h-7 rounded-full"
                    src={`${
                      ele?.profile_img ? ele?.profile_img : profileImage
                    }`}
                    alt=""
                  />
                  <div
                    className={` absolute bottom-[3rem] h-[0rem] border-[0.2rem]`}
                    style={{
                      height: `${
                        highestNumber <= 20
                          ? `${(ele.created_campaigns / 2).toFixed(1)}rem`
                          : `${(
                              ele.created_campaigns *
                              (1 / parseInt(yAxis[0])) *
                              10
                            ).toFixed(1)}rem`
                      }`,
                    }}
                  >
                    <div className="w-6 h-6 flex text-white justify-center top-[-1rem] left-[-0.7rem] rounded-full bg-blue-500 absolute">
                      {ele.created_campaigns}
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </main>
    </div>
  );
};

const BigCampaignComposition = ({ memberData, number, setViewCampComp }) => {
  console.log('<=>', number);
   const [hover, setHover] = useState(false);
  const [yAxis, setYAxis] = useState([]);
  let highestNumber = 0;
  if (number) {
    highestNumber = number;
  }

  if (highestNumber > 20 && highestNumber % 10 !== 0) {
    highestNumber = highestNumber + (-(highestNumber % 10) + 10);
  }
  console.log('data=>', highestNumber);
  console.log('high', highestNumber <= 20);
  let y_axis = [];
  useEffect(() => {
    if (highestNumber >= 50) {
      console.log('sm', Math.floor(highestNumber / 4));
      const dif = Math.floor(highestNumber / 10);
      let prev = -dif;
      for (let index = 0; index < 11; index++) {
        console.log('ind', index, '->', prev + dif);
        prev = prev + dif;
        y_axis.push(String(prev).length > 1 ? String(prev) : `0${prev}`);
      }

      setYAxis(y_axis.reverse());
    } else {
      setYAxis([
        '50',
        '45',
        '40',
        '35',
        '30',
        '25',
        '20',
        '15',
        '10',
        '5',
        '0',
      ]);
    }
    console.log('idx', y_axis);
  }, [highestNumber]);

  return (
    <div className="flex flex-col bg-white w-full h-full px-8 rounded-3xl  ">
      <header className="flex justify-between  py-8">
        <i className="font-bold text-[20px]">Campaign Composition</i>
        <button onClick={() => setViewCampComp(false)}>Collapse</button>
      </header>
      <main className=" mb-6 relative">
        <div className="flex flex-col gap-4 ">
          {yAxis.length &&
            yAxis.map((ele, index) => {
              return (
                <span className="flex items-center gap-4 " key={index}>
                  <p>{ele}</p>
                  <div
                    className={`border-[1px] fill-available h-0 border-blue-500 border-dashed ${
                      String(ele).length === 1 ? 'ml-2' : null
                    }`}
                  >
                    {' '}
                  </div>
                </span>
              );
            })}
        </div>
        {/* 10rem = 20 camp */}
        {/* 0.5 rem = 1 camp */}
        {/* 10 rem = yAxis[0] camp */}
        {/* 1/parseInt(yAxis[0])*25 rem = 1 camp */}
        <div className="flex justify-around fill-available gap-2 pl-12 pr-4 ">
          {memberData.length > 0 &&
            yAxis.length > 0 &&
            memberData?.map((ele, index) => {
              return (
                <div
                  className=" flex relative justify-center items-center mt-[-0.7rem] border-black w-12 h-12"
                  onMouseEnter={() => setHover(index)}
                  onMouseLeave={() => setHover()}
                >
                  <span
                    className={` ${
                      hover === index ? '' : 'hidden'
                    } absolute top-10`}
                  >
                    {memberData[index]?.first_name}
                  </span>
                  <img
                    className="w-7 h-7 rounded-full"
                    src={`${
                      ele?.profile_img ? ele?.profile_img : profileImage
                    }`}
                    alt=""
                  />
                  <div
                    className={` absolute bottom-[3rem] h-[0rem] border-[0.2rem]`}
                    style={{
                      height: `${
                        highestNumber <= 20
                          ? `${(ele.created_campaigns / 2).toFixed(1)}rem`
                          : `${(
                              ele.created_campaigns *
                              (1 / parseInt(yAxis[0])) *
                              25
                            ).toFixed(1)}rem`
                      }`,
                    }}
                  >
                    <div className="w-6 h-6 flex text-white justify-center top-[-1rem] left-[-0.7rem] rounded-full bg-blue-500 absolute">
                      {ele.created_campaigns}
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </main>
    </div>
  );
};

const ViewMemeber = ({ memberData }) => {
  const [isActive, setActive] = useState(false);
  const navigate = useNavigate();
  console.log('hi mem', memberData);
  return (
    <div className="flex flex-col bg-white w-full px-8 rounded-3xl  ">
      <header className="flex justify-between  py-8">
        <i className="font-bold text-[20px]">Member List</i>
        <button
          onClick={() => {
            navigate('../members');
          }}
        >
          View all
        </button>
      </header>
      <main className="overflow-auto ">
        <div className="flex flex-col gap-4 ">
          {memberData.length > 0 &&
            memberData.map((member, index) => {
              return (
                <div
                  className={`flex justify-between rounded-3xl px-6 cursor-pointer py-4 ${
                    isActive === index ? 'bg-[#EFF5FF]' : null
                  }`}
                  key={index}
                  onClick={() => setActive(index)}
                >
                  <div className="flex gap-2">
                    <span className="flex">
                      <img
                        className="w-12 h-12 rounded-full"
                        src={`${
                          member?.profile_img
                            ? member?.profile_img
                            : profileImage
                        }`}
                        alt=""
                      />
                    </span>
                    <span className="flex flex-col">
                      <b>
                        {member?.first_name} {member?.last_name}
                      </b>
                      <p>{member?.designation}</p>
                    </span>
                  </div>
                  {isActive === index ? (
                    <div className="flex gap-2">
                      <img
                        className="w-5 cursor-pointer"
                        src={pencilIcon}
                        alt="pencil"
                        // onClick={setOpenEdit(true)}
                      />
                      <span className="border-[0.02rem] border-slate-300 mt-[0.5rem] h-[70%] "></span>
                      <img
                        className="w-5 cursor-pointer"
                        src={optionIcon}
                        alt="option"
                      />
                    </div>
                  ) : null}
                </div>
              );
            })}
        </div>
      </main>
    </div>
  );
};

const ViewCampaign = () => {
  const navigate = useNavigate();
  const [tableData, setTableData] = useState([]);
  const getUser = async (userLimit, pageNo) => {
    const reqData = {
      is_deleted: 0,
      _limit: 7,
      _page: 1,
    };
    const response = await handleApiResponse(
      '/api/v1/interview/campaign_list/',
      reqData,
      1
    );
    if (response?.code === 'token_not_valid') {
      navigate('/');
      sessionStorage.clear();
      return;
    }
    console.log(response);
    if (response.data.status_code === 200) {
      console.log('camp displayed', Object.values(response.data.data).flat());
      console.log('response.data.data.total', response.data.data.total);

      setTableData(Object.values(response.data.data.data).flat());
    }
  };

  const findStatus = (start, end) => {
    const monthMap = {
      Jan: '1',
      Feb: '2',
      Mar: '3',
      Apr: '4',
      May: '5',
      Jun: '6',
      Jul: '7',
      Aug: '8',
      Sep: '9',
      Oct: '10',
      Nov: '11',
      Dec: '12',
    };
    function isSecDateBigger(date1, date2, pos) {
      // console.log(date1, date2);
      let [month1, day1, year1] = date1.split(' ');
      let [year2, month2, day2] = date2.split('-').map(Number);
      month1 = monthMap[month1];

      month1 = Number(month1);
      day1 = Number(day1);
      year1 = Number(year1);
      // console.log("today", year1, month1, day1);
      // console.log("start", year2, month2, day2);

      if (day1 === day2 && month1 === month2 && year1 === year2 && pos) {
        // console.log("true");
        return true;
      }

      if (year1 > year2) {
        return true;
      } else if (year1 < year2) {
        return false;
      }

      if (month1 > month2) {
        return true;
      } else if (month1 < month2) {
        return false;
      }

      return day1 > day2;
    }

    const todayDate = new Date();
    let thisDate = String(todayDate).slice(4, 15);
    let result = isSecDateBigger(thisDate, start, 1);
    if (result) {
      const res = isSecDateBigger(thisDate, end);
      if (res) {
        return 'Completed';
      } else {
        return 'Ongoing';
      }
    } else {
      return 'Upcoming';
    }
  };

  useEffect(() => {
    getUser();
  }, []);
  return (
    <div className="flex flex-col bg-white fill-available-h w-full px-8 rounded-3xl ">
      <header className="flex justify-between  py-8">
        <i className="font-bold text-[20px]">Campaign List</i>
        <button onClick={() => navigate('../campaign')}>View all</button>
      </header>
      <main className="overflow-auto mb-6">
        <table
          class="w-full text-sm text-[#A6AEBA] text-left rtl:text-right "
        >
          <thead class="text-[15px] h-[54px] text-[#222850] bg-[#F7FAFC] border-b border-[#E4E7EB]">
            <tr>
              <th scope="col" class="px-6">
                S.No.
              </th>
              <th scope="col" class="px-3">
                Campaign Name
              </th>

              <th scope="col" class="px-3">
                Created By
              </th>

              <th scope="col" class="px-3">
                Start Date
              </th>

              <th scope="col" class="px-6">
                Progress
              </th>
            </tr>
          </thead>
          <tbody>
            {tableData?.map((rowData, index) => (
              <CampaignTable
                key={index}
                number={0 + index}
                row12={rowData?.name}
                row17={rowData?.creater_name}
                row15={rowData?.start_date?.slice(0, 10)}
                row18={findStatus(
                  rowData?.start_date?.slice(0, 10),
                  rowData?.end_date.slice(0, 10)
                )}
                isDash={true}
              />
            ))}
          </tbody>
        </table>
      </main>
    </div>
  );
};

const HelperCard2 = ({ name, KPIData }) => {
  const total = KPIData?.total_campaigns;
  const ongoing = KPIData?.ongoing_campaigns;
  const upcoming = KPIData?.upcoming_campaigns;
  const completed = total - (upcoming + ongoing);
  return (
    <div className="flex flex-col bg-white px-8 py-2 rounded-3xl">
      <header className="flex w-full justify-between  py-2">
        <i className="font-bold text-[20px]">{name}</i>
      </header>
      <main>
        <div className="flex gap-4 mb-4 text-[24px]">
          <img src={shortLogo} alt="logo" />
          <div>
            <i className="opacity-[50%]">Total Activity</i>
            <br />
            <i className="font-bold">{total}</i>

          </div>
        </div>
        <section className="flex flex-col relative justify-between h-[42px] w-[320px]">
          <article className="flex justify-between">
            <div>{(completed / total).toFixed(2) * 100}%</div>
            <div>{100-(completed / total).toFixed(2) * 100}%</div>
          </article>

          <svg
            className="absolute bottom-0"
            width="315"
            height="20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <line
              x1="3"
              y1="10"
              x2={(completed / total) * 300}
              y2="10"
              stroke="#48C885"
              stroke-width="6"
              stroke-linecap="round"
            />
          </svg>
          <svg
            className="absolute bottom-0"
            width="315"
            height="20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <line
              x1="310"
              y1="10"
              x2={310 - (ongoing / total) * 300}
              y2="10"
              stroke="#FC7F29"
              stroke-width="6"
              stroke-linecap="round"
            />
          </svg>
        </section>
        <section className="bg-[#EFF5FF] mb-2 p-4 rounded-3xl w-full flex justify-between ">
          <div className="flex flex-col items-center gap-2 ">
            <div className="p-2 w-[2.5rem]  rounded-full bg-[#FC7F29] ">
              <img src={watchIcont} alt="" />
            </div>
            <p className="font-bold">{ongoing}</p>
            <p>Ongoing</p>
          </div>

          <div className="border-[0.04rem] border-[#E9EAEC] h-[6rem] my-2"></div>

          <div className="flex flex-col items-center gap-2">
            <div className="p-2 w-[2.5rem] rounded-full bg-[#48C885] ">
              <img src={tickIcon} alt="" />
            </div>
            <p className="font-bold">{completed}</p>
            <p>Completed</p>
          </div>
        </section>
      </main>
    </div>
  );
};

const HelperCard3 = ({ backgroundImage , name, KPIData, number}) => {
  const total = KPIData?.total_campaigns;
  const ongoing = KPIData?.ongoing_campaigns;
  const upcoming = KPIData?.upcoming_campaigns;
  const completed = total - (upcoming + ongoing);
  return (
    <div className="flex h-fit gap-8 fill-available flex-col bg-white px-8 py-8 rounded-3xl"  style={{
      backgroundImage: `url(${backgroundImage})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'right center',
      backgroundSize: 'contain'
    }}>
      <header className="flex fill-available justify-between  py-2 font-scada text-[20px]">{name}</header>
      <div className='text-semibold text-[#0875F4] text-3xl'>{number}</div>
    </div>
  );
};

// <div className="flex">
//   <div className="flex flex-col justify-center items-center gap-4">
//     <div className="flex gap-4">
//       <ScoreCard
//         text={'Total Campaigns'}
//         number={KPIData?.total_campaigns}
//       />
//       <ScoreCard
//         text={'Ongoing Campaigns'}
//         number={KPIData?.ongoing_campaigns}
//       />
//       <ScoreCard
//         text={'Upcoming Campaigns'}
//         number={KPIData?.upcoming_campaigns}
//       />
//     </div>
//     <div className="flex gap-4">
//       <div className="p-2 flex flex-col gap-[2rem] w-[29.653vw] items-center bg-white border-[0.05rem] rounded-lg px-8 py-4 shadow-custom">
//         <p className="text-[1.111vw] font-medium ">Members</p>

//         <SingleCircularProgressBar
//           bulkProgress={KPIData?.active_users - 1}
//           name2={'Acitve'}
//           specificProgress={KPIData?.inactive_users}
//           name1={'Inactive'}
//         />

//         <div className="flex gap-4 self-center">
//           <div className="flex text-[0.972vw] gap-1 align-center">
//             <img
//               src={coloredCircle}
//               alt="colored circle"
//               className="h-[0.972vw]"
//             />
//             <div>Active</div>
//           </div>
//           <div className="flex text-[0.972vw] gap-1 align-center">
//             <img
//               src={circle}
//               alt="colored circle"
//               className="h-[0.972vw]"
//             />
//             <div>Inactive</div>
//           </div>
//         </div>
//       </div>
//       <div className="p-2 flex flex-col gap-[2rem] w-[29.199vw] h-[29vw] items-center bg-white border-[0.05rem] rounded-lg px-8 py-4 shadow-custom">
//         <p className="text-[1.111vw] font-medium ">
//           Campaign Composition
//         </p>
//         <div className="flex self-center">
//           <SingleCircularProgressBar
//             bulkProgress={KPIData?.bulk_campaigns}
//             name2={'Mass'}
//             specificProgress={KPIData?.specific_campaigns}
//             name1={'Regular'}
//           />
//         </div>
//         <div className="mt-6 flex gap-4 self-center">
//           <div className="flex text-[0.972vw] gap-1 align-center">
//             <img
//               src={coloredCircle}
//               alt="colored circle"
//               className="h-[0.972vw]"
//             />
//             <div>Mass Campaign</div>
//           </div>
//           <div className="flex text-[0.972vw] gap-1 align-center">
//             <img
//               src={circle}
//               alt="colored circle"
//               className="h-[0.972vw]"
//             />
//             <div>Regular Campaign</div>
//           </div>
//         </div>
//       </div>
//     </div>
//   </div>
//   {/* <div className="ml-4">
//     <div className="flex-col p-2 flex gap-[1rem] w-[29.9vw] h-[71.4vh] items-start bg-white border-[0.05rem] rounded-lg px-8 py-4 shadow-custom ">
//       <div className="w-full flex items-center justify-between border-b-1 border-[#ECEEF6] h-fit">
//         <p className="text-[1.111vw] font-medium ">Completed Campaigns</p>
//         <p className="w-[3.333vw] h-[2.431vw] bg-[#A6AEBA1A] flex items-center justify-center rounded-[1.111vw] font-semibold text-[0.972vw]">
//           {KPIData?.total_campaigns -
//             (KPIData?.ongoing_campaigns + KPIData?.upcoming_campaigns)}
//         </p>
//       </div>
//       <hr className="w-[439px] ml-[-2rem]" />
//       <div className="h-[71.4vh] overflow-auto">
//         {campDetails.map((ele) => {
//           return (
//             <CampaignProgressBar
//               name={ele?.campaign_name}
//               role={`(${ele?.jobrole})`}
//               completed={
//                 ele?.total_candidates -
//                 (ele?.ongoing_candidates + ele?.pending_candidates)
//               }
//               total={ele?.total_candidates}
//               img={''}
//             />
//           );
//         })}
//       </div>
//     </div>
//   </div> */}
// </div>;
