import { toast } from "react-toastify";
import {
  arrowIcon,
  clockSvg,
  coverImg,
  shareIcon,
  shareIcon2,
  clevercruitlogo,
  lineBreak,
} from "../../assets/imgs";
import {
  Card1,
  Card2,
  Card3,
  Card4,
  DurationCard,
  PersonalCard,
  PersonalCard1,
  SegmentedCircleBar,
} from "../card";
import { ScoreCard } from "./card";
import { handleApiResponse } from "../../API/services";
import jsPDF from "jspdf";
import { useAsyncValue, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { Loader } from "../loader";
import SuspiciousChart from "../../utils/SuspiciousChart";
import TeamWorkChart from "../../utils/TeamWorkChart";

export const NewAnalytics = () => {
  const navigate = useNavigate();
  const [isLeftOption, setIsLeftOption] = useState(true);
  const location = useLocation();
  const status = location?.state?.status || "Completed";
  const [overall_score, setOverall_score] = useState("");
  const [suspicious, setSuspicious] = useState("");
  const [comprehensiveAbility, setComprehensiveAbility] = useState("");
  const [fluency, setFluency] = useState("");
  const [createdDate, setCreatedDate] = useState("");
  const [relevant_skills, setRelevantSkills] = useState("");
  const [description, setDescription] = useState("");
  const [stability, setStability] = useState("");
  const [teamColab, setTeamColab] = useState("");
  const [languageCommand, setLanguageCommand] = useState("");
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");
  const searchParams = new URLSearchParams(location.search);
  const uuid = searchParams.get("uid");
  const candId = searchParams.get("candId");
  const startDate = new Date(start);
  const endDate = new Date(end);
  const queryParams = new URLSearchParams(location.search);
  const [isLoader, setLoader] = useState(false);
  const [messages, setMessages] = useState([]);
  // console.log(evalData);
  console.log(start);
  console.log(end);
  console.log(uuid);
  const timeDifferenceInMillis = endDate - startDate;
  // console.log(messages)
  // Convert milliseconds to minutes and seconds
  const minutes = Math.floor(timeDifferenceInMillis / (1000 * 60));
  const seconds = Math.round((timeDifferenceInMillis % (1000 * 60)) / 1000);
  const candPhoto = queryParams.get("candPhoto").split("?")[0];
  const candName = queryParams.get("candName");
  const candCompany = queryParams.get("candCompany");
  const candJobRole = queryParams.get("candJobRole");
  const candExp = queryParams.get("candExp");
  const fullPhotoURL = `${candPhoto}`;
  const [marks, setMarks] = useState("0.275rem");
  const [candidateStability, setCandidateStability] = useState("");
  console.log(candidateStability, "candidateStability");
  const [suspiciousCause, setSuspiciousCause] = useState([]);
  console.log(suspiciousCause);
  let noFaceDetectedCount = 0;
  let dontManipulateWindowCount = 0;
  let otherCount = 0;

  suspiciousCause.forEach((cause) => {
    if (cause === "No face detected") {
      noFaceDetectedCount++;
    } else if (cause === "Don't manipulate the window") {
      dontManipulateWindowCount++;
    } else {
      otherCount++;
    }
  });

  const totalCount = suspiciousCause.length;

  const noFaceDetectedPercentage = ((noFaceDetectedCount / 5) * 100).toFixed(2);
  const dontManipulateWindowPercentage = (
    (dontManipulateWindowCount / 5) *
    100
  ).toFixed(2);
  const otherPercentage = ((otherCount / 5) * 100).toFixed(2);

  useEffect(() => {
    setMarks(`${(0.275 + overall_score * 0.188).toFixed(2)}rem`);
  }, [overall_score]);

  console.log(fullPhotoURL);
  console.log(minutes);
  console.log(seconds);
  console.log(`Time difference: ${minutes} minutes and ${seconds} seconds`);

  const downloadPDF = (messages) => {
    const doc = new jsPDF();
    const pageHeight = doc.internal.pageSize.getHeight();
    const pageWidth = doc.internal.pageSize.getWidth();
    const margin = 10;
    const maxLineWidth = pageWidth - margin * 2;
    const lineHeight = 10;
    let y = 10;
    let sender = "Candidate";

    console.log(messages);

    messages.forEach((message) => {
      y += 5;

      // Toggle sender between "AI" and "Interviewer" for each message
      sender = sender === "AI" ? "Candidate" : "AI";
      const text = `${sender}: ${message.msg}`;

      // Split text to fit within the page width
      const lines = doc.splitTextToSize(text, maxLineWidth);

      lines.forEach((line) => {
        // Check if adding this line will exceed the page height
        if (y + lineHeight > pageHeight - margin) {
          doc.addPage();
          y = margin; // Reset y position for the new page
        }
        doc.text(line, margin, y);
        y += lineHeight; // Move y position for the next line
      });

      y += 5; // Add some space between messages
    });

    doc.save("chat_transcript.pdf");
  };

  const handleDownloadTranscript = async (e) => {
    try {
      const response = await handleApiResponse(
        "/api/v1/interview/data/get_candidate_transcript/",
        {
          candidate_uid: uuid,
        },
        1
      );
      console.log("API Response:", response.data);
      if (response?.code === "token_not_valid") {
        navigate("/");
        sessionStorage.clear();
        return;
      }
      if (response.data.status_code === 200) {
        console.log(response.data.data);
        setMessages(response.data.data);
        downloadPDF(response.data.data);
        // dispatch(signupSuccess(response.data));
        // setLocal('adminToken', response.data.access);
        // setLocal('first_name', response.data.first_name);
        // setLocal('last_name', response.data.last_name);
        // setLocal('uid', response.data.uid);
        // setLocal('parentUid', response.data.parent);
        // setLocal(
        //   'notPermited',
        //   response?.data?.user_type === 3 ? true : false
        // );
        // if (!response?.data.is_email_verified) {
        //   setShowEmailVerification(true);
        // }
      }
    } catch (e) {}
  };
  console.log(relevant_skills);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await handleApiResponse(
          "/api/v1/get_performance_status/",
          {
            task_id: uuid,
            uid: candId,
          },
          1
        );
        console.log(response?.data);
        if (response?.code === "token_not_valid") {
          navigate("/");
          sessionStorage.clear();
          return;
        }
        if (response?.data.status_code === 200) {
          setLoader(false);
          console.log(response?.data.data.overall_score);
          setOverall_score(response?.data?.data?.result.overall_score);
          setCreatedDate(response?.data?.data?.result?.created_date);
          setTeamColab(response?.data?.data?.result.team_colab);
          setSuspicious(response?.data?.data?.result.suspicious);
          setComprehensiveAbility(
            response?.data?.data?.result.comprehensive_ability
          );
          setLanguageCommand(response?.data?.data?.result.lang_command);
          setRelevantSkills(response?.data?.data?.result.domain_experties);
          setStability(response?.data?.data?.result.candidate_stability);
          setStart(response?.data?.data?.result.interview_start);
          setEnd(response?.data?.data?.result.interview_end);
          setDescription(response?.data?.data?.result.description);
          console.log(response?.data?.data?.result.candidate_stability);
          setCandidateStability(
            response?.data?.data?.result.candidate_stability
          );
          setFluency(response?.data?.data?.result.fluency);
          setSuspiciousCause(response?.data?.data?.result.suspicious_cause);
        } else if (response?.data.status_code === 400) {
          setLoader(false);
          toast.error("The interview is not completed by candidate. ", {
            toastStyle: {
              borderLeft: "5px solid #f44336",
            },
          });
        } else {
          toast.error(response?.msg, {
            toastStyle: {
              borderLeft: "5px solid #f44336",
            },
          });
        }
        // setLoader(false);
      } catch (error) {
        // Handle errors here
      }
    };

    fetchData(); // Invoke the async function
  }, [overall_score, uuid]);
  console.log(overall_score);
  console.log(candidateStability);
  return (
    <main className="flex flex-col gap-4 sm:gap-6 text-dark-blue p-4 sm:p-8 w-full min-h-screen bg-gray-100">
      {isLoader ? <Loader /> : ""}
      <section className="flex flex-col sm:flex-row items-start sm:items-center justify-between">
        <div className="flex items-center mb-4 sm:mb-0">
          <img
            src={arrowIcon}
            alt="arrow icon"
            onClick={() => {
              navigate(-1);
            }}
            className="cursor-pointer mr-4"
          />
          <div>
            <p className="font-semibold text-lg">Analytics</p>
          </div>
        </div>
      </section>
      <section className="flex flex-col gap-4 sm:gap-6">
        <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center w-full">
          <p className="font-satoshi flex items-center gap-2 mb-4 sm:mb-0">
            <img src={clevercruitlogo} alt="" className="h-30px w-650px" />
            <span className="text-2xl mt-2">
              AI video interview -{" "}
              <b>
                Analytics Report -{" "}
                {createdDate.split("T")[0].replace(/-/g, "/")}
              </b>
            </span>
          </p>
          <button className="flex items-center gap-2 px-4 py-2 bg-white rounded-full shadow">
            <img className="w-4" src={shareIcon2} alt="" />
            Share
          </button>
        </div>
        <div
          className="p-6 sm:p-8 text-white relative flex flex-col sm:flex-row justify-between items-center overflow-hidden rounded-xl"
          style={{
            backgroundImage: "linear-gradient(to right, #064197 , #021531 )",
          }}
        >
          <div className="w-full sm:w-2/3 mb-6 sm:mb-0">
            <b>
              <i className="text-2xl sm:text-3xl mb-4 block">
                Clevercruit Use our generator to your own, or read on for the
              </i>
            </b>
            <p className="text-sm sm:text-base">
              The passage experienced a surge in popularity during the 1960s
              when Letraset used it on their dry-transfer sheets, and again
              during the. The passage experienced a surge in popularity during
              the 1960s..
            </p>
          </div>

          {/* <div className="w-1 h-48 bg-white shadow-sm rounded-full"></div> */}
          {/* <div className="flex justify-center items-center h-screen bg-navy-900"> */}
          <div className="relative w-4 h-48">
            <div
              className="absolute inset-0 bg-white"
              style={{
                clipPath:
                  "polygon(0% 0%, 20% 0%, 50% 60%, 50% 40%, 20% 100%, 0% 100%)",
              }}
            />
          </div>
          {/* </div> */}
          <div className="hidden sm:block h-3/4 border-l-2 border-white mx-4"></div>

          <div className="flex flex-col gap-2 w-full sm:w-1/3 text-center sm:text-left">
            <b>
              <i className="text-xl">Overall Score</i>
            </b>
            <p className="text-sm">Overall summary of your performance</p>
            <p>
              <b className="text-4xl">
                {overall_score ? parseFloat(overall_score).toFixed(2) : "0.00"}
              </b>{" "}
              Out of 100
            </p>
            <div
              className="relative w-full h-8 rounded-full border-2 border-white mt-2"
              style={{
                backgroundImage:
                  "linear-gradient(to right, #FF0E01, #F6CD02 , #1EF848 )",
              }}
            >
              <div className="border-2 rounded-full border-black h-full">
                <svg
                  width="100%"
                  height="100%"
                  className="ml-0"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <line
                    x1="10"
                    y1="50%"
                    x2="100%"
                    y2="50%"
                    stroke="#FFFFFF"
                    strokeWidth="15"
                    strokeDasharray="2 , 13"
                  />
                </svg>
              </div>
              <div style={{ position: "absolute", top: "0.6rem", left: marks }}>
                <svg width="32" height="21" xmlns="http://www.w3.org/2000/svg">
                  <polygon
                    points="10,20 30,20 19.8,10"
                    fill="white"
                    strokeWidth="2"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="flex flex-col lg:flex-row gap-4 sm:gap-6">
        <div className="flex flex-col gap-4 sm:gap-6 w-full lg:w-2/3">
          <div className="flex flex-col sm:flex-row gap-4 sm:gap-6">
            <div className="relative flex flex-col items-end gap-2 w-full max-w-[28rem] p-4 rounded-2xl">
              <img
                src={clockSvg}
                className="absolute w-[6rem] sm:w-[8rem] md:w-[10rem] lg:w-[12.5rem] top-[35%] left-2 sm:left-4 md:left-6 ms-4 sm:ms-8 md:ms-10"
                alt=""
              />

              <b className="bg-white relative text-[48px] md:text-[64px] lg:text-[78px] text-[#043376] text-center px-1 font-digital rounded-3xl w-full max-w-[15rem] py-2 md:py-3">
                {minutes % 10 === minutes
                  ? `0${minutes} : ${seconds}`
                  : `${minutes} : ${seconds}`}
                <p className="absolute bottom-1 text-[14px] md:text-[16px] lg:text-[18px] left-10 md:left-12">
                  Mints
                </p>
                <p className="absolute bottom-1 text-[14px] md:text-[16px] lg:text-[18px] right-12 md:right-14">
                  Sec
                </p>
              </b>

              <b className="bg-white text-[#043376] text-[20px] md:text-[28px] lg:text-[32px] flex flex-col items-end px-2 md:px-4 py-1 md:py-2 rounded-3xl">
                <p>Interview</p>
                <p>Time</p>
              </b>
            </div>

            <div className="bg-white p-4 rounded-xl shadow w-full sm:w-1/2">
              <b className="text-xl font-bold italic mb-2 ">
                Tech / Domain Skill
              </b>
              <p className="text-sm">Overall summary of your performance</p>
              <div className="flex bg-[#F6F8FF] rounded-xl flex-col items-center w-full h-[85%]">
                <div className="w-full aspect-square max-w-[250px]">
                  <TeamWorkChart
                    totalData={{
                      total: (100 - parseFloat(relevant_skills)).toFixed(2),
                      progres: parseFloat(relevant_skills).toFixed(2),
                      ProgColor: "#00BD1C",
                      withoutProgColor: "rgba(0, 189, 28, 0.2)",
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-4 bg-white rounded-xl shadow p-6">
            <div>
              <b>
                <i className="text-xl text-gray-800">Communication score</i>
              </b>
              <p className="text-sm text-gray-600">
                Overall summary of your performance
              </p>
            </div>
            <div className="flex flex-col sm:flex-row w-full justify-around gap-4">
              <div className="flex flex-col bg-[#F6F8FF] rounded-xl p-6 items-center w-full sm:w-1/3">
                <div className="w-40 h-40">
                  <TeamWorkChart
                    totalData={{
                      total: (100 - parseFloat(languageCommand)).toFixed(2),
                      progres: parseFloat(languageCommand).toFixed(2),
                      ProgColor: "#1AAEFE",
                      withoutProgColor: `rgba(26, 174, 254, 0.2)`,
                    }}
                  />
                </div>
                <b className="mt-4 text-gray-700 italic">Fluency</b>
              </div>
              <div className="flex flex-col bg-[#F6F8FF] rounded-xl p-6 items-center w-full sm:w-1/3">
                <div className="w-40 h-40">
                  <TeamWorkChart
                    totalData={{
                      total: (100 - parseFloat(fluency)).toFixed(2),
                      progres: parseFloat(fluency).toFixed(2),
                      ProgColor: "#FEF31A",
                      withoutProgColor: `rgba(254, 243, 26, 0.2)`,
                    }}
                  />
                </div>
                <b className="mt-4 text-gray-700 italic">Grammar</b>
              </div>
              <div className="flex flex-col bg-[#F6F8FF] rounded-xl p-6 items-center w-full sm:w-1/3">
                <div className="w-40 h-40">
                  <TeamWorkChart
                    totalData={{
                      total: (100 - 75).toFixed(2),
                      progres: "75",
                      ProgColor: "#FE261A",
                      withoutProgColor: "rgba(254, 38, 26, 0.2)",
                    }}
                  />
                </div>
                <b className="mt-4 text-gray-700 italic">
                  Comprehensive ability
                </b>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-gradient-to-l from-blue-900 to-blue-700 w-full lg:w-1/3 p-2 sm:p-4 rounded-2xl">
          <div className="flex flex-col gap-4 bg-white rounded-2xl shadow p-4 sm:p-6 h-full">
            <img
              className="h-20 w-20 sm:h-24 sm:w-24 mx-auto border-2 rounded-full object-cover"
              src={fullPhotoURL}
              alt="Photo"
            />
            <b className="text-xl sm:text-2xl w-full text-center text-dark-blue">
              <i>{candName}</i>
            </b>
            <p className="text-sm -mt-4 m-auto">
              Overall summary of your performance
            </p>
            <div className="flex flex-wrap justify-center gap-2 sm:gap-4">
              <div className="flex items-center shadow border px-2 sm:px-3 py-1 sm:py-2 bg-white rounded-full text-xs sm:text-sm">
                {candJobRole}
              </div>
              <div className="flex items-center shadow border px-2 sm:px-3 py-1 sm:py-2 bg-white rounded-full text-xs sm:text-sm">
                {candExp} years
              </div>
            </div>
            <div className="flex flex-col gap-2 sm:gap-4 bg-[#f6f8ff] rounded-2xl p-3 sm:p-4 mt-2 sm:mt-4 flex-grow">
              <b className="text-lg sm:text-xl text-dark-blue">
                <i>About You</i>
              </b>
              <p
                className="text-xs sm:text-sm overflow-y-auto"
                style={{ maxHeight: "calc(100vh - 300px)" }}
              >
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Modi
                aut perferendis fugit quibusdam a id exercitationem
                necessitatibus voluptas, ad repellendus aperiam illo earum eos,
                tempore molestiae dicta. At, consectetur soluta? Obcaecati ea
                omnis ratione minus at, suscipit voluptatibus molestiae,
                repellat, deserunt veniam ex odit. Excepturi blanditiis vel
                expedita unde, deleniti vero iste fuga quae veniam. Ratione
                dignissimos reprehenderit vero? Aliquid assumenda esse iste
                recusandae sapiente.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="flex gap-4 justify-between fill-available overflow-auto">
        <div className="bg-white p-8 text-center rounded-3xl border-2 ml-[1rem] w-full min-w-[15rem]">
          <b className="text-xl font-bold italic">Team Work / Collaboration</b>
          <div className="flex  flex-col gap-3 min-w-[18rem] h-[22rem] rounded-3xl p-6 ">
            <TeamWorkChart
              totalData={{
                total: (100 - parseFloat(teamColab)).toFixed(2),
                progres: parseFloat(teamColab).toFixed(2),
                ProgColor: "#FE1ADC",
                withoutProgColor: "rgba(254, 26, 220, 0.2)",
              }}
            />
          </div>
        </div>

        <div className="flex flex-col  bg-white gap-4 w-full min-w-[24rem] p-4 rounded-2xl border-2">
          <b className="text-xl font-bold italic">Suspicious Activity</b>
          <div className="flex flex-col md:flex-row items-center md:items-start gap-4 w-full">
            <div className="flex flex-col gap-4 mb-6 md:mb-0 md:w-2/5 lg:w-1/3">
              <div className="flex gap-3 items-center rounded-3xl p-3 ">
                <div className="w-4 h-4 rounded-full bg-[#d57d00] flex-shrink-0 "></div>
                <p className="flex text-[#d57d00]">Window Manipulate</p>
              </div>
              <div className="flex gap-3 items-center rounded-3xl p-3 ">
                <div className="w-4 h-4 rounded-full bg-[#975ced] flex-shrink-0 "></div>

                <p className="text-[#975ced]">No Face Detected</p>
              </div>
              <div className="flex gap-3 items-center rounded-3xl p-3 ">
                <div className="w-4 h-4 rounded-full bg-[#9C9898] flex-shrink-0 "></div>
                <p className="text-[#9c9898]">Multiple Face / Suspicious</p>
              </div>
            </div>

            <div className="w-full md:w-2/3">
              <SuspiciousChart
                totalData={{
                  total: totalCount,
                  noFacePer: noFaceDetectedPercentage,
                  dontManPer: dontManipulateWindowPercentage,
                  multiface: otherPercentage,
                  overAll:
                    100 -
                    parseInt(
                      noFaceDetectedPercentage +
                        dontManipulateWindowPercentage +
                        otherPercentage
                    ),
                }}
              />
            </div>
          </div>

          <section className="flex flex-col md:flex-row text-white justify-center items-center gap-4 text-center">
            <div className="flex bg-[#D57D00] rounded-xl py-3 px-6 md:px-12">
              <p>{dontManipulateWindowCount}</p>
            </div>
            <div className="bg-[#975CED] rounded-xl py-3 px-6 md:px-12">
              <p>{noFaceDetectedCount}</p>
            </div>
            <div className="bg-[#9C9898] rounded-xl py-3 px-6 md:px-12">
              <p>{otherCount}</p>
            </div>
          </section>
        </div>

        <div className="bg-white p-6 sm:p-8 rounded-3xl  max-w-2xl min-w-[438px] border-2">
          <div className="flex flex-col gap-4">
            <h2 className="text-xl font-bold text-navy-blue italic">
              Stability
            </h2>
            {(() => {
              let parsedStability = candidateStability;

              if (typeof candidateStability === "string") {
                try {
                  const sanitizedData = candidateStability
                      .replace(/'/g, '"')
                      .replace(/^\[|\]$/g, "")
                      .replace(/-/g, ""),
                    parsedStability = JSON.parse(`[${sanitizedData}]`);
                } catch (e) {
                  console.error("Failed to parse candidateStability:", e);
                }
              }

              return Array.isArray(parsedStability) ? (
                parsedStability.map((item, index) => (
                  <div key={index} className="flex items-start space-x-4">
                    <div className="w-3 h-3 rounded-full bg-[#334157] flex-shrink-0 mt-2"></div>
                    <p className="text-[#334157] text-base font-semibold leading-relaxed">
                      {item.replace(/^"|"$/g, "")}
                    </p>
                  </div>
                ))
              ) : (
                <p className="text-navy-blue">No stability data available</p>
              );
            })()}
          </div>
        </div>
      </section>
      <section className="flex gap-4 justify-between w-full">
        <div className="flex gap-4 bg-white rounded-xl shadow w-full flex-col p-4">
          <b className="text-xl ">Interview Summary</b>
          <p className="text-sm -mt-4">summary of your performance</p>
          <div className="flex gap-4 rounded-xl bg-[#F6F8FF] w-full flex-col p-4">
            {description}
          </div>
        </div>
      </section>
    </main>
  );
};
