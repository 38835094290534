import { useState, useEffect } from 'react';
import {
  BackButton,
  Backbutton,
  BrowseButton,
  Finishbutton,
  NewBackButton,
  NextButton,
  Nextbutton,
  NextButton1,
  NextButton2,
} from '../../components/buttons';
import {
  bg2,
  Check,
  clevercruit,
  FillBg,
  resumeUpload,
} from '../../assets/imgs';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { handleApiResponse } from '../../API/services';
import { useDispatch } from 'react-redux';
import { getLocal, setLocal } from '../../utils/localStorage';
import { useParams } from 'react-router-dom';
import { Loader } from '../../components/loader';
import detailsBoxBorder from '../../assets/imgs/detailsboxborder.svg';
import guidelinesbg from '../../assets/imgs/guidelinesbg.svg';
import importIcon from '../../assets/imgs/browseicon1.svg';
import formImage from '../../assets/imgs/formImage.svg';
import { arrowEmail } from '../../assets/imgs';
import {
  setFirstName1,
  setLastName1,
  setNumber1,
  setJob1,
  clearForm,
  setExperience,
  setEmail,
} from '../../redux/formDetails/actions';
import detailsBox from '../../assets/imgs/detailsbox.png';
import { loginSuccess } from '../../redux/login/actions';
import { bulkCandidateDetailsSuccess } from '../../redux/bulkCandidateUserDetails/actions';
import { FaInfoCircle } from 'react-icons/fa';
import { Tooltip } from 'react-tooltip';
import { set } from 'date-fns';

export const FillForm = () => {
  const getFileFromLocal = (key) => {
    const item = sessionStorage.getItem(key);
    if (item) {
      const fileInfo = JSON.parse(item);
      const arrayBuffer = base64ToArrayBuffer(fileInfo.base64);
      return new File([arrayBuffer], fileInfo.name, { type: fileInfo.type });
    }
    return null;
  };

  const base64ToArrayBuffer = (base64) => {
    const binaryString = window.atob(base64);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer;
  };

  const firstName = useSelector(
    (state) => state?.candidateData?.user?.first_name
  );
  const email = useSelector((state) => state?.candidateData?.user?.email);
  const lastName = useSelector(
    (state) => state?.candidateData?.user?.last_name
  );
  const job = useSelector((state) => state?.candidateData?.user?.job_profile);
  const number = useSelector((state) => state?.candidateData?.user?.mobile);
  const exp = useSelector(
    (state) => state?.candidateData?.user?.work_experience
  );
  const [newExp, setNewExp] = useState(getLocal('newExp') || '');
  console.log(newExp);
  const [newExpMonths, setNewExpMonths] = useState(
    getLocal('newExpMonths') || ''
  );
  const navigate = useNavigate();
  const firstName1 = useSelector((state) => state?.fillForm?.firstName) || '';
  const email1 = useSelector((state) => state?.fillForm?.email) || '';
  const lastName1 = useSelector((state) => state?.fillForm?.lastName) || '';
  const job1 = useSelector((state) => state?.fillForm?.job) || '';
  const number1 = useSelector((state) => state?.fillForm?.number) || '';
  const exp1 = useSelector((state) => state?.fillForm?.exp) || '';
  console.log('firstname data', firstName1);
  const { intLink } = useParams();
  console.log(intLink);
  const [uploadFile, setUploadFile] = useState(
    () => getFileFromLocal('file') || 'false'
  );
  console.log(uploadFile);
  const [uploadJD, setUploadJD] = useState(false);
  const [isAllCheck, setIsAllCheck] = useState(false);
  const [isCodingQuestAllow, setIsCodingQuestAllow] = useState(Boolean);
  const [error, setError] = useState('');
  const [current, setCurrent] = useState(
    sessionStorage.getItem('fillForm')
      ? parseInt(sessionStorage.getItem('fillForm'))
      : 0
  );
  const uid = useSelector((state) => state?.candidateData?.user?.uid);
  const dispatch = useDispatch();
  const location = useLocation();
  const campType = sessionStorage.getItem('campType');
  const [isLoader, setLoader] = useState(false);
  const interview_link_token = getLocal('link');
  const isResumeAllowed = location?.state?.resume;
  const userEmail = location?.state?.email;
  const step = [
    { name: 'Personal Details' },
    { name: 'Upload Your Resume' },
    { name: 'Guidelines' },
  ];

  const arrayBufferToBase64 = (buffer) => {
    let binary = '';
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  };

  const fileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const base64 = arrayBufferToBase64(reader.result);
        resolve(base64);
      };
      reader.onerror = reject;
      reader.readAsArrayBuffer(file);
    });
  };
  useEffect(() => {
    sessionStorage.removeItem('interviewTime');
    sessionStorage.removeItem('timer');
    sessionStorage.removeItem('interviewTimeRun');
    sessionStorage.removeItem('timerRunning');
    sessionStorage.removeItem('wc');
    sessionStorage.removeItem('hasStartedRecording');
    sessionStorage.removeItem('isNext');
    sessionStorage.removeItem('isEnd');
    sessionStorage.removeItem('showNext');
    sessionStorage.removeItem('endInterview');
    sessionStorage.removeItem('lastEndInterview');
    sessionStorage.removeItem('chatroomLoading');
    sessionStorage.removeItem('questionCounter');
    sessionStorage.removeItem('setIsEndTimer');
    sessionStorage.removeItem('setEndTimer');
    sessionStorage.removeItem('setEndInterviewCounter');
    sessionStorage.removeItem('no_face_detect_count');
    sessionStorage.removeItem('no_face_detect');
    sessionStorage.removeItem('networkError');
    sessionStorage.removeItem('setCoveringCamera');
    sessionStorage.removeItem('setcoveringCTimer');
    sessionStorage.removeItem('coverCamera');
    sessionStorage.removeItem('setcoverModel');
    sessionStorage.removeItem('codingQuestion');
    sessionStorage.removeItem('code');
    sessionStorage.removeItem('openEditor');
    sessionStorage.removeItem('causeArray');
  }, []);

  const handleExpeChange = (newExp) => {
    setNewExp(newExp);
  };

  const handleExpeMonthsChange = (newExpMonths) => {
    setNewExpMonths(newExpMonths);
  };
  console.log(newExpMonths);
  sessionStorage.removeItem('isStarted');
  sessionStorage.removeItem('wc');

  const handleSubmit1 = async (e) => {
    setLoader(true);
    if (isResumeAllowed) {
      if (!uploadFile) {
        setLoader(false);
        toast.warning('Please upload all required files !', {
          toastStyle: {
            borderLeft: '5px solid #ffeb3b',
          },
        });
        setCurrent(0);
        return;
      }
    }
    console.log(firstName);
    console.log(lastName);
    console.log(number);
    console.log(job);
    console.log(exp);
    if (
      firstName === '' ||
      lastName === '' ||
      number === '' ||
      job === '' ||
      newExp === 'NaN' ||
      newExpMonths === 'NaN'
    ) {
      setLoader(false);
      setCurrent(0);
      setError('Please fill in all the details.');
    } else {
      console.log('------------');
      const formData = new FormData();
      formData.append('first_name', firstName1);
      formData.append('last_name', lastName1);
      formData.append('mobile', number1);
      formData.append('job_role', job1);
      formData.append('work_experience', `${newExp}.${newExpMonths}`);
      formData.append('email', email1);
      formData.append('interview_link_token', intLink);
      formData.append('organization', sessionStorage.getItem('Oid'));
      formData.append('campaign', sessionStorage.getItem('Cid'));
      formData.append('resume', uploadFile);
      if (sessionStorage.getItem('CandidateUid')) {
        formData.append('uid', sessionStorage.getItem('CandidateUid'));
      }
      // if (isResumeAllowed) {
      //   console.log(uploadFile);
      //   if (!uploadFile?.url) {
      //     formData.append('resume', uploadFile);
      //   }
      // }

      console.log(formData);
      const response = await handleApiResponse(
        '/api/v1/interview/add_bulk_candidate/',
        formData,
        0
      );
      if (response?.code === 'token_not_valid') {
        navigate('/');
        setLoader(false);
        sessionStorage.clear();
        return;
      }
      console.log(response);
      if (response.status_code === 200) {
        setLoader(false);
        dispatch(bulkCandidateDetailsSuccess(response.data));
        toast.success('Added successfully.', {
          toastStyle: {
            borderLeft: '5px solid #4caf50',
            backgroundColor: '#00112D',
            color: 'white',
          },
        });
        setCurrent(2);
        window.location.reload();
        setLocal('CandidateUid', response?.data.uid);
        setLocal('JobRole', response?.data.uid);
        setLocal('candFName', response?.data?.first_name);
        setLocal('candLName', response?.data?.last_name);
        setLocal('');
      } else if (response?.status_code === 400) {
        console.log('first');
        setCurrent(0);
        setLoader(false);
        // console.log(response?.msg?.non_field_errors[0])
        toast.error(
          Array.isArray(response?.msg?.non_field_errors) &&
            response.msg.non_field_errors.length > 0
            ? response.msg.non_field_errors[0]
            : response.msg.email
            ? response.msg.email[0]
            : response?.msg?.startsWith(
                'duplicate key value violates unique constraint'
              )
            ? 'Candidate with this email already exists.'
            : response?.msg,
          {
            toastStyle: {
              borderLeft: '5px solid #f44336',
            },
          }
        );
        // toast.error(
        //   response?.msg?.startsWith("duplicate key value violates unique constraint")
        //     ? "Candidate with this email already exists."
        //     : Array.isArray(response?.msg?.non_field_errors) && response.msg.non_field_errors.length > 0
        //     ? response.msg.non_field_errors[0]
        //     : response.msg.email
        //     ? response.msg.email[0]
        //     : response?.msg
        // );
        return;
      }
      if (uid) {
        formData.append('uid', uid);
        // setLocal('uid', uid);
      }
    }
  };
  console.log('typeof(uploadFile)', typeof uploadFile);
  useEffect(() => {
    console.log('resume', isResumeAllowed);
  }, []);

  const saveFileToLocal = async (key, file) => {
    const base64 = await fileToBase64(file);
    const fileInfo = {
      name: file.name,
      type: file.type,
      base64,
    };
    sessionStorage.setItem(key, JSON.stringify(fileInfo));
  };

  console.log(uploadFile);
  const handleSubmit = async (e) => {
    setLoader(true);
    console.log(uploadFile);
    if (!uploadFile) {
      toast.warning('Please upload resume !', {
        toastStyle: {
          borderLeft: '5px solid #ffeb3b',
        },
      });
      setLoader(false);
      setCurrent(0);
      return 0;
    } else {
      console.log(typeof uploadFile);
      const formData = new FormData();
      formData.append('uid', getLocal('CandidateUid'));
      formData.append('resume', uploadFile);
      formData.append('work_experience', `${newExp}.${newExpMonths}`);
      formData.append('interview_link_token', interview_link_token);

      const response = await handleApiResponse(
        '/api/v1/interview/add_bulk_candidate/',
        formData
      );
      if (response?.code === 'token_not_valid') {
        navigate('/');
        sessionStorage.clear();
        setLoader(false);
        return;
      }
      if (response?.status_code === 400) {
        setLoader(false);
        toast.error(response?.msg, {
          toastStyle: {
            borderLeft: '5px solid #f44336',
          },
        });
        setCurrent(0);
        return 0;
      } else {
        setIsCodingQuestAllow(
          response?.data?.campign_info?.is_coding_quest_allow
        );
        setLocal('newExp', newExp);
        setLocal('newExpMonths', newExpMonths);
        await saveFileToLocal('file', uploadFile);
        console.log(response?.data);
        setCurrent(2);
        window.location.reload();
        setLoader(false);
        return 1;
      }
    }
  };

  const handleClick = async (e) => {
    e.preventDefault();
    sessionStorage.setItem('fillForm', 2);

    console.log(campType);
    if (campType === 'Regular') {
      console.log(current);
      console.log(firstName);
      console.log(lastName);
      console.log(number);
      console.log(exp);
      console.log(newExp);
      if (current === 0) {
        console.log(firstName);
        console.log(lastName);
        console.log(number);
        console.log(exp);
        if (
          // firstName === "" ||
          // lastName === "" ||
          // number === "" ||
          newExp === '' ||
          newExpMonths === '' ||
          // firstName === undefined ||
          // lastName === undefined ||
          // number === undefined ||
          newExpMonths === undefined ||
          newExp === undefined ||
          !uploadFile
        ) {
          toast.warning('Please fill in all required fields.', {
            toastStyle: {
              borderLeft: '5px solid #ffeb3b',
            },
          });
        } else {
          handleSubmit();
        }
      }
      // else if (
      //   current === 1
      // ) {
      //   handleSubmit()
      //   // setCurrent(1);
      // }
      //  else if (
      //   current === 1 &&
      //   (uploadFile === "" || !uploadFile)
      // ) {
      //   setCurrent(1);
      // }
      // else if()
      else {
        console.log(isAllCheck);
        console.log('yes');
        if (isResumeAllowed && isAllCheck) {
          console.log('yes1');
          navigate('../start-interview');
          window.location.reload();
          sessionStorage.removeItem('fillForm');
          setCurrent(current + 1);
        } else {
          setCurrent(current + 2);
          // navigate("../start-interview");
          console.log('yes2');
        }
      }
      // if (current === 1 && uploadFile ) {
      //   console.log('yes')
      //   handleSubmit();
      //   if (uploadFile) {
      //     setCurrent(current + 1);
      //   }
      // }
    } else if (campType === 'Mass') {
      e.preventDefault();
      console.log('------');
      if (current === 0) {
        console.log(firstName1);
        console.log(lastName1);
        console.log(number1);
        // console.log(exp1);
        console.log(job1);
        console.log(newExp);
        console.log(newExpMonths);
        if (
          firstName1 === '' ||
          lastName1 === '' ||
          newExp === '' ||
          newExpMonths === '' ||
          firstName1 === undefined ||
          lastName1 === undefined ||
          newExp === undefined ||
          newExpMonths === undefined ||
          !uploadFile
        ) {
          toast.warning('Please fill in all required fields.', {
            toastStyle: {
              borderLeft: '5px solid #ffeb3b',
            },
          });
        } else {
          if (!isResumeAllowed) {
            setLoader(true);
            handleSubmit1();
            setCurrent(2);
          } else {
            // setCurrent(1);
            handleSubmit1();
          }
        }
      } else {
        if (current === 0) {
          setCurrent(2);
          console.log('ref');
          window.location.reload();
        } else {
          if (isResumeAllowed) {
            setCurrent(current + 1);
            navigate('../start-interview');
            sessionStorage.removeItem('fillForm');
            window.location.reload();
          } else {
            setCurrent(current + 2);
            navigate('../start-interview');
            sessionStorage.removeItem('fillForm');
            window.location.reload();
          }
        }
      }
    }
  };

  const handleClickBack = async (e) => {
    sessionStorage.removeItem('fillForm');
    if (current == 2) {
      setCurrent(current - 2);
      window.location.reload();
    } else {
      if (isResumeAllowed) {
        setCurrent(current - 1);
      } else {
        setCurrent(current - 2);
      }
    }
  };

  console.log(current);
  return (
    <main className="flex flex-col fill-available ">
      {isLoader ? <Loader /> : ''}

      {current === 0 ? (
        <UserForm
          step={step}
          current={current}
          campType={campType}
          handleExpeChange={handleExpeChange}
          handleExpeMonthsChange={handleExpeMonthsChange}
          newExp={newExp}
          newExpMonths={newExpMonths}
          setUploadResume={setUploadFile}
          uploadResume={uploadFile}
          uploadFile={uploadFile}
          handleClick={handleClick}
        />
      ) : current === 1 ? (
        <UploadResume
          setUploadResume={setUploadFile}
          uploadResume={uploadFile}
        />
      ) : (
        <Guidelines
          current={current}
          handleClickBack={handleClickBack}
          setIsAllCheck={setIsAllCheck}
          isAllCheck={isAllCheck}
          handleClick={handleClick}
        />
      )}
    </main>
  );
};

export const InputBox4 = ({
  onKeyDown,
  name,
  type = 'text',
  holder,
  value,
  onChange,
}) => {
  return (
    <span className="flex flex-col">
      <div className="flex mb-[5px]">
        <label className="text-white" htmlFor="">
          {name}
        </label>
        {name !== 'Mobile Number' && <span className="text-red-500">*</span>}
      </div>
      <div className="p-[1px] rounded-xl ">
        <div
          className="w-full h-[40px] max-w-md p-[1px] rounded-xl bg-gradient-to-b from-[#2867B1] to-transparent "
          style={{
            boxShadow: `0px 4px 6px rgba(0, 0, 0, 0.6)`,
          }}
        >
          <input
            disabled
            type={type}
            id={name.toLowerCase().replace(/\s+/g, '')}
            name={name.toLowerCase().replace(/\s+/g, '')}
            placeholder={holder}
            className="h-[38px] cursor-not-allowed w-full p-[20px] rounded-xl border-none text-white placeholder:text-white focus:outline-none"
            style={{
              background:
                'linear-gradient(to bottom, rgb(27,51,81) 16%, rgb(40,100,151,0.3) 93%)',
            }}
            onChange={onChange}
            value={value}
            onKeyDown={onKeyDown}
          />
        </div>
      </div>
    </span>
  );
};

export const InputBox6 = ({
  onKeyDown,
  name,
  type = 'text',
  holder,
  value,
  onChange,
}) => {
  return (
    <span className="flex flex-col">
      <div className="flex mb-[5px]">
        <label className="text-white" htmlFor="">
          {name}
        </label>
        {name !== 'Mobile Number' && <span className="text-red-500">*</span>}
      </div>
      <div
        className="w-full max-w-md p-[1px] rounded-xl bg-gradient-to-r from-[#3DBDF1] via-[#3DBDF1] to-[#1A6F92]"
        style={{
          boxShadow: `0px 4px 6px rgba(0, 0, 0, 0.6)`,
        }}
      >
        <input
          type={type}
          id={name.toLowerCase().replace(/\s+/g, '')}
          name="email"
          placeholder={holder}
          className="w-full h-[38px] p-3 rounded-xl border-none bg-[#192b4c] text-white placeholder:text-[#8893A2] focus:outline-none"
          onChange={onChange}
          value={value}
          onKeyDown={onKeyDown}
        />
      </div>
    </span>
  );
};

export const InputBox5 = ({
  onKeyDown,
  name,
  type = 'text',
  holder,
  value,
  value1,
  onChange,
  holder1,
  onChange1,
  name1,
}) => {
  return (
    <span className="flex flex-col">
      <div className="flex mb-[5px]">
        <div className="w-[50%]">
          <label className="text-white" htmlFor="">
            {name}
          </label>
          <span className="text-red-500">*</span>
        </div>
        <div>
          <label className="text-white" htmlFor="">
            {name1}
          </label>
          <span className="text-red-500">*</span>
        </div>
      </div>
      <div className="flex gap-[25px]">
        <div
          className="w-full h-[40px] max-w-md p-[1px] rounded-xl bg-gradient-to-b from-[#2867B1] to-transparent "
          style={{
            boxShadow: `0px 4px 6px rgba(0, 0, 0, 0.6)`,
          }}
        >
          <input
            type={type}
            id={name.toLowerCase().replace(/\s+/g, '')}
            name={name.toLowerCase().replace(/\s+/g, '')}
            placeholder={holder}
            onChange={onChange}
            value={value}
            onKeyDown={onKeyDown}
            className="h-[38px] w-full p-3 rounded-xl border-none  text-white placeholder:text-white placeholder:opacity-50 focus:outline-none"
            style={{
              background:
                'linear-gradient(to bottom, rgb(27,51,81) 16%, rgb(40,100,151,0.3) 93%)',
            }}
          />
        </div>

        <div
          className="w-full h-[40px] max-w-md p-[1px] rounded-xl ml-[0.5rem] bg-gradient-to-b from-[#2867B1] to-transparent"
          style={{
            boxShadow: `0px 4px 6px rgba(0, 0, 0, 0.6)`,
          }}
        >
          <input
            type={type}
            id={name1.toLowerCase().replace(/\s+/g, '')}
            name={name1.toLowerCase().replace(/\s+/g, '')}
            placeholder={holder1}
            onChange={onChange1}
            value={value1}
            onKeyDown={onKeyDown}
            className="h-[38px] w-full p-3 rounded-xl border-none bg-[#192b4c] text-white placeholder:text-white placeholder:opacity-50 focus:outline-none"
            style={{
              background:
                'linear-gradient(to bottom, rgb(27,51,81) 16%, rgb(40,100,151,0.3) 93%)',
            }}
          />
        </div>
      </div>
    </span>
  );
};

export const InputBox = ({
  onKeyDown,
  head,
  name,
  type,
  holder,
  value,
  onChange,
  handleBlur,
  showPassword,
  showPassword1,
}) => {
  return (
    <span className="flex flex-col fill-available">
      <div className="flex">
        <label
          className="text-white text-[13px] mb-[0.88vh]"
          htmlFor={name.toLowerCase().replace(/\s+/g, '')}
        >
          {head}
        </label>
        {name !== 'mobile' && name !== 'lastName' && (
          <span className="text-red-500">*</span>
        )}
      </div>
      <div className="w-full h-[42px] max-w-md p-[1px] rounded-[15px] border-[1px] border-white">
        <input
          type={
            name === 'password'
              ? showPassword
                ? 'text'
                : 'password'
              : name === 'confirmPassword'
              ? showPassword1
                ? 'text'
                : 'password'
              : type
          }
          id={name.toLowerCase().replace(/\s+/g, '')}
          name={name}
          placeholder={holder}
          onChange={onChange}
          value={value}
          onKeyDown={onKeyDown}
          onBlur={handleBlur}
          className=" h-[39px] w-full p-3.5 rounded-[15px] bg-[#000D26] text-white placeholder:text-white placeholder:opacity-50 focus:outline-none"
        />
      </div>
    </span>
  );
};

export const InputBox8 = ({
  onKeyDown,
  head,
  name,
  type,
  holder,
  value,
  onChange,
  handleBlur,
  showPassword,
  showPassword1,
}) => {
  return (
    <span className="flex flex-col fill-available">
      <div className="flex">
        <label
          className="text-white text-[16px] mb-[8px]"
          htmlFor={name.toLowerCase().replace(/\s+/g, '')}
        >
          {head}
        </label>
        {name !== 'mobile' && name !== 'lastName' && (
          <span className="text-red-500">*</span>
        )}
      </div>
      <div className="w-full h-[46px] max-w-md p-[1px] rounded-[15px] border-2 border-white">
        <input
          type={
            name === 'password'
              ? showPassword
                ? 'text'
                : 'password'
              : name === 'confirmPassword'
              ? showPassword1
                ? 'text'
                : 'password'
              : type
          }
          id={name.toLowerCase().replace(/\s+/g, '')}
          name={name}
          placeholder={holder}
          onChange={onChange}
          value={value}
          onKeyDown={onKeyDown}
          onBlur={handleBlur}
          className=" h-[41px] w-full p-3.5 rounded-[15px] bg-[#000D26] text-white placeholder:text-white placeholder:opacity-50 focus:outline-none"
        />
      </div>
    </span>
  );
};

export const InputBoxEmail = ({
  onKeyDown,
  head,
  name,
  type,
  holder,
  value,
  onChange,
  handleBlur,
  showPassword,
  showPassword1,
  verified,
  generateOtp,
  errors,
  formvalues,
  existError,
}) => {
  return (
    <span className="flex flex-col w-[28vw]">
      <div className="flex">
        <label
          className="text-white text-[13px] mb-[8px]"
          htmlFor={name.toLowerCase().replace(/\s+/g, '')}
        >
          {head}
        </label>
        {name !== 'mobile' && <span className="text-red-500">*</span>}
      </div>
      <div className="relative w-[403px] h-[42px] p-[1px] rounded-[15px] bg-white">
        <input
          type={
            name === 'password'
              ? showPassword
                ? 'text'
                : 'password'
              : name === 'confirmPassword'
              ? showPassword1
                ? 'text'
                : 'password'
              : type
          }
          id={name.toLowerCase().replace(/\s+/g, '')}
          name={name}
          placeholder={holder}
          onChange={onChange}
          value={value}
          onKeyDown={onKeyDown}
          onBlur={handleBlur}
          className=" w-[85%] p-3.5 relative h-[41px] rounded-[15px] border-2 bg-[#000B21] text-white placeholder:text-white placeholder:opacity-50 focus:outline-none"
        />
        <div className="absolute z-[10]">
          {existError && (
            <div
              style={{
                color: 'red',
                // position: 'absolute',
                top: '100%', // Position it just below the input
                left: '0',
                marginBottom: '0', // Ensure no space is taken
                whiteSpace: 'pre-line',
                backgroundColor: 'white', // Add white background
                padding: '5px', // Optional: Add padding for better readability
                borderRadius: '4px',
                textAlign: 'left',
              }}
              className="w-max"
            >
              User Already Exists.
            </div>
          )}
        </div>
        {!errors.email && formvalues.email !== '' && (
          <div
            className="right-2 top-[20%] absolute flex flex-col justify-center items-center cursor-pointer"
            onClick={!verified ? generateOtp : undefined}
          >
            <img
              src={arrowEmail}
              alt="arrow right"
              className=" w-[20px] h-[20px]"
            />
            <p
              className={`text-[8px] ${
                verified ? 'text-green-500' : 'text-black'
              }`}
            >
              {verified ? 'Verified' : 'Verify email'}
            </p>
          </div>
        )}
      </div>
    </span>
  );
};

export const InputBoxColor = ({
  onKeyDown,
  head,
  name,
  type,
  holder,
  value,
  onChange,
  handleBlur,
  showPassword,
  showPassword1,
}) => {
  return (
    <span className="flex flex-col ">
      <div className="flex">
        <label htmlFor="">{head}</label>
        <span className="text-red-500">*</span>
      </div>
      <input
        type={
          name === 'password'
            ? showPassword
              ? 'text'
              : 'password'
            : name === 'confirmPassword'
            ? showPassword1
              ? 'text'
              : 'password'
            : type
        }
        id={name.toLowerCase().replace(/\s+/g, '')}
        name={name}
        placeholder={holder}
        className="p-3 rounded-lg outline-0 mt-2 placeholder-text-sm placeholder-tracking-[0.6px] bg-[#FBFBFB]"
        onChange={onChange}
        value={value}
        onKeyDown={onKeyDown}
        onBlur={handleBlur}
      />
    </span>
  );
};

export const InputBox3 = ({
  onKeyDown,
  name,
  holder,
  value,
  onChange,
  error,
  showErrorOnInput,
}) => {
  return (
    <span className="flex flex-col">
      <span className="flex">
        <label htmlFor={name.toLowerCase().replace(/\s+/g, '')}>{name}</label>
        <span className="text-red-500">*</span>
      </span>
      <textarea
        id={name.toLowerCase().replace(/\s+/g, '')}
        placeholder={holder}
        className={`h-[134px] p-3 outline-0 border-[#22284E] border-[1px] rounded-lg mt-2 placeholder-text-sm`}
        onChange={onChange}
        value={value}
        onKeyDown={onKeyDown}
      />
      {showErrorOnInput === '1' ? (
        <div className="text-red-500">{error}</div>
      ) : null}
    </span>
  );
};

export const InputBox2 = ({
  onKeyDown,
  name,
  type = 'text',
  holder,
  value,
  onChange,
}) => {
  return (
    <span className="flex flex-col ">
      <div className="flex">
        <label htmlFor="">{name}</label>
        <span className="text-red-500">*</span>
      </div>
      <input
        disabled
        type={type}
        id={name.toLowerCase().replace(/\s+/g, '')}
        placeholder={holder}
        className="p-3 rounded-lg outline-0 mt-2 placeholder-text-sm placeholder-tracking-[0.6px] bg-gray-100 cursor-not-allowed"
        onChange={onChange}
        value={value}
        onKeyDown={onKeyDown}
        readOnly
      />
    </span>
  );
};

export const InputBox1 = ({
  onKeyDown,
  name,
  type = 'text',
  holder,
  value,
  onChange,
  error,
  showErrorOnInput,
}) => {
  return (
    <span className="flex flex-col text-[14px] text-[#111827]">
      <span className="flex">
        <label htmlFor="">{name}</label>
        {name !== 'Last Name' && <span className="text-red-500">*</span>}
      </span>
      <input
        type={type}
        id={name.toLowerCase().replace(/\s+/g, '')}
        placeholder={holder}
        className={`p-2 outline-0 bg-[#FBFBFB] border-[1px] border-[#22284E] rounded-lg mt-2 placeholder-text-sm`}
        onChange={onChange}
        value={value}
        onKeyDown={onKeyDown}
      />
      {showErrorOnInput === '1' ? (
        <div className="text-red-500">{error}</div>
      ) : null}
    </span>
  );
};

export const InputBoxRed = ({
  onKeyDown,
  name,
  type = 'text',
  holder,
  value,
  onChange,
  error,
  showErrorOnInput,
  openEditConfirmation,
}) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const [isEmpty, setIsEmpty] = useState(!value);

  useEffect(() => {
    setShowTooltip(true);
    const timer = setTimeout(() => {
      setShowTooltip(false);
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    setIsEmpty(!value);
  }, [value]);

  const handleIconClick = () => {
    setShowTooltip((prev) => !prev);
  };

  return (
    <span className="flex flex-col ">
      <span className="flex">
        <label htmlFor="">{name}</label>
        <span className="text-red-500">*</span>
        {openEditConfirmation && (
          <>
            <FaInfoCircle
              data-tooltip-id="skillsInfo"
              className="ml-2 cursor-pointer text-gray-500"
              onClick={handleIconClick}
            />
            <Tooltip
              id="skillsInfo"
              arrow={false}
              place="right"
              effect="solid"
              isOpen={showTooltip}
              onMouseEnter={() => setShowTooltip(true)}
              onMouseLeave={() => setShowTooltip(false)}
            >
              You need to change the Job Role Name mandatorily.
            </Tooltip>
          </>
        )}
      </span>
      <input
        type={type}
        id={name.toLowerCase().replace(/\s+/g, '')}
        placeholder={holder}
        className={`p-3 outline-0 ${
          isEmpty && openEditConfirmation
            ? 'border-red-500'
            : 'border-[#22284E]'
        } border rounded-lg mt-2 placeholder-text-sm`}
        onChange={onChange}
        value={value}
        onKeyDown={onKeyDown}
      />
      {showErrorOnInput === '1' ? (
        <div className="text-red-500">{error}</div>
      ) : null}
    </span>
  );
};

const UserForm = ({
  uploadFile,
  userEmail,
  emptySubmit,
  campType,
  handleExpeChange,
  handleExpeMonthsChange,
  newExp,
  newExpMonths,
  setUploadResume,
  uploadResume,
  step,
  current,
  handleClick,
}) => {
  const firstName = getLocal('candFName');
  const email = getLocal('candEmail');
  const lastName = getLocal('candLName');
  const job = getLocal('jobRole');
  const number = getLocal('candMobile');
  const com = getLocal('organization');
  const initialExp = useSelector(
    (state) => state?.candidateData?.user?.work_experience
  );
  const [exp, setExp] = useState(initialExp);
  const [monthsExp, setMonthsExp] = useState(initialExp);
  console.log(campType);
  console.log(com);

  const user = userEmail;
  const uid = useSelector((state) => state?.login?.user?.uid);
  const firstName1 = useSelector((state) => state?.fillForm?.firstName);
  const lastName1 = useSelector((state) => state?.fillForm?.lastName);
  const job1 = getLocal('roleName');
  const number1 = useSelector((state) => state?.fillForm?.number);
  const exp1 = useSelector((state) => state?.fillForm?.exp);
  const com1 = getLocal('organization');
  const email1 = useSelector((state) => state?.fillForm?.email);
  const [error1, setError1] = useState('');
  console.log(job1);
  const dispatch = useDispatch();
  const [forceRender, setForceRender] = useState(false);
  const [previousJD, setPreviousJD] = useState(null);
  const navigate = useNavigate();

  const handleDropResume = (e) => {
    e.preventDefault();
    const permissible = ['.pdf'];
    const files = Array.from(e.dataTransfer.files);
    if (
      files.length &&
      permissible.some((ext) => files[0].name.endsWith(ext))
    ) {
      setUploadResume(files[0]);
    }
  };

  const handleUploadResume = (e) => {
    const file = e.target.files[0];
    const extension = file.name.split('.').pop().toLowerCase();
    const allowedExtensions = [
      'pdf',
      'docx',
      'csv',
      'xlsx',
      'odt',
      'txt',
      'ods',
    ];

    if (allowedExtensions.includes(extension)) {
      setUploadResume(file);
    } else {
      toast.warning(
        'Unsupported file type. Please upload a PDF, DOCX, or CSV file.',
        {
          toastStyle: {
            borderLeft: '5px solid #ffeb3b',
          },
        }
      );
      setUploadResume('');
    }
  };

  const handleRemoveResume = () => {
    sessionStorage.removeItem('file');
    const currentFileName = uploadResume ? uploadResume.name : null;
    setUploadResume(null);
    const fileInput = document.getElementById('resume-upload');
    if (fileInput) {
      fileInput.value = '';
    }
    const handleUploadResumeAgain = (e) => {
      const file = e.target.files[0];
      if (file && file.name === currentFileName) {
        setUploadResume(file);
      } else {
        setUploadResume(file);
      }
    };
    fileInput.addEventListener('change', handleUploadResumeAgain, {
      once: true,
    });
    setTimeout(() => {
      setForceRender((prev) => !prev);
    }, 0);
  };

  const handleFirstNameChange = (e) => {
    const name = e.target.value;

    dispatch(setFirstName1(name));
  };

  const handleLastNameChange = (e) => {
    const lastName = e.target.value;
    dispatch(setLastName1(lastName));
  };

  const handleEmailChange = (e) => {
    dispatch(setEmail(e.target.value));
  };

  const handleNumberChange = (e) => {
    const inputNumber = e.target.value.replace(/\D/g, '');
    if (inputNumber.length <= 10) {
      dispatch(setNumber1(inputNumber));
      setError1('Enter valid mobile number.');
    }
  };

  const handleExpChange = (e) => {
    let inputNumber = e.target.value.replace(/\D/g, '');
    inputNumber = Math.min(60, parseInt(inputNumber, 10)).toString();

    dispatch(setExperience(inputNumber));
  };

  const handleExpChange1 = (e) => {
    if (/^\d{0,2}$/.test(e.target.value)) {
      setExp(e.target.value);
      handleExpeChange(e.target.value);
    }
  };

  const handleExpMonthsChange1 = (e) => {
    const value = e.target.value;
    if (
      /^\d{0,2}$/.test(value) &&
      (value === '' || (Number(value) >= 0 && Number(value) <= 12))
    ) {
      setMonthsExp(value);
      handleExpeMonthsChange(value);
    }
  };

  const handleKeyDown = (e) => {
    const key = e.key;
    if (!key.match(/[a-zA-Z]/)) {
      e.preventDefault();
    }
  };
  console.log('uploadResume?.name', uploadResume?.name);
  console.log('uploadFile?.name', uploadFile?.name);
  console.log('uploadResume', uploadResume);
  if (campType === 'Regular') {
    return (
      <div className="h-screen items-center">
        <div className=" flex flex-col h-screen text-white flex justify-center items-center">
          <div
            className=" w-[97vw] h-[92vh]  rounded-[24px]  "
            style={{
              border: '1px solid',
              borderImageSource:
                'linear-gradient(1.06deg, rgba(0, 77, 244, 0) 13.7%, rgba(0, 77, 244, 0.4) 27.55%, #004DF4 36.4%, #26DDFF 53.88%, rgba(38, 221, 255, 0) 89.18%)',
              borderImageSlice: 1,
            }}
          >
            <div className="flex justify-around gap-4 min-h-[4.5rem]">
              <div className="w-full rounded-t-[24px] flex items-center justify-between py-4 px-4 border-x-[1.125rem] border-t-[1.125rem] border-[#286497] border-opacity-[27%] ">
                <div
                  className="  rounded-[8px] shadow-2xl p-2 w-full  flex felx-col fill-available justify-between items-center relative"
                  style={{
                    background:
                      'linear-gradient(to bottom, rgb(27,51,81,0.3) 16%, rgb(40,100,151,0.3) 93%)',
                    border: '1px solid',
                    borderImageSource:
                      'linear-gradient(151.06deg, rgba(0, 77, 244, 0) 13.7%, rgba(0, 77, 244, 0.4) 27.55%, #004DF4 36.4%, #26DDFF 53.88%, rgba(38, 221, 255, 0) 89.18%)',
                    borderImageSlice: 1,
                  }}
                >
                  <img
                    src={clevercruit}
                    alt="clevercruit logo"
                    className="pl-2 h-8 "
                  />
                </div>
              </div>
            </div>

            <div className=" custom-sm:h-available-sm custom-lg:h-available-sm custom-md:h-available-sm backdrop-blur bg-[rgb(40,100,151,0.27)] flex flex-col rounded-b-[24px] overflow-auto ">
              <div className="flex h-full w-full custom-sm:justify-start custom-lg:justify-center custom-md:justify-around gap-8 scale-[0.8]  custom-md:items-center">
                <div>
                  <div className="flex flex-col gap-[1px] ">
                    <p className="text-[32px] text-bold text-[#00B7FD] ">
                      {step[current].name}
                    </p>
                    <p className="text-[14px] mb-[10px] font-medium text-white">
                      Please provide your Personal Details.
                    </p>
                  </div>

                  <div className="bg-[#28649745] rounded-2xl p-4 ">
                    <div
                      className="px-12  custom-md:w-[45rem] "
                      style={{
                        border: '1px solid',
                        borderImageSource:
                          'linear-gradient(2.06deg, rgba(0, 77, 244, 0) 13.7%, rgba(0, 77, 244, 0.4) 27.55%, #004DF4 36.4%, #26DDFF 53.88%, rgba(38, 221, 255, 0) 89.18%)',
                        borderImageSlice: 1,
                        borderRadius: '12px',
                      }}
                    >
                      <form
                        action=""
                        className="flex flex-col gap-[16px] fill-available"
                      >
                        <div className="grid custom-md:grid-cols-2 custom-sm:grid-cols-1 gap-4">
                          <InputBox4
                            name={'First Name'}
                            holder={'Enter first name'}
                            value={firstName}
                            readOnly
                          />
                          <InputBox4
                            name={'Last Name'}
                            holder={'Enter last name'}
                            value={lastName}
                            readOnly
                          />
                          <InputBox4
                            name={'Email'}
                            type="email"
                            holder={'Enter email'}
                            value={email}
                            readOnly
                          />
                          <InputBox4
                            name={'Mobile Number'}
                            type="number"
                            holder={'Enter mobile number'}
                            value={number}
                            readOnly
                          />
                          <span className="flex flex-col ">
                            <div className="flex mb-[5px]">
                              <label className="text-white" htmlFor="">
                                Job Profile
                              </label>
                              <span className="text-red-500">*</span>
                            </div>

                            <div className="p-[1px] rounded-xl">
                              <div
                                className="w-full h-[40px] max-w-md p-[1px] rounded-xl bg-gradient-to-b from-[#2867B1] to-transparent "
                                style={{
                                  boxShadow: `0px 4px 6px rgba(0, 0, 0, 0.6)`,
                                }}
                              >
                                <input
                                  disabled
                                  type="text"
                                  placeholder="Enter job profile"
                                  value={job}
                                  className="h-[38px] cursor-not-allowed w-full p-[20px] rounded-xl border-none text-white placeholder:text-white focus:outline-none"
                                  style={{
                                    background:
                                      'linear-gradient(to bottom, rgb(27,51,81) 16%, rgb(40,100,151,0.3) 93%)',
                                  }}
                                  readOnly
                                />
                              </div>
                            </div>
                          </span>
                          <InputBox4
                            name={'Company'}
                            holder={'Enter company name'}
                            value={com}
                            readOnly
                          />
                        </div>
                        <InputBox5
                          name={'Total years of Experience'}
                          type="number"
                          holder={'Enter total years of experience'}
                          value={newExp}
                          value1={newExpMonths}
                          onChange={handleExpChange1}
                          holder1={'Enter total months of experience'}
                          onChange1={handleExpMonthsChange1}
                          name1={'Total months of Experience'}
                        />
                        <div className="flex flex-col mb-4 fill-available">
                          <div className="flex">
                            <label htmlFor="" className="text-white mb-[2px]">
                              Upload Resume
                            </label>
                            <span className="text-red-500">*</span>
                          </div>
                          <div
                            className="bg-[#192b4c] relative text-white placeholder:text-[#8893A2] focus:outline-none flex items-center w-full p-[1px] rounded-xl bg-gradient-to-b from-[#2867B1] to-transparent "
                            style={{
                              boxShadow: `0px 4px 6px rgba(0, 0, 0, 0.6)`,
                            }}
                          >
                            <input
                              type="text"
                              value={uploadResume?.name || uploadFile?.name}
                              placeholder="Drag or drop your Resume here (PDF, DOCX, CSV)"
                              className=" h-[40px] flex-grow p-3.5 rounded-xl border-none text-white placeholder:text-[#8893A2] focus:outline-none"
                              style={{
                                background:
                                  'linear-gradient(to bottom, rgb(27,51,81) 16%, rgb(40,100,151,0.3) 93%)',
                              }}
                              readOnly
                            />
                            <div className=" absolute top-2 right-3 z- 10 p-[1px] bg-gradient-to-b from-[#01FFFF] via-transparent to-transparent rounded-lg">
                              <div
                                className="  flex items-center justify-center cursor-pointer h-[26px] w-[118px] bg-[#0075FF] text-white rounded-lg"
                                onDrop={handleDropResume}
                                onDragOver={(e) => e.preventDefault()}
                              >
                                <label
                                  htmlFor="resume-upload"
                                  className="flex items-center justify-center gap-[0.4rem] cursor-pointer "
                                >
                                  <img src={importIcon} alt="upload icon" />
                                  Browse
                                  <input
                                    type="file"
                                    id="resume-upload"
                                    className="hidden"
                                    onChange={handleUploadResume}
                                  />
                                </label>
                              </div>
                            </div>
                          </div>

                          {/* {(uploadResume?.name || uploadFile?.name) && (
                  <div
                    className={`bg-fill-btn-color-code1 p-4 rounded-lg flex justify-between ${uploadResume ? "block" : "hidden"
                      }`}
                  >
                    <p>{uploadResume?.name || uploadFile?.name || ""}</p>
                    <p onClick={handleRemoveResume}>Close</p>
                  </div>
                )} */}
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="gap-2 mt-4 flex">
                    <BackButton
                      onClick={() => {
                        navigate('../');
                      }}
                    />
                    <div className="w-[187px]">
                      <NextButton2 onClick={handleClick} />
                    </div>
                  </div>
                </div>

                <div className="custom-sm:hidden custom-md:block">
                  <img
                    src={formImage}
                    alt="form image"
                    className="h-[600px] min-w-[300px]"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  console.log(firstName1);
  console.log(lastName1);
  console.log(email1);
  console.log(number1);
  console.log(exp1);
  console.log('camp type ==', campType);

  if (campType === 'Mass') {
    return (
      <div className="h-max flex justify-center w-[100vw] ">
        <div className=" w-[96vw] h-[90vh]  mt-[3vh] rounded-2xl border-[1.5px] backdrop-blur-md border-blue-700 p-4">
          <div className="flex justify-around gap-4">
            <span className="w-full h-[4.5rem]  rounded-xl flex items-center justify-between border-[15px] border-black border-opacity-25 ">
              <img src={clevercruit} alt="clevercruit logo" className="ml-2" />
            </span>
          </div>
          <div className="flex h-full w-full justify-center gap-8 scale-[0.9] mt-[-1.8rem] items-center">
            <div>
              <div className="flex flex-col gap-[1px] ">
                <p className="text-[32px] text-bold text-[#00B7FD] ">
                  {step[current].name}
                </p>
                <p className="text-[14px] mb-[10px] font-medium text-white">
                  Please provide your Personal Details.
                </p>
              </div>

              <div
                style={{
                  backgroundImage: `url(${detailsBox})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  width: '721px',
                  height: '509px',
                  position: 'relative',
                }}
              >
                <img
                  src={detailsBoxBorder}
                  alt="border image details page"
                  style={{
                    position: 'absolute',
                    top: '-8px',
                    left: '8px',
                    width: '44rem', // Set the width of the overlay image to fit within the container
                    height: '-webkit-fill-available', // Set the height of the overlay image
                  }}
                />
                <div
                  style={{
                    padding: '0.5rem 3rem',
                    position: 'relative',
                    zIndex: 1,
                  }}
                >
                  <form
                    action=""
                    className="flex flex-col gap-[16px] fill-available"
                  >
                    <div className="grid grid-cols-2 gap-4">
                      <InputBox6
                        name={'First Name'}
                        holder={'Enter first name'}
                        value={firstName1}
                        onChange={handleFirstNameChange}
                        // onKeyDown={handleKeyDown}
                        emptySubmit={emptySubmit}
                        required
                      />
                      {/* <InputBox4
                  name={"First Name"}
                  holder={"Enter first name"}
                  value={firstName}
                  readOnly
                /> */}
                      <InputBox6
                        name={'Last Name'}
                        holder={'Enter last name'}
                        value={lastName1}
                        onChange={handleLastNameChange}
                        // onKeyDown={handleKeyDown}
                        emptySubmit={emptySubmit}
                        required
                      />
                      {/* <InputBox4
                  name={"Last Name"}
                  holder={"Enter last name"}
                  value={lastName}
                  readOnly
                /> */}
                      <InputBox6
                        name={'Email'}
                        type="email"
                        holder={'Enter email'}
                        emptySubmit={emptySubmit}
                        onChange={handleEmailChange}
                        value={email1}
                      />
                      {/* <InputBox4
                  name={"Email"}
                  type="email"
                  holder={"Enter email"}
                  value={email}
                  readOnly
                /> */}
                      <InputBox6
                        name={'Mobile Number'}
                        type="text"
                        holder={'Enter mobile number'}
                        value={number1}
                        emptySubmit={emptySubmit}
                        onChange={handleNumberChange}
                        required
                      />
                      {/* <InputBox4
                  name={"Mobile Number"}
                  type="number"
                  holder={"Enter mobile number"}
                  value={number}
                  readOnly
                  /> */}
                      <InputBox4
                        name={'Job Profile'}
                        holder={'Enter job profile'}
                        value={job1}
                        readOnly
                      />
                      <InputBox4
                        name={'Company'}
                        holder={'Enter company name'}
                        value={com1}
                        readOnly
                      />
                      {/* <InputBox2
            name={"Company"}
            holder={"Enter company name"}
            value={com1}
          /> */}

                      {/* <span className="flex flex-col ">
                  <div className="flex mb-[10px]">
                    <label className="text-white" htmlFor="">Job Profile</label>
                    <span className="text-red-500">*</span>
                  </div> */}

                      {/* <input
                  type="text"
                  placeholder="Enter job profile"
                  className="p-3 rounded-lg outline-0 mt-2 placeholder:text-sm placeholder:tracking-[0.6px] bg-gray-100 cursor-not-allowed"
                  value={job}
                  readOnly
                /> */}
                      {/* <div className="w-full max-w-md p-[1px] rounded-xl bg-gradient-to-r from-[#3DBDF1] via-[#3DBDF1] to-[#1A6F92]">
                    <input
                      type="text"
                      placeholder="Enter job profile"
                      value={job}
                      className="cursor-not-allowed w-full p-3.5 rounded-xl border-none bg-[#192b4c] text-white placeholder:text-white focus:outline-none"
                      readOnly
                    />
                  </div>
                </span> */}
                    </div>
                    {/* <InputBox5
                name={"Total years of Experience"}
                type="number"
                holder={"Enter total years of experience"}
                value={newExp}
                value1={newExpMonths}
                onChange={handleExpChange1}
                holder1={"Enter total months of experience"}
                onChange1={handleExpMonthsChange1}
                name1={"Total months of Experience"}
              /> */}
                    <InputBox5
                      name={'Total years of Experience'}
                      type="number"
                      holder={'Enter total years of experience'}
                      value={newExp}
                      value1={newExpMonths}
                      onChange={handleExpChange1}
                      holder1={'Enter total months of experience'}
                      onChange1={handleExpMonthsChange1}
                      name1={'Total months of Experience'}
                      // readOnly
                    />
                    <div className="flex flex-col fill-available">
                      <div className="flex">
                        <label htmlFor="" className="text-white mb-[2px]">
                          Upload Resume
                        </label>
                        <span className="text-red-500">*</span>
                      </div>
                      <div
                        className="bg-[#192b4c] relative text-white placeholder:text-[#8893A2] focus:outline-none flex items-center w-full p-[1px] rounded-xl bg-gradient-to-r from-[#3DBDF1] via-[#3DBDF1] to-[#1A6F92]"
                        style={{
                          boxShadow: `0px 4px 6px rgba(0, 0, 0, 0.6)`,
                        }}
                      >
                        <input
                          type="text"
                          value={uploadResume?.name || uploadFile?.name}
                          placeholder="Drag or drop your Resume here (PDF, DOCX, CSV)"
                          className="h-[40px] flex-grow p-3.5 rounded-xl border-none bg-[#192b4c] text-white placeholder:text-[#8893A2] focus:outline-none"
                          readOnly
                        />
                        <div
                          className="absolute top-2 right-3 z- 10 flex items-center justify-center cursor-pointer h-[26px] w-[118px] bg-[#0075FF] text-white rounded-lg ml-2"
                          onDrop={handleDropResume}
                          onDragOver={(e) => e.preventDefault()}
                        >
                          <label
                            htmlFor="resume-upload"
                            className="flex items-center justify-center gap-[0.4rem] cursor-pointer"
                          >
                            <img src={importIcon} alt="upload icon" />
                            Browse
                            <input
                              type="file"
                              id="resume-upload"
                              className="hidden"
                              onChange={handleUploadResume}
                            />
                          </label>
                        </div>
                      </div>

                      {/* {(uploadResume?.name || uploadFile?.name) && (
                <div
                  className={`bg-fill-btn-color-code1 p-4 rounded-lg flex justify-between ${uploadResume ? "block" : "hidden"
                    }`}
                >
                  <p>{uploadResume?.name || uploadFile?.name || ""}</p>
                  <p onClick={handleRemoveResume}>Close</p>
                </div>
              )} */}
                    </div>
                  </form>
                  <div
                    className="w-[187px] flex mt-[20px] gap-[15px] mb-4"
                    style={{
                      boxShadow: `0px 4px 6px rgba(0, 0, 0, 0.6)`,
                    }}
                    onClick={handleClick}
                  >
                    <NextButton1 />
                  </div>
                </div>
              </div>
              <div></div>
            </div>
            <div>
              <img src={formImage} alt="form image" className="h-[600px]" />
            </div>
          </div>
        </div>
      </div>

      // <form action="" className=" flex flex-col gap-6 fill-available">
      //   <div className=" grid grid-cols-2 gap-6">
      //     <InputBox6
      //       name={"First Name"}
      //       holder={"Enter first name"}
      //       value={firstName1}
      //       onChange={handleFirstNameChange}
      //       onKeyDown={handleKeyDown}
      //       emptySubmit={emptySubmit}
      //       required
      //     />
      //     <InputBox6
      //       name={"Last Name"}
      //       holder={"Enter last name"}
      //       value={lastName1}
      //       onChange={handleLastNameChange}
      //       onKeyDown={handleKeyDown}
      //       emptySubmit={emptySubmit}
      //       required
      //     />
      //     <InputBox6
      //       name={"Email"}
      //       type="email"
      //       holder={"Enter email"}
      //       emptySubmit={emptySubmit}
      //       onChange={handleEmailChange}
      //       value={email1}
      //     />
      //     <InputBox6
      //       name={"Mobile Number"}
      //       type="text"
      //       holder={"Enter mobile number"}
      //       value={number1}
      //       emptySubmit={emptySubmit}
      //       onChange={handleNumberChange}
      //       required
      //     />
      //     <InputBox4
      //       name={"Job Profile"}
      //       holder={"Enter job profile"}
      //       value={job1}
      //       readOnly
      //     />
      //     <InputBox2
      //       name={"Company"}
      //       holder={"Enter company name"}
      //       value={com1}
      //     />
      //   </div>
      //   <InputBox5
      //     name={"Total years of Experience"}
      //     type="number"
      //     holder={"Enter total years of experience"}
      //     value={newExp}
      //     value1={newExpMonths}
      //     onChange={handleExpChange1}
      //     holder1={"Enter total months of experience"}
      //     onChange1={handleExpMonthsChange1}
      //   // readOnly
      //   />
      //   <div className="flex flex-col fill-available">
      //     <label htmlFor="" className="mb-2">
      //       Resume
      //     </label>
      //     <div
      //       className="bg-white border-2 flex flex-col h-[12.56rem] items-center gap-3"
      //       onDrop={handleDropResume}
      //       onDragOver={(e) => e.preventDefault()}
      //     >
      //       <img src={resumeUpload} alt="upload" className="mt-[1.9rem]" />
      //       <p>Drag and drop your Resume here (PDF, DOCX, CSV)</p>
      //       <label
      //         htmlFor="resume-upload"
      //         className="cursor-pointer h-[1.5rem] w-[4.125rem] text-center"
      //       >
      //         Browse
      //         <input
      //           type="file"
      //           id="resume-upload"
      //           className="hidden"
      //           onChange={handleUploadResume}
      //         />
      //       </label>
      //     </div>

      //     <div
      //       className={`bg-fill-btn-color-code1 p-4 rounded-lg flex justify-between ${uploadResume ? "block" : "hidden"
      //         }`}
      //     >
      //       <p>{uploadResume?.name || ""}</p>
      //       <p onClick={handleRemoveResume}>Close</p>
      //     </div>
      //   </div>
      // </form>
    );
  }
};

export const UploadResume = ({
  uploadResume,
  setUploadResume,
  setUploadJD,
  uploadJD,
}) => {
  const [forceRender, setForceRender] = useState(false);
  const [previousJD, setPreviousJD] = useState(null);

  const handleDropResume = (e) => {
    e.preventDefault();
    const permissible = ['.pdf'];
    const files = Array.from(e.dataTransfer.files);
    if (
      files.length &&
      permissible.some((ext) => files[0].name.endsWith(ext))
    ) {
      setUploadResume(files[0]);
    }
  };

  const handleUploadResume = (e) => {
    const file = e.target.files[0];
    const extension = file.name.split('.').pop().toLowerCase();
    const allowedExtensions = [
      'pdf',
      'docx',
      'csv',
      'xlsx',
      'odt',
      'txt',
      'ods',
    ];

    if (allowedExtensions.includes(extension)) {
      setUploadResume(file);
    } else {
      toast.warning(
        'Unsupported file type. Please upload a PDF, DOCX, or CSV file.',
        {
          toastStyle: {
            borderLeft: '5px solid #ffeb3b',
          },
        }
      );
      setUploadResume('');
    }
  };

  const handleRemoveResume = () => {
    const currentFileName = uploadResume ? uploadResume.name : null;
    setUploadResume(null);
    const fileInput = document.getElementById('resume-upload');
    if (fileInput) {
      fileInput.value = '';
    }
    const handleUploadResumeAgain = (e) => {
      const file = e.target.files[0];
      if (file && file.name === currentFileName) {
        setUploadResume(file);
      } else {
        setUploadResume(file);
      }
    };
    fileInput.addEventListener('change', handleUploadResumeAgain, {
      once: true,
    });
    setTimeout(() => {
      setForceRender((prev) => !prev);
    }, 0);
  };

  return (
    <section className="fill-available flex gap-4 fill-available">
      <div className="flex flex-col fill-available">
        <div
          className="bg-white border-2 flex flex-col h-[12.56rem] items-center gap-3"
          onDrop={handleDropResume}
          onDragOver={(e) => e.preventDefault()}
        >
          <img src={resumeUpload} alt="upload" className="mt-[1.9rem]" />
          <p>Drag and drop your Resume here (PDF, DOCX, CSV)</p>
          <label
            htmlFor="resume-upload"
            className="cursor-pointer border-2 h-[1.5rem] w-[4.125rem] text-center"
          >
            Browse
            <input
              type="file"
              id="resume-upload"
              className="hidden"
              onChange={handleUploadResume}
            />
          </label>
        </div>

        <div
          className={`bg-fill-btn-color-code1 p-4 rounded-lg flex justify-between ${
            uploadResume ? 'block' : 'hidden'
          }`}
        >
          <p>{uploadResume?.name || ''}</p>
          <p onClick={handleRemoveResume}>Close</p>
        </div>
      </div>
    </section>
  );
};

const CheckBox = ({ isChecked, setIsChecked, idx }) => {
  const handleClick = () => {
    const updatedChecked = [...isChecked];
    updatedChecked[idx].checked = !updatedChecked[idx].checked;
    setIsChecked(updatedChecked);
  };

  return (
    <section className="mt-[4px] cursor-pointer">
      <div
        className={`w-4 h-4 ${
          isChecked[idx].checked
            ? 'border-0 bg-white-500 relative'
            : 'block border-[0.1rem] border-text-color-code1'
        }`}
        onClick={handleClick}
      >
        {isChecked[idx].checked ? (
          <img
            src={Check}
            alt="checked"
            className="w-[1.24rem] h-4 absolute z-[10]"
          />
        ) : null}
      </div>
    </section>
  );
};

const Guidelines = ({
  setIsAllCheck,
  isAllCheck,
  handleClick,
  handleClickBack,
  current,
}) => {
  const initialGuidelines = [
    {
      checked: false,
      text: 'Allow your camera and microphone permission to proceed with the interview.',
    },
    {
      checked: false,
      text: 'Give the permission of entire screen sharing to proceed with the interview.',
    },
    {
      checked: false,
      text: 'You will be given 90 seconds for each question and to submit your response early you can turn off the mike after completing the answer and it will automatically send your response.',
    },
    {
      checked: false,
      text: 'Your response will be automatically submitted after 90 seconds.',
    },
    {
      checked: false,
      text: 'For coding question you will be given 30 minutes. Click on Submit button after completing the interview.',
    },
    {
      checked: false,
      text: 'Then you can stop sharing your screen and click on End Interview after completing the interview.',
    },
  ];
  const [isChecked, setIsChecked] = useState(initialGuidelines);

  useEffect(() => {
    const allChecked = isChecked.every((item) => item.checked === true);
    console.log(allChecked);
    setIsAllCheck(allChecked);
  }, [isAllCheck, isChecked]);

  return (
    <section className=" fill-available h-screen custom-md:pt-[85px] custom-sm:pt-[45px] flex flex-col custom-sm:gap-2 custom-md:gap-6 justify-center items-center ">
      <p className="text-center">
        <b className="custom-md:text-[32px] custom-sm:text-[24px]  text-[#00B7FD]">
          Read all guidelines carefully.
        </b>
        <p className="custom-md:text-[1rem] custom-sm:text-[1rem] w-[35rem] text-white text-center font-[manrope]">
          Lorem ipsum perspiciatis modi eaque, quisquam libero error, pariatur
          nesciunt accusamus itaque mollitia delectus.
        </p>
      </p>
      <div
        className="flex custom-sm:justify-start custom-md:justify-center items-center custom-md:w-[50%] custom-sm:w-[70%]   custom-lg:w-[50vw] overflow-auto flex-col  p-6 rounded-3xl bg-white"
        style={{
          backgroundImage: `url(${FillBg})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
        }}
      >
        <div className="flex justify-center custom-lg:w-[45vw]  overflow-auto items-center border-2 border-[rgb(1,15,36,0.3)] p-4 rounded-3xl">
          <div className="rounded-[16px]  text-[18px] font-[manrope] flex flex-col gap-2 custom-md:h-[40vh] max-h-[60vh] custom-lg:gap-7 custom-lg:h-[45vh]">
            {isChecked.map((item, idx) => (
              <div className="flex gap-2 items-start" key={idx}>
                <CheckBox
                  setIsChecked={setIsChecked}
                  isChecked={isChecked}
                  idx={idx}
                />
                <div className={`text-base font-medium text-[#1D1D1D] `}>
                  {item.text}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="gap-2 flex">
        <BackButton onClick={handleClickBack} />
        <div className="w-[187px]">
          <NextButton2
            onClick={handleClick}
            current={current}
            isAllCheck={isAllCheck}
          />
        </div>
      </div>
    </section>
  );
};

export const UploadResumeOnly = ({
  uploadResume,
  setUploadResume,
  setEditResumeChange,
  error,
}) => {
  const [forceRender, setForceRender] = useState(false);
  const handleDropResume = (e) => {
    e.preventDefault();
    const permissible = ['.pdf'];
    const files = Array.from(e.dataTransfer.files);
    if (
      files.length &&
      permissible.some((ext) => files[0].name.endsWith(ext))
    ) {
      setUploadResume(files[0]);
    }
  };

  const handleUploadResume = (e) => {
    const file = e.target.files[0];
    const extension = file.name.split('.').pop().toLowerCase();
    const allowedExtensions = ['pdf', 'docx', 'doc'];
    if (allowedExtensions.includes(extension)) {
      setUploadResume(file);
    }
  };

  console.log(uploadResume);
  const handleRemoveResume = () => {
    setEditResumeChange(true);
    const currentFileName = uploadResume ? uploadResume.name : null;
    setUploadResume(null);
    const fileInput = document.getElementById('resume-upload');
    if (fileInput) {
      fileInput.value = '';
    }
    const handleUploadResumeAgain = (e) => {
      const file = e.target.files[0];
      if (file && file.name === currentFileName) {
        setUploadResume(file);
      } else {
        setUploadResume(file);
      }
    };
    fileInput.addEventListener('change', handleUploadResumeAgain, {
      once: true,
    });
    setTimeout(() => {
      setForceRender((prev) => !prev);
    }, 0);
  };

  return (
    <section className="border-[1px] rounded-lg border-[#22284E] fill-available h-[42px] p-2 pl-3 flex gap-4">
      <div className="flex flex-col fill-available">
        <div
          className="bg-white  relative flex justify-between h-[12.56rem] justify-center items-center gap-3"
          onDrop={handleDropResume}
          onDragOver={(e) => e.preventDefault()}
        >
          <p className={uploadResume ? 'text-black' : 'text-[#A6AEBA]'}>
            {uploadResume?.name ||
              (uploadResume &&
                uploadResume.split('/').pop().split('.').shift()) ||
              'Drag and drop your JD here (PDF, DOCX)'}
          </p>

          {/* <label
            htmlFor="resume-upload"
            className="cursor-pointer h-[1.5rem] w-[4.125rem] text-center"
          >
            Browse
            <input
              type="file"
              id="resume-upload"
              className="hidden"
              accept=".doc,.docx,.pdf,.csv"
              onChange={handleUploadResume}
            />
          </label> */}
          <div
            className="absolute right-0 z- 10 flex items-center justify-center cursor-pointer h-[26px] w-[118px] bg-[#22284E] text-white rounded-lg ml-2"
            onDrop={handleDropResume}
            onDragOver={(e) => e.preventDefault()}
          >
            <label
              htmlFor="resume-upload"
              className="cursor-pointer h-[1.5rem] text-center flex items-center gap-2"
            >
              <img src={importIcon} alt="upload icon" />
              Browse
              <input
                type="file"
                id="resume-upload"
                className="hidden"
                accept=".doc,.docx,.pdf,.csv"
                onChange={handleUploadResume}
              />
            </label>
          </div>
        </div>

        {/* <div
          className={`bg-fill-btn-color-code1 p-4 rounded-lg flex justify-between ${uploadResume ? 'block' : 'hidden'
            }`}
        >
          <p>
            {uploadResume?.name ||
              (uploadResume &&
                uploadResume.split("/").pop().split(".").shift())}
          </p>
          <p onClick={handleRemoveResume}>Close</p>
        </div> */}
        {error['jd'] === '1' ? (
          <div className="text-red-500">Fill the resume</div>
        ) : null}
      </div>
    </section>
  );
};
