import { toast } from 'react-toastify';
import { handleApiResponse } from '../../API/services';
import {
  bell,
  clevercruit,
  clevercruitlogo,
  closeButton,
  coin,
  cross,
  logo,
  userImg,
} from '../../assets/imgs';
import { getLocal } from '../../utils/localStorage';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { concatenateArgs } from '../../utils/validation';

export const NavBar = (props) => {
  const [isProfilePageVisible, setIsProfilePageVisible] = useState(false);
  const last_name = getLocal('last_name');
  const first_name = getLocal('first_name');
  const Cname = getLocal('organization');
  const id = getLocal('companyId');
  const uid = getLocal('uid');
  const navigate = useNavigate('');
  const [total_credit, setTotalCredits] = useState(0);
  const [used_credit, setUsedCredits] = useState(0);
  const [addCredits, setAddCredits] = useState(null);
  const [openAddCredits, setOpenAddCredits] = useState(false);


  const percentageUsed = (used_credit / total_credit) * 100;
  const isCriticalUsage = percentageUsed >= 80;

  const call = async () => {
    const reqData = {};
    const response = await handleApiResponse('/api/v1/get_credit/', reqData, 1);
    if (response?.code === 'token_not_valid') {
      navigate('/');
      sessionStorage.clear();
      return;
    }
    if (response?.data?.status_code === 200) {
      setTotalCredits(response?.data?.data?.total_credits);
      sessionStorage.setItem('total_credit', response.data.data.total_credits);

      setUsedCredits(response.data.data.credit_used);
      sessionStorage.setItem('used_credit', response.data.data.credit_used);
    }
  };

  useEffect(() => {
    call();
  }, []);

  const addCreditCall = async () => {
    const reqData = {
      total_credits: addCredits,
    };
    const resonse = await handleApiResponse('/api/v1/add_credit/', reqData, 1);
    console.log(resonse.data.status_code);
    if (resonse?.code === 'token_not_valid') {
      navigate('/');
      sessionStorage.clear();
      return;
    }
    if (resonse.data.status_code) {
      toast.success('Credit added',{
        toastStyle: {
            borderLeft: "5px solid #4caf50", backgroundColor: "#00112D", color: "white" 
        },
      });
      setAddCredits(null);
      setOpenAddCredits(false);
    } else {
      toast.error('Something went wrong',{
            toastStyle: {
              borderLeft: "5px solid #f44336",
            },
          });
    }
  };

  const handleInputInPopUp = (credit) => {
    if (credit > 10000) {
      return;
    } else setAddCredits(credit);
  };

  const openCredit = () => {
    setOpenAddCredits(true);
  };

  return (
    <nav
      className="h-[12%] py-[1rem] flex justify-between"
      onClick={() => props.setIsShowPopup(false)}
    >
      {openAddCredits && (
        <div className="w-[100vw] h-[100vh] flex justify-center items-center absolute z-50 top-[0rem] left-[0rem] backdrop-blur">
          <span className="flex flex-col justify-between items-center p-12 bg-white w-[40%] h-[30%] relative">
            <img
              src={closeButton}
              alt="btn"
              className="absolute right-[1rem] top-[1rem] cursor-pointer"
              onClick={() => setOpenAddCredits(false)}
            />
            <p>Enter the updated credit amount</p>
            <input
              type="number"
              placeholder="Credit"
              className="border-2 p-2"
              value={addCredits}
              onChange={(e) => {
                handleInputInPopUp(e.target.value);
              }}
            />
            <button className="border-2 w-[5rem] p-1" onClick={addCreditCall}>
              Set
            </button>
          </span>
        </div>
      )}
      <p className=" flex items-center fill-available text-[1.375rem]  font-normal ml-[1.5rem]">
        <img src={clevercruitlogo} alt="" className="cursor-pointer" onClick={()=>navigate('/admin/dashboard')}/>
      </p>
      <section className=" flex justify-end items-center gap-4">
        <div
          className="flex w-[12rem] border-2 py-2 px-1 gap-2 rounded-3xl font-bold bg-white justify-center  cursor-pointer"
          onClick={openCredit}
        >
          <img src={coin} alt="" />
          <p>{total_credit - used_credit} </p>
          <p>Credits</p>
        </div>
        <div className="relative cursor-pointer mr-5 relative p-2 bg-white rounded-full">
          {isCriticalUsage && (
            <div className="w-3 h-3 bg-red-500 rounded-full absolute top-[-0.1rem] right-[-0.2rem] z-10"></div>
          )}
          <img
            src={bell}
            className="w-[2rem] "
            alt="bell"
            onClick={() => props.setOpenNotification(!props.openNotification)}
          />
          <div
            className={`${
              props.openNotification ? 'visible' : 'hidden'
            }  w-[371px] h-[511px] font-satoshi rounded-2xl absolute right-[0rem] bg-white 1 border-2 z-[100] p-4`}
          >
            <div className="flex justify-between items-center">
              <b>
                <i>Notifications</i>
              </b>
              <div>
                <img
                  src={cross}
                  onClick={() => props.setOpenNotification(!props.openNotification)}
                />
              </div>
            </div>
            <br />
            <br />
            <p
              className={`${
                isCriticalUsage ? 'text-red-500 bold' : 'text-black'
              }`}
            >
              {isCriticalUsage
                ? `${percentageUsed.toFixed(
                    2
                  )}% of your credits have been used.`
                : 'No Notifications for now'}
            </p>
          </div>
        </div>

        {/* <div className="gap-2  fill-available text-[1.375rem]  font-normal">
          <div className="flex flex-col gap-2 justify-end">
            <span className="flex gap-4">
              <img
                src={
                  sessionStorage.getItem('profileImage') === ''
                    ? userImg
                    : sessionStorage.getItem('profileImage')
                }
                className="w-[3vw] h-[33px]"
                alt="profile Image"
              />
              <div
                className="flex items-center cursor-pointer justify-end"
                onClick={() => showProfilePage()}
              >
                Hi, {first_name} {last_name}!{' '}
              </div>
            </span>
            <div className="text-[0.975rem] ml-[4vw] flex">
              {concatenateArgs(Cname, id)}
            </div>
          </div>
        </div> */}
      </section>
    </nav>
  );
};
