import React, { useEffect, useState } from 'react';
import { handleApiResponse } from '../../API/services';
import { useNavigate } from 'react-router-dom';
import { eyeButton } from '../../assets/imgs';
import { ModalChangeCampaign } from './modal';
import { getLocal } from '../../utils/localStorage';
import { CheckForInvalidToken } from '../../utils/helperFuntion';

export const Dropdown = (props) => {

  const getCampType = ['Regular', 'Mass'];
  const open =()=>{
    console.log("clicked")
     props.setToggle((prev) => ({
       ...prev,
       jobRole: false,
       CampType: !props.toggle.CampType,
     }));
     console.log("data => ",props.toggle.jobRole)
  }

  const handleToggleDropdown = () => {
    open();
  };
   const close = () => {
     props.setToggle((prev) => ({
       ...prev,
       jobRole: false,
       CampType: false,
     }));
   };

  const handleOption = (value) => {
    props?.setCampTypeValue(value);
    if (value === 'Regular'){
      props.setCampType(1);
    }
    else{
      props.setCampType(2);
    }
    console.log(value)
    close();
  };


  const handleKeyDown = (e) => {
    if (e.key === 'Tab') {
      close();
    }
  };

  const getCampType1 = () => {
    const campType = sessionStorage.getItem('camp_type');
    if (campType === '1') {
      return 'Regular';
    } else if (campType === '2') {
      return 'Mass';
    }
    return null;
  };
 
  return (
    <>
      <div className="relative inline-block" onKeyDown={handleKeyDown}>
        <button
          id="dropdownDefaultButton"
          data-dropdown-toggle="dropdown"
          className="w-[55vw] mt-2 justify-between bg-[#FFFFFF] focus:outline-none font-medium rounded-lg text-sm px-[0.75rem] py-2.5 text-center inline-flex items-center"
          type="button"
          onClick={handleToggleDropdown}
        >
          {props?.jobroleValue || props?.campTypeValue || getCampType1() || props.holder}
          <svg
            className="w-2.5 h-2.5 ms-3"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 10 6"
            transform={`${props.toggle.CampType ? 'rotate(180)' : 'rotate(0)'}`}
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="m1 1 4 4 4-4"
            />
          </svg>
        </button>
        <div
          id="dropdown"
          className={`${
            props.toggle.CampType ? 'block' : 'hidden'
          } absolute left-0 mt-2 w-[55vw] z-50 bg-[#FFFFFF] divide-y divide-gray-100 rounded-lg shadow dark:bg-gray-700`}
        >
          <ul
            className="py-2 cursor-pointer"
            aria-labelledby="dropdownDefaultButton"
          >
            {getCampType.map((ele, index) => {
              return (
                <li
                  key={index}
                  className="flex justify-between px-4 py-2 hover:bg-gray-100"
                  onClick={() => handleOption(ele)}
                >
                  {ele}
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </>
  );
};
 
export const DropdownJobRole = (props) => {
  // Initialize state
  const [selectedOption, setSelectedOption] = useState(sessionStorage.getItem('jrName') || null);
  const [searchInput, setSearchInput] = useState('');
  const [jd, setJd] = useState('');
  const navigate = useNavigate(); // Initialize the navigate function
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(props.jobroleValue);


  const handleToggleDropdown = (event) => {
    if (isButtonDisabled) {
      setIsPopupVisible(true);
    } else {
      if (props.data.length === 1) {
        getRole();
      }
      props.setToggle((prev) => ({
        ...prev,
        jobRole: !prev.jobRole,
        CampType: false,
      }));
    }
  };

  const close = () => {
    props.setToggle((prev) => ({
      ...prev,
      jobRole: false,
      CampType: false,
    }));
  };

  const handleSelectOption = (value) => {
    close();
    if (value.name === 'Fetching...') {
      return;
    }
    setSelectedOption(value.name); // Set the selected option
    setJd(value.jd);
    props?.setjobroleValue(value.name);
    console.log(value.name);
    props?.setJobRoleUid(value.uid);
    sessionStorage.setItem('expFrom', value.experience_from);
    sessionStorage.setItem('expTo', value.experience_to);
    sessionStorage.setItem('jrName', value.name); // Store the selected job role name in local storage
  };

  const getRole = async () => {
    const reqData = {
      is_deleted: 0,
      // _limit: 10,
      // _page: 1,
    };
    const res = await handleApiResponse('/api/v1/interview/jobrole_list/', reqData, 1);
    const response = CheckForInvalidToken(res, navigate)
    if (response?.data.status_code === 200) {
      props.setData(response?.data?.data?.data);
    } else {
      props.setData(['Error']);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Tab') {
      close();
    }
  };

  const handleEyeButtonClick = () => {
    if (selectedOption) {
      const jdUrl = `${process.env.REACT_APP_URL_DOC}/jds/${jd}`;
      navigate('/admin/jobRolePage', { state: { jdUrl } }); // Navigate to /job with the file URL
    }
  };

  const handleSearchInputChange = (e) => {
    setSearchInput(e.target.value);
  };

  const filteredOptions = props.data.filter((ele) =>
    ele.name.toLowerCase().includes(searchInput.toLowerCase())
  );

  const handleYesButtonClick = (e) => {
    e.preventDefault();
    setIsButtonDisabled(false);
    sessionStorage.setItem('isButtonDisabled', "false");
    setIsPopupVisible(false);
  };

  return (
    <>
      <div className="relative inline-block" onKeyDown={handleKeyDown}>
        {isPopupVisible && (
          <ModalChangeCampaign
            isOpen={isPopupVisible}
            onClose={() => setIsPopupVisible(false)}
            handleYesClick={handleYesButtonClick}
          />
        )}
        <button
          id="dropdownDefaultButton"
          data-dropdown-toggle="dropdown"
          className={`w-[55vw] mt-2 justify-between bg-[#FFFFFF] focus:outline-none font-medium rounded-lg text-sm px-[0.75rem] py-2.5 text-center inline-flex items-center ${isButtonDisabled ? 
            'pointer-event-none bg-gray-100' 
            : ''}`}
          type="button"
          onClick={handleToggleDropdown}
        >
          {props?.jobroleValue || selectedOption || props.holder}
          <svg
            className="w-2.5 h-2.5 ms-3"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 10 6"
            transform={`${props.toggle.jobRole ? 'rotate(180)' : 'rotate(0)'}`}
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="m1 1 4 4 4-4"
            />
          </svg>
        </button>
        <div
          id="dropdown"
          className={`${
            props.toggle.jobRole ? 'block' : 'hidden'
          } absolute left-0 mt-2 w-[55vw] z-50 bg-[#FFFFFF] divide-y divide-gray-100 rounded-lg shadow dark:bg-gray-700 h-[10rem] overflow-auto`}
        >
          <input
            type="text"
            placeholder="Search..."
            value={searchInput}
            onChange={handleSearchInputChange}
            className="w-full p-2 border-b border-gray-200 focus:outline-none"
          />
          <ul className="py-2 cursor-pointer" aria-labelledby="dropdownDefaultButton">
            {filteredOptions.length !== 0 ? (
              filteredOptions.map((ele) => (
                <li
                  key={ele.id}
                  className="flex justify-between px-4 py-2 hover:bg-gray-100"
                  onClick={() => handleSelectOption(ele)}
                >
                  {ele.name}
                </li>
              ))
            ) : (
              <p className="pl-2 text-white">No data found</p>
            )}
          </ul>
        </div>
        {selectedOption && (
          <button onClick={handleEyeButtonClick} className="absolute right-0 mt-2 mr-6 p-2">
            <img src={eyeButton} alt="eye button" />
          </button>
        )}
      </div>
    </>
  );
};

export const DropdownShareType = (props) => {

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleToggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleOption = (option) => {
    console.log(`Selected option: ${option.label}`);
    props.setLinkTypeValue(option.value)
    props.setLinkTypeString(option.label)
    // Add your handling logic here, e.g., update a state or call a parent function
    setIsDropdownOpen(false);
  };

  // const getCampType = ['Regular', 'Mass'];
  // const open =()=>{
  //   console.log("clicked")
  //     props.setToggle((prev) => ({
  //       ...prev,
  //       // jobRole: false,
  //       CampType: !props.toggle.CampType,
  //     }));
  //     console.log("data => ",props.toggle.jobRole)
  // }

  // const handleToggleDropdown = () => {
  //   open();
  // };
  //  const close = () => {
  //    props.setToggle((prev) => ({
  //      ...prev,
  //      jobRole: false,
  //      CampType: false,
  //    }));
  //  };

  // // const handleOption = (value) => {
  // //   props?.setCampTypeValue(value);
  // //   if (value === 'Regular'){
  // //     props.setCampType(1);
  // //   }
  // //   else{
  // //     props.setCampType(2);
  // //   }
  // //   console.log(value)
  // //   close();
  // // };


  // const handleKeyDown = (e) => {
  //   if (e.key === 'Tab') {
  //     close();
  //   }
  // };

  const options = [
    { label: 'Self', value: 1 },
    { label: 'Platform', value: 2 },
  ];
 
  return (
    <>
<div className="relative inline-block" onKeyDown={(e) => e.key === 'Escape' && setIsDropdownOpen(false)}>
      <button
        id="dropdownDefaultButton"
        data-dropdown-toggle="dropdown"
        className="w-[31vw] mt-2 justify-between bg-[#FFFFFF] focus:outline-none font-medium rounded-lg text-sm px-[0.75rem] py-2.5 text-center inline-flex items-center"
        type="button"
        onClick={handleToggleDropdown}
      >
        {props?.linkTypeString || props.holder}
        <svg
          className={`w-2.5 h-2.5 ms-3 transition-transform ${isDropdownOpen ? 'rotate-180' : 'rotate-0'}`}
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 10 6"
        >
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="m1 1 4 4 4-4"
          />
        </svg>
      </button>
      <div
        id="dropdown"
        className={`${isDropdownOpen ? 'block' : 'hidden'} absolute left-0 mt-2 w-[31vw] z-50 bg-[#FFFFFF] divide-y divide-gray-100 rounded-lg shadow dark:bg-gray-700`}
      >
        <ul className="py-2 cursor-pointer" aria-labelledby="dropdownDefaultButton">
          {options.map((option, index) => (
            <li
              key={index}
              className="flex justify-between px-4 py-2 hover:bg-gray-100"
              onClick={() => handleOption(option)}
            >
              {option.label}
            </li>
          ))}
        </ul>
      </div>
    </div>
    </>
  );
};
