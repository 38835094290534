import React, { useRef, useState, useEffect } from 'react';
import { cameraIcon, closeButton, profileImage, uploadSettings } from '../../assets/imgs/index';
import { validateName } from '../../utils/validation';
import { useDispatch } from 'react-redux';
import { InputBox } from '../pages/FillFormPage';
// import { addProfileRequest,addProfileSuccess,addProfileFailure ,fetchDataRequest,fetchDataSuccess,fetchDataFailure} from '../../Redux/Profile/actions.js';
import {
  handleFormDataApiResponse,
  handleApiResponse,
} from '../../API/services.js';
import { toast } from 'react-toastify';
import { getLocal } from '../../utils/localStorage.js';
import { CustomButtonWhite } from '../../components/buttons.js';
import { useNavigate } from 'react-router-dom';
function ProfilePage() {
  const [imageUrl, setImageUrl] = useState(false);
  const fileInputRef = useRef(null);
  const [nameError, setNameError] = useState('');
  const [departmentError, setDepartmentError] = useState('');
  const [number, setNumber] = useState('');
  const [emptyError, setEmptyError] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [email, setEmail] = useState('');
  const dispatch = useDispatch();
  const [userId, setUserId] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [organization, setOrganization] = useState('');
  const [department, setDepartment] = useState('');
  const [lastName, setLastName] = useState('');
  const [previousImageUrl, setPreviousImageUrl] = useState('');
  const [reset, setReset] = useState(false);
  const [userDetails, setUserDetials] = useState(true);
  const [editProfileChange, setEditProfileChange] = useState(false);
  const uid = sessionStorage.getItem("uid");

  const navigate = useNavigate();
  const base_url = process.env.REACT_APP_URL;
  console.log("--==--",imageUrl);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await handleApiResponse('/api/v1/get_profile/', {}, 1);
        if (response?.code === 'token_not_valid') {
          navigate('/');
          sessionStorage.clear();
          return;
        }
        console.log('API Response:', response?.data);
        if (response?.data?.status_code === 200) {
          console.log(reset);
          if (reset) {
            setFirstName('');
            setLastName('');
            setNumber('');
            setOrganization('');
            setDepartment('');
          } else {
            setFirstName(response?.data?.data?.first_name);
            setLastName(response?.data?.data?.last_name);
            setEmail(response?.data?.data?.email);
            setNumber(response?.data?.data?.mobile);
            setOrganization(response?.data?.data?.organization);
            setDepartment(response?.data?.data?.department);
          }
          console.log(response?.data?.data?.profile_img);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [dispatch, previousImageUrl, firstName]);

  console.log(department);

  const handleDepartmentChange = (e) => {
    setDepartmentError('');
    setEmptyError('');
    setDepartment(e.target.value);
  };
  const handleNumberChange = (e) => {
    setPhoneError('');
    const inputValue = e.target.value;
    const isValidNumber = /^\d{0,10}$/.test(inputValue); // Check if input consists of only digits and has a maximum length of 10

    if (isValidNumber) {
      setEmptyError('');
      setNumber(inputValue);
    }
  };

  const handleUploadClick = () => {
    setEditProfileChange(true);
    fileInputRef.current.click();
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    try {
      if (file) {
        const imageUrl = URL.createObjectURL(file);
        setImageUrl([imageUrl, file]);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setEmptyError('');
    setNameError('');
    setPhoneError('');
    setDepartmentError('');
      console.log(imageUrl);
      const file = imageUrl;
      console.log(file);
      // if (file) {
        try {
          const formData = new FormData();
          console.log(imageUrl);
          if (editProfileChange) {
            formData.append('profile_img', imageUrl[1]);
          }
          // formData.append("profile_img", imageUrl[1]);
          formData.append('department', department);
          formData.append('mobile', number);
          console.log(uid);
          formData.append('uid', uid);
          const response = await handleFormDataApiResponse(
            '/api/v1/updateuser/',
            formData,
            1
          );
          console.log('API Response:', response?.data);

          if (response?.data.status_code === 200) {
            toast.success('Updated successfully.',{
              toastStyle: {
                 borderLeft: "5px solid #4caf50", backgroundColor: "#00112D", color: "white"
              },
            });
            navigate(-1);
            sessionStorage.setItem(
              'profileImage',
              `${response?.data?.data?.profile_img.split('?')[0]}`
            );
            return 1;
          }
        } catch (error) {
          console.log(error);
        }
      // }
      //  else {
      //   toast.error('Selected format for file is not correct.');
      //   console.error('No file selected.');
      // }
  };

  // 
  const last_name = getLocal('last_name');
  const first_name = getLocal('first_name');

  return (
    <div className="h-screen text-[#00112D] p-12 gap-16 flex flex-col justify-center bg-[#E4E7EB]">
      <section className="flex justify-center  overflow-y-auto">
        <div className="z-3 flex flex-col w-[90%] h-fit justify-center mb-[1.323vw] mt-[1.323vw] ml-[2.646vw] bg-white z-5  rounded-[1.058vw] bg-gradient-to-r from-opacity-white to-white shadow-md p-8">
          <div className="flex justify-between">
            <div className="mb-[0.794vw] text-[2.19vw] font-bold sans1">
              Account Setting
            </div>
            <img
              src={closeButton}
              className="cursor-pointer"
              alt="close button"
              onClick={() => {
                navigate('/admin/dashboard');
              }}
            />
          </div>
          <div className="mb-[0.794vw] text-[1.2vw] font-normal">
            Change or edit your profile picture
          </div>
          <div className="fill-available font-medium flex justify-end gap-4">
            <button
              className={`w-[30%] border-b-2 ${
                userDetails ? 'border-[#22284E]' : 'border-white'
              }  `}
              onClick={() => {
                setUserDetials(true);
              }}
            >
              User Details
            </button>
            <button
              className={`w-[30%] border-b-2  ${
                !userDetails ? 'border-[#22284E]' : 'border-white'
              }  `}
              onClick={() => {
                setUserDetials(false);
              }}
            >
              User Password
            </button>
          </div>
          <hr className="mb-[1.058vw] mx-[-2rem] " />
          <div className="flex flex-col p-4 justify-center bg-[#F8FBFF]">
            <div className="flex gap-6 justify-between px-12 py-4">
              <section className="flex flex-col gap-4 p-4 justify-center items-center ">
                <div className=" text-[1.358vw] font-medium">
                  Your Profile Picture
                </div>
                <div
                  className=" relative w-32 h-32  rounded-full bg-[#E9F2FF] border-4  border-[#EFF5FF] shadow flex items-center  justify-center"
                  onClick={handleUploadClick}
                >
                  <input
                    type="file"
                    accept="image/*"
                    ref={fileInputRef}
                    onChange={handleImageUpload}
                    style={{ display: 'none' }}
                  />
                  <img
                    src={
                      imageUrl
                        ? imageUrl[0]
                        : sessionStorage.getItem('profileImage')
                        ? sessionStorage.getItem('profileImage')
                        : profileImage
                    }
                    alt=""
                    className="w-32 h-32 rounded-full"
                  />
                  <img src={cameraIcon} className='absolute bottom-[-10px] cursor-pointer right-[-20px]'/>
                </div>
                <div className="flex flex-col items-center justify-center">
                  <p className="font-bold text-[24px]">
                    {first_name} {last_name}
                  </p>
                  <p>Designation</p>
                </div>
              </section>
              {userDetails && (
                <section className="">
                  <div className="gap-[1.587vw] grid grid-cols-2 grid-rows-2">
                    <div>
                      <div className="mb-[0.529vw] text-[1.058vw] font-medium">
                        First Name
                      </div>
                      <input
                        type="text"
                        placeholder="Please enter the first name"
                        className="cursor-not-allowed outline-0 p-[0.661vw] input-field w-[20.423vw] h-[3.439vw] text-[#22284E] bg-[#FAFBFC] rounded-[0.529vw] border-[1px] border-solid border-[#22284E]"
                        required
                        value={firstName}
                        readOnly
                      />
                    </div>
                    <div>
                      <div className="mb-[0.529vw] text-[1.058vw] font-medium">
                        Last Name
                      </div>
                      <input
                        type="text"
                        placeholder="Please enter the first name"
                        className="cursor-not-allowed outline-0 p-[0.661vw] input-field w-[20.423vw] h-[3.439vw] text-[#22284E] bg-[#FAFBFC] rounded-[0.529vw] border-[1px] border-solid border-[#22284E]"
                        required
                        value={lastName}
                        readOnly
                      />
                    </div>
                    <div>
                      <div className="mb-[0.529vw] text-[1.058vw] font-medium">
                        Email
                      </div>
                      <input
                        type="email"
                        placeholder="abcpersona@xyz.com"
                        className="cursor-not-allowed outline-0 p-[0.661vw] input-field w-[20.423vw] h-[3.439vw] text-[#22284E] bg-[#FAFBFC] rounded-[0.529vw] border-[1px] border-solid border-[#22284E]"
                        required
                        value={email}
                        readOnly
                      />
                    </div>
                    <div>
                      <div className="mb-[0.529vw] text-[1.058vw] font-medium">
                        Mobile number
                      </div>

                      <input
                        type="tel"
                        placeholder="Please enter your phone number"
                        className="cursor-not-allowed outline-0 p-[0.661vw] input-field w-[20.423vw] h-[3.439vw] text-[#22284E] bg-[#FAFBFC] rounded-[0.529vw] border-[1px] border-solid border-[#22284E]"
                        required
                        value={number}
                        onChange={(e) => {
                          handleNumberChange(e);
                        }}
                      />
                      <div style={{ color: 'red' }}>{phoneError}</div>
                    </div>
                    <div>
                      <div className="mb-[0.529vw] text-[1.058vw] font-medium outline-0">
                        Organization Name
                      </div>
                      <input
                        type="text"
                        placeholder="Please enter the organization name outline-0"
                        className="cursor-not-allowed p-[0.661vw] input-field w-[20.423vw] h-[3.439vw] text-[#22284E] bg-[#FAFBFC] rounded-[0.529vw] border-[1px] border-solid border-[#22284E]"
                        required
                        value={organization}
                        readOnly
                      />
                    </div>
                    <div>
                      <div className="mb-[0.529vw] text-[1.058vw] outline-0 font-medium">
                        Designation
                      </div>
                      <input
                        type="text"
                        placeholder="Please enter the department"
                        className="cursor-not-allowed outline-0 p-[0.661vw] input-field w-[20.423vw] h-[3.439vw] text-[#22284E] bg-[#FAFBFC] rounded-[0.529vw] border-[1px] border-solid border-[#22284E]"
                        required
                        value={department}
                        readOnly
                      />
                      <div style={{ color: 'red' }}>{departmentError}</div>
                    </div>
                  </div>
                </section>
              )}
              {!userDetails && (
                <section className="">
                  <div>
                    <div className="mb-[0.529vw] text-[1.058vw] font-medium">
                      Current Password
                    </div>

                    <input
                      type="tel"
                      placeholder="Please enter your current password"
                      className="outline-0 p-[0.661vw] input-field w-[20.423vw] h-[3.439vw] text-[#22284E] bg-[#FAFBFC] rounded-[0.529vw] border-[1px] border-solid border-[#22284E]"
                      onChange={(e) => {
                        handleNumberChange(e);
                      }}
                    />
                    <div style={{ color: 'red' }}>{phoneError}</div>
                  </div>
                  <div className="gap-[1.587vw] mt-[1.45rem] grid grid-cols-2 grid-rows-2">
                    <div>
                      <div className="mb-[0.529vw] text-[1.058vw] font-medium outline-0">
                        New Password
                      </div>
                      <input
                        type="text"
                        placeholder="Please enter the new password"
                        className="p-[0.661vw] input-field w-[20.423vw] h-[3.439vw] text-[#22284E] bg-[#FAFBFC] rounded-[0.529vw] border-[1px] border-solid border-[#22284E]"
                      />
                    </div>
                    <div>
                      <div className="mb-[0.529vw] text-[1.058vw] outline-0 font-medium">
                        Confirm New Password
                      </div>
                      <input
                        type="text"
                        placeholder="Please enter the current password"
                        className="outline-0 p-[0.661vw] input-field w-[20.423vw] h-[3.439vw] text-[#22284E] bg-[#FAFBFC] rounded-[0.529vw] border-[1px] border-solid border-[#22284E]"
                        onChange={(e) => {
                          handleDepartmentChange(e);
                        }}
                      />
                      <div style={{ color: 'red' }}>{departmentError}</div>
                    </div>
                  </div>
                </section>
              )}
            </div>
            <div style={{ color: 'red' }}>{emptyError}</div>
            <div className="mt-12 gap-4 flex justify-center">
            <button
              className={`w-[40%] text-[18px] justify-end text-white h-[62px] rounded-lg bg-[#22284E]`}
              onClick={handleSubmit}
            >
              Save
            </button>
              {/* <CustomButtonWhite text={'Save'} onClick={handleSubmit} /> */}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default ProfilePage;
