import React, { useEffect, useState, useRef } from 'react';
import {
  arrowIcon,
  Ellipse,
  deleteIcon,
  editIcon,
  userImg,
  searchIcon,
} from '../../assets/imgs/index';
import { ViewCampaignTable } from '../../components/AdminComponents/table';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { handleApiResponse } from '../../API/services';
import { CustomButtonWhite } from '../../components/buttons';
import { toast } from 'react-toastify';
import { Pagination } from '../../features/Pagination';
import { getLocal } from '../../utils/localStorage';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import isEqual from 'lodash/isEqual';

const base_url = process.env.REACT_APP_FRONEND;

function ViewCampaigns() {
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [isLeftOption, setIsLeftOption] = useState(() => {
    const storedValue = sessionStorage.getItem('isLeftOption');
    return storedValue !== null ? JSON.parse(storedValue) : true;
  });
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPage1, setCurrentPage1] = useState(1);
  const itemsPerPage = 20;
  const location = useLocation();
  const [search, setSearch] = useState('');
  const [fileBlob, setFileBlob] = useState(null);
  // console.log(data);
  const campUid = location?.state?.uid;
  const campType = location?.state?.campaign_type;
  const jr_name = location?.state?.jr_name;
  const status = location?.state?.status;
  const start_date = location?.state?.start_date;
  const end_date = location?.state?.end_date;
  const campaign_type = location?.state?.campaign_type;
  const name = location?.state?.name;
  const [tableData, setTableData] = useState([]);
  const [candidateId, setCandidateId] = useState([]);
  const [campTypeLocal, setCampTypeLocal] = useState(
    sessionStorage.getItem("campType")
  );
  const [downloadLinksClicked, setDownloadLinksClicked] = useState(false);
  console.log(campUid);
  console.log(campType);
  const [counts, setCounts] = useState([]);

  const useDeepCompareEffect = (callback, dependencies) => {
    const currentDependenciesRef = useRef();

    if (!isEqual(currentDependenciesRef.current, dependencies)) {
      currentDependenciesRef.current = dependencies;
    }

    useEffect(callback, [currentDependenciesRef.current]);
  };

  //   const completedRows = searchResults?.length > 0
  //   ? searchResults.filter(rowData => getStatus(rowData.status) === "Completed")
  //   : currentPageData.filter(rowData => getStatus(rowData.status) === "Completed");

  // const completedCount = completedRows.length;

  // sessionStorage.setItem("campType", campType);
  const callApi = async () => {
    let reqData;

    if (campType === 'Regular') {
      reqData = {
        campaign: campUid,
        is_selected: 1,
        is_deleted: 0,
      };
    } else if (campType === 'Mass') {
      reqData = {
        campaign: campUid,
      };
    } else {
      // Handle cases where campType is neither "Regular" nor "Mass"
      console.error('Unknown campType:', campType);
      return;
    }

    try {
      const response = await handleApiResponse(
        '/api/v1/interview/candidate_list/',
        reqData,
        1
      );
      if (response?.code === 'token_not_valid') {
        navigate('/');
        sessionStorage.clear();
        return;
      }
      console.log(response?.data?.data?.data);
      const newCounts = response?.data?.data?.data?.map(
        (item) => item?.linkinfo?.count
      );

      console.log(newCounts);

      setCounts(newCounts);
      setTableData(response?.data?.data?.data);
    } catch (error) {
      console.error('API call failed:', error);
      // Handle error, e.g., set an error state
    }
  };

  // console.log(tableData);
  useEffect(() => {
    callApi();
  }, []);

  useDeepCompareEffect(() => {
    callApi();
  }, [counts]);

  // const [campTypeLocal, setCampTypeLocal] = useState(sessionStorage.getItem('campType'));
  console.log(campUid);
  console.log(campType);
  // sessionStorage.setItem("campType", campType);

  useEffect(() => {
    setCampTypeLocal(sessionStorage.getItem("campType"));
  }, []);

  const tableData1 = [];
  const [searchResults, setSearchResults] = useState([]);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const startIndex1 = (currentPage1 - 1) * itemsPerPage;
  const endIndex1 = startIndex1 + itemsPerPage;
  const [clicked, setClicked] = useState(false);
  // const currentPageData = tableData?.slice(startIndex, endIndex);
  const currentPageData = tableData
  const currentPageData1 = tableData1?.slice(startIndex1, endIndex1);
  const [redirectLink, setRedirectLink] = useState();
  const [bulkCountExists, setbulkCountExists] = useState(0);
  const [formattedQuestions, setFormattedQuestions] = useState([]);
  const [candidates, setCandidates] = useState([]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handlePageChange1 = (pages) => {
    setCurrentPage1(pages);
  };

  const openModalEditJobRole = () => {
    setIsModalOpen4(true);
  };

  const closeModalEditJobRole = () => {
    setIsModalOpen4(false);
  };
  //   const completedRows = searchResults?.length > 0
  //   ? searchResults.filter(rowData => getStatus(rowData.status) === "Completed")
  //   : currentPageData.filter(rowData => getStatus(rowData.status) === "Completed");

  // const completedCount = completedRows.length;

  // const copyToClipboard = async (link) => {
  //   try {
  //     // Try using the Clipboard API first
  //     if (navigator.clipboard) {
  //       await navigator.clipboard.writeText(link);
  //       console.log("Link copied to clipboard:", link);
  //     } else {
  //       // Fallback to document.execCommand for older browsers
  //       const textarea = document.createElement("textarea");
  //       textarea.value = link;
  //       textarea.style.position = "absolute";
  //       textarea.style.left = "-9999px";

  //       document.body.appendChild(textarea);
  //       textarea.select();

  //       try {
  //         const successful = document.execCommand("copy");
  //         if (successful) {
  //           console.log("Link copied to clipboard:", link);
  //         } else {
  //           throw new Error("Failed to copy");
  //         }
  //       } catch (error) {
  //         console.error("Error copying link to clipboard:", error);
  //         let fallbackLink = `${base_url}/interview/candidate/fallback`;
  //     alert(`An error occurred. Please use this link: ${fallbackLink}`);
  //     copyToClipboard(fallbackLink);
  //       } finally {
  //         document.body.removeChild(textarea);
  //       }
  //     }
  //   } catch (error) {
  //     console.error("Error copying link to clipboard:", error);
  //   }
  // };

  window.Clipboard = (function (window, document, navigator) {
    var textArea, copy;

    function isOS() {
      return navigator.userAgent.match(/ipad|iphone/i);
    }

    function createTextArea(text) {
      textArea = document.createElement('textArea');
      textArea.value = text;
      document.body.appendChild(textArea);
    }

    function selectText() {
      var range, selection;

      if (isOS()) {
        range = document.createRange();
        range.selectNodeContents(textArea);
        selection = window.getSelection();
        selection.removeAllRanges();
        selection.addRange(range);
        textArea.setSelectionRange(0, 999999);
      } else {
        textArea.select();
      }
    }

    function copyToClipboard() {
      document.execCommand('copy');
      document.body.removeChild(textArea);
    }

    copy = function (text) {
      createTextArea(text);
      selectText();
      copyToClipboard();
    };

    return {
      copy: copy,
    };
  })(window, document, navigator);

  const copyToClipboard = async (link) => {
    try {
      if (navigator.clipboard) {
        await navigator.clipboard.writeText(link);
        console.log('Link copied to clipboard:', link);
        toast.success('link copied',{
          toastStyle: {
             borderLeft: "5px solid #4caf50", backgroundColor: "#00112D", color: "white"
          },
        });
      } else {
        // Fallback for older browsers and Safari on iOS
        window.Clipboard.copy(link);
      }
    } catch (error) {
      console.error('Error copying link to clipboard:', error);
      alert(`Please use this link: ${link}`);
    }
  };

  const handleBulkCount = async () => {
    let data = { campaign_uid: campUid };
    const res = await handleApiResponse(
      '/api/v1/interview/get_bulk_link/',
      data,
      1
    );
    if (res?.code === 'token_not_valid') {
      navigate('/');
      sessionStorage.clear();
      return;
    }
    if (res?.status) {
      setbulkCountExists(res?.data?.data?.count);
    } else {
      setbulkCountExists(0);
    }
  };

  const getQuestions = async () => {
    const reqData = {
      campaign: campUid,
    };
    const response = await handleApiResponse(
      '/api/v1/interview/question/get_question/',
      reqData,
      1
    );
    if (response?.code === 'token_not_valid') {
      navigate('/');
      sessionStorage.clear();
      return;
    }
    if (response?.data?.status_code === 200) {
      console.log(response?.data?.data?.quest);
      setFormattedQuestions(response.data.data);
      // const questions = response.data.data.quest;

      // questions.forEach((item, index) => {
      //   console.log(`Question${index + 1}: ${item.question}`);
      //   console.log(`Answer${index + 1}: ${item.answer}`);
      // });

      // const formattedQuestions = questions.map((item, index) => ({
      //   question: `Question${index + 1}: ${item.question}`,
      //   answer: `Answer${index + 1}: ${item.answer}`,
      // }));

      // return formattedQuestions;
      // setIsUpdate(response?.data?.data?.uid);
      // console.log(response?.data?.data);
      // console.log(response?.data?.data?.quest_type);
      // setSelectedOption(`option${response?.data?.data?.quest_type}`);

      // const prevQuestion = response?.data?.data?.quest;
      // if (response?.data?.data?.quest_type === 1) {
      //   setQuestions1(prevQuestion);
      // } else if (response?.data?.data?.quest_type === 2) {
      //   const updatedQuestions = prevQuestion.map((item, index) => ({
      //     id: index + 1,
      //     name: `Question ${index + 1}`,
      //     question: item.question,
      //     answer: item.answer,
      //     QError: "",
      //     AError: "",
      //   }));
      //   setQuestions(updatedQuestions);
      // } else if (response?.data?.data?.quest_type === 3) {
      //   const updatedQuestions = prevQuestion.map((item, index) => ({
      //     id: index + 1,
      //     name: `Question ${index + 1}`,
      //     question: item.question,
      //     answer: item.answer,
      //     QError: "",
      //     AError: "",
      //     isHr: item?.isHr,
      //   }));
      //   setQuestions3(updatedQuestions);
      // }
    }
  };

  useEffect(() => {
    getQuestions();
  }, []);

  useEffect(() => {
    handleBulkCount();
  }, []);

  const handleBulkLink = async () => {
    try {
      let data = { campaign_uid: campUid };
      console.log(data);
      const response = await handleApiResponse(
        '/api/v1/interview/generate_bulk_link/',
        data,
        1
      );
      if (response?.code === 'token_not_valid') {
        navigate('/');
        sessionStorage.clear();
        return;
      }
      if (response?.data?.status_code === 200) {
        let link =
          `${base_url}/interview/candidate/` + response?.data?.data?.link;
        // navigator.clipboard.writeText(link)
        copyToClipboard(link);
        sessionStorage.setItem("linkType", "1");
        handleBulkCount();
        // setRedirectLink(link);
        setRedirectLink(link);
        // setCount((prev) => prev + 1);
      } else {
        toast.error(response?.data?.msg,{
          toastStyle: {
            borderLeft: "5px solid #f44336",
          },
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getStatus = (isPerformanceGenerated) => {
    console.log(isPerformanceGenerated);
    if (isPerformanceGenerated === 1) {
      return 'Pending';
    } else if (isPerformanceGenerated >= 3) {
      return 'Completed';
    }
  };

  const handleCopyLink = async () => {
    try {
      let data = { campaign_uid: campUid };
      console.log(data);
      const response = await handleApiResponse(
        '/api/v1/interview/get_bulk_link/',
        data,
        1
      );
      if (response?.code === 'token_not_valid') {
        navigate('/');
        sessionStorage.clear();
        return;
      }
      console.log(response?.data);
      console.log(response?.data?.data?.link);
      let link =
        `${base_url}/interview/candidate/` + response?.data?.data?.link;
      copyToClipboard(link);
      setRedirectLink(link);
    } catch (e) {
      console.log(e);
      // let fallbackLink = `${base_url}/interview/candidate/fallback`;
      // alert(`An error occurred. Please use this link: ${fallbackLink}`);
      // copyToClipboard(fallbackLink);
    }
  };

  const sendMail = async () => {
    const reqData = {
      campaign_uid: campUid,
    };
    const response = await handleApiResponse(
      '/api/v1/interview/generate_unique_link/',
      reqData,
      1
    );
    if (response?.code === 'token_not_valid') {
      navigate('/');
      sessionStorage.clear();
      return;
    }
    console.log(response?.data);
    if (response?.data?.status_code === 200) {
      setClicked(true);
      toast.success('Email has been shared to the candidates.',{
        toastStyle: {
           borderLeft: "5px solid #4caf50", backgroundColor: "#00112D", color: "white"
        },
      });
    }
  };

  const handleSearch = async () => {
    const reqData = {
      campaign: campUid,
      q: search,
    };
    const response = await handleApiResponse(
      '/api/v1/interview/candidate_list/',
      reqData,
      1
    );
    if (response?.code === 'token_not_valid') {
      navigate('/');
      sessionStorage.clear();
      return;
    }
    console.log(response.data.data.data);
    setSearchResults(response.data.data.data);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' || e.keyCode === 13) {
      handleSearch();
    }
  };

  const completedRows =
    searchResults?.length > 0
      ? searchResults.filter(
          (rowData) => getStatus(rowData.status) === 'Completed'
        )
      : currentPageData.filter(
          (rowData) => getStatus(rowData.status) === 'Completed'
        );

  const completedCount = completedRows.length;

  const downloadLinks = async () => {
    setDownloadLinksClicked(true);
    const reqData = {
      campaign: campUid,
      is_selected: 1,
    };
    const response = await handleApiResponse(
      '/api/v1/interview/candidate_list/',
      reqData,
      1
    );
    if (response?.code === 'token_not_valid') {
      navigate('/');
      sessionStorage.clear();
      return;
    }
    const candidateData = response?.data?.data?.data;
    console.log('candidateData', candidateData);
    if (candidateData && Array.isArray(candidateData)) {
      // Filter candidates where candidate?.linkinfo?.count is 0
      const filteredCandidates = candidateData.filter(
        (candidate) => candidate?.linkinfo?.count === 0
      );

      const linkInfoArray = filteredCandidates.map(
        (candidate) => candidate?.linkinfo?.link
      );
      console.log(linkInfoArray, 'linkinfo');

      if (linkInfoArray.some((link) => !link)) {
        // If any linkinfo is empty, perform the alternate request
        const newReqData = {
          campaign_uid: campUid,
        };

        const newResponse = await handleApiResponse(
          '/api/v1/interview/generate_unique_link/',
          newReqData,
          1
        );
        if (response?.code === 'token_not_valid') {
          navigate('/');
          sessionStorage.clear();
          return;
        }

        if (newResponse?.data?.status_code === 200) {
          const dataArray = newResponse?.data?.data;
          console.log(dataArray, 'dataArray');

          const formattedData = dataArray.map((item) => ({
            first_name: item.candidate.first_name,
            last_name: item.candidate.last_name,
            emailid: item.candidate.email,
            links:
              `${process.env.REACT_APP_FRONEND}/interview/candidate/` +
              item.link,
          }));

          const worksheet = XLSX.utils.json_to_sheet(formattedData);

          // Create a new workbook
          const workbook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(workbook, worksheet, 'Links');

          // Generate a buffer
          const excelBuffer = XLSX.write(workbook, {
            bookType: 'xlsx',
            type: 'array',
          });

          // Create a blob from the buffer
          const blob = new Blob([excelBuffer], {
            type: 'application/octet-stream',
          });

          // Save the file and store the blob in state
          setFileBlob(blob);
          saveAs(blob, 'links.xlsx');
        }
      } else {
        const formattedData = candidateData.map((item) => ({
          first_name: item.first_name,
          last_name: item.last_name,
          emailid: item.email,
          links: item.linkinfo
            ? `${process.env.REACT_APP_FRONEND}/interview/candidate/` +
              item.linkinfo.link
            : '',
        }));

        const worksheet = XLSX.utils.json_to_sheet(formattedData);

        // Create a new workbook
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Links');

        // Generate a buffer
        const excelBuffer = XLSX.write(workbook, {
          bookType: 'xlsx',
          type: 'array',
        });

        // Create a blob from the buffer
        const blob = new Blob([excelBuffer], {
          type: 'application/octet-stream',
        });

        // Save the file and store the blob in state
        setFileBlob(blob);
        saveAs(blob, 'links.xlsx');
      }
    }
    // if (fileBlob) {
    //   // If the fileBlob exists, download the existing file
    //   saveAs(fileBlob, 'links.xlsx');
    //   return;
    // }
    // const reqData = {
    //   campaign_uid: campUid,
    // };

    // const response = await handleApiResponse(
    //   "/api/v1/interview/generate_unique_link/",
    //   reqData,
    //   1
    // );

    // if (response?.data?.status_code === 200) {
    //   const dataArray = response?.data?.data;
    //   console.log(dataArray,'dataarray')
    //   const formattedData = dataArray.map((item) => ({
    //     first_name: item.candidate.first_name,
    //     last_name: item.candidate.last_name,
    //     emailid: item.candidate.email,
    //     links: `${process.env.REACT_APP_FRONEND}/interview/candidate/` + item.link,
    //   }));
    //   const worksheet = XLSX.utils.json_to_sheet(formattedData);

    //   // Create a new workbook
    //   const workbook = XLSX.utils.book_new();
    //   XLSX.utils.book_append_sheet(workbook, worksheet, "Links");

    //   // Generate a buffer
    //   const excelBuffer = XLSX.write(workbook, {
    //     bookType: "xlsx",
    //     type: "array",
    //   });

    //   // Create a blob from the buffer
    //   const blob = new Blob([excelBuffer], {
    //     type: "application/octet-stream",
    //   });

    //   // Save the file and store the blob in state
    //   setFileBlob(blob);
    //   saveAs(blob, "links.xlsx");
    //   // if()
    // }
  };

  return (
    <div className="text-[#A6AEBA] ml-[1.5rem] mr-[3.5rem] gap-4 flex flex-col">
      <div className="flex justify-between">
        <div className="flex mb-[1rem] items-center">
          <img
            src={arrowIcon}
            alt="arrow icon"
            onClick={() => {
              sessionStorage.removeItem("campType");
              sessionStorage.removeItem('isLeftOption')
              // sessionStorage.removeItem("linkType");
              navigate("/admin/campaign");
            }}
            className="cursor-pointer"
          />
          <div className="font-semibold text-[1.25vw]">Campaign Detail</div>
        </div>
        <div>
          {campType === 'Mass' &&
            (bulkCountExists > 0 || bulkCountExists !== undefined ? (
              <div className="flex gap-4">
                <CustomButtonWhite text="Copy Link" onClick={handleCopyLink} />
                <CustomButtonWhite
                  text="Re-Generate Link"
                  onClick={handleBulkLink}
                />
              </div>
            ) : (
              <CustomButtonWhite
                text="Generate Link"
                onClick={handleBulkLink}
              />
            ))}
          {/* {(campType === "Regular" && getLocal("mail_share_type") !== "1") && (
            <div className="flex gap-4 mr-20">
              <CustomButtonWhite text="Share Link" onClick={sendMail} />
            </div>
          )} */}

          {campType === 'Regular' && getLocal('mail_share_type') === '1' && (
            <div className="flex gap-4 mr-20">
              <CustomButtonWhite
                text="Download Links"
                onClick={downloadLinks}
              />
            </div>
          )}
        </div>
      </div>
      <div className="flex gap-2">
        <div className="flex flex-col gap-2">
          <div className="rounded-md h-[14.403vw] border-2 w-[31.806vw] flex flex-col items-center justify-center p-2">
            <p className="text-[1.528vw] font-semibold tracking-wider mt-2">
              {name}
            </p>
            <p className="mt-2 w-[5.333vw] h-[2.731vw] bg-[#A6AEBA1A] flex items-center justify-center rounded-[1.111vw] font-semibold text-[0.972vw]">
              {status}
            </p>
            <div className="grid grid-cols-2 gap-4 mt-4">
              <div>
                <div className="flex gap-2">
                  <img src={Ellipse} alt="ellipse" />
                  <p className="text-[0.903vw] tracking-[0.6px]">Job Role</p>
                </div>
                <p className="text-[0.972vw] tracking-[0.6px] font-medium ml-3">
                  {jr_name}
                </p>
              </div>
              <div>
                <div className="flex gap-2">
                  <img src={Ellipse} alt="ellipse" />
                  <p className="text-[0.903vw] tracking-[0.6px]">
                    Campaign Type
                  </p>
                </div>
                <p className="text-[0.972vw] tracking-[0.6px] font-medium ml-3">
                  {campaign_type}
                </p>
              </div>
              <div>
                <div className="flex gap-2">
                  <img src={Ellipse} alt="ellipse" />
                  <p className="text-[0.903vw] tracking-[0.6px]">Start Date</p>
                </div>
                <p className="text-[0.972vw] tracking-[0.6px] font-medium ml-3">
                  {start_date}
                </p>
              </div>
              <div>
                <div className="flex gap-2">
                  <img src={Ellipse} alt="ellipse" />
                  <p className="text-[0.903vw] tracking-[0.6px]">End Date</p>
                </div>
                <p className="text-[0.972vw] tracking-[0.6px] font-medium ml-3">
                  {end_date}
                </p>
              </div>
            </div>
          </div>
          <div className="overflow-y-scroll rounded-md h-[20.403vw] border-2 w-[31.806vw] flex flex-col p-2">
            <p className="flex items-center justify-center font-bold mb-2">
              Questions
            </p>
            {formattedQuestions?.quest?.map((item, index) => (
              <div key={index}>
                <p
                  className={`text-[1vw] mb-2 ${
                    (item?.mandatory && !item?.isHr && item?.isEdit) ||
                    (item?.mandatory && !item?.isHr)
                      ? 'text-blue-600'
                      : item?.mandatory &&
                        item?.isHr &&
                        formattedQuestions?.quest_type === 2
                      ? 'text-blue-400'
                      : item?.mandatory && item?.isHr
                      ? 'text-green-600'
                      : ''
                  } `}
                >
                  <b>
                    {formattedQuestions?.quest_type === 3 && item.mandatory
                      ? 'HR ' + item.name
                      : formattedQuestions?.quest_type === 2
                      ? 'HR Question '
                      : 'AI Question '}
                    {formattedQuestions?.quest_type === 3 &&
                    item.mandatory &&
                    item.isEdit
                      ? ' (AI question Modified)'
                      : formattedQuestions?.quest_type === 3 &&
                        item.mandatory &&
                        !item.isHr
                      ? '(AI Mandated)'
                      : item.mandatory && item.isHr
                      ? formattedQuestions?.quest_type === 2
                        ? index + 1 + ' (Hr Mandated)'
                        : ' (Hr Mandated)'
                      : formattedQuestions?.quest_type === 1 && item.mandatory
                      ? index + 1 + ' (AI Mandated)'
                      : index + 1}
                  </b>
                  : {item.question}
                </p>
              </div>
            ))}
          </div>
        </div>
        <span className="w-[61%] h-auto bg-[#FFFFFF] rounded-lg border-[0.05rem] ">
          <div className="flex gap-8 p-4 px-8 border-b-[0.09rem]">
            <span className="relative">
              <p
                className="cursor-pointer flex gap-1"
                onClick={() => {
                  setIsLeftOption(true);
                  sessionStorage.setItem("isLeftOption", true);
                }}
              >
                All Candidate{' '}
                <span className="w-[2.333vw] h-[1.731vw] bg-[#A6AEBA1A] flex items-center justify-center rounded-[1.111vw] text-[0.833vw]">
                  {
                    tableData.filter(
                      (rowData) => getStatus(rowData.status) !== 'Completed'
                    ).length
                  }
                </span>
              </p>
              <div
                className={`w-[10rem] left-[-2rem] bottom-[-1rem] border-[0.08rem] border-text-color-code1 absolute ${
                  isLeftOption ? 'block' : 'hidden'
                }`}
              ></div>
            </span>
            <span className="relative">
              <p
                className="cursor-pointer flex gap-1"
                onClick={() => {
                  setIsLeftOption(false);
                  sessionStorage.setItem("isLeftOption", false);
                }}
              >
                Completed Candidate{' '}
                <span className="w-[2.333vw] h-[1.731vw] bg-[#A6AEBA1A] flex items-center justify-center rounded-[1.111vw] text-[0.833vw]">
                  {completedCount}
                </span>
              </p>
              <div
                className={`w-[12rem] left-[-1rem] bottom-[-1rem] border-[0.08rem] border-text-color-code1 absolute ${
                  isLeftOption ? 'hidden' : 'block'
                }`}
              ></div>
            </span>
          </div>
          <div className="flex p-4">
            <input
              placeholder="Search candidate"
              onChange={(e) => setSearch(e.target.value)}
              className="w-full h-10 p-5 outline-0 border-[#F1F2F4] border-2 bg-[#FFFFFF] rounded-lg mt-2 placeholder-text-sm"
              value={search}
              onKeyDown={handleKeyDown}
            />
            <img
              src={searchIcon}
              alt="search icon"
              className="cursor-pointer right-8 relative"
            />
          </div>
          <div className="overflow-x-scroll">
            <table
              className={`w-full text-sm text-[#A6AEBA] text-left rtl:text-right h-[50vh] ${
                isLeftOption ? 'block' : 'hidden'
              }`}
            >
              <thead class="w-[100%] bg-[#F9FAFB] text-[0.833vw] font-semibold text-[#A6AEBA] border-b h-[2px] border-[#E4E7EB]">
                <tr>
                  <th scope="col" class="w-[2%] px-6 py-3">
                    Name
                  </th>
                  <th scope="col" class="px-6 py-3">
                    Email
                  </th>
                  <th scope="col" class="px-6 py-3">
                    Company
                  </th>
                  <th scope="col" class="px-6 py-3">
                    Mobile number
                  </th>
                  <th scope="col" class="px-6 py-3">
                    Candidate Unique ID
                  </th>
                  <th scope="col" class="px-6 py-3">
                    Interview ID
                  </th>
                  <th scope="col" class="px-6 py-3">
                    Status
                  </th>
                  <th scope="col" class="px-6 py-3">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody className="overflow-x-scroll">
                {searchResults?.length > 0
                  ? searchResults
                      ?.filter(
                        (rowData) => getStatus(rowData.status) !== 'Completed'
                      )
                      .map((rowData, index) => (
                        <ViewCampaignTable
                          counts={counts}
                          key={index}
                          jr_name={rowData?.campign_info?.jr_name}
                          icon={userImg}
                          name={`${rowData.first_name} ${rowData.last_name}`}
                          row12={rowData.email}
                          candVideo={rowData.video}
                          row13={rowData.mobile}
                          taskId={rowData.task_id}
                          clicked={clicked}
                          candId={rowData.id}
                          row17={getStatus(rowData.status)}
                          editIcon={editIcon}
                          deleteIcon={deleteIcon}
                          openModal={openModalEditJobRole}
                          closeModal={closeModalEditJobRole}
                          isModalOpen4={isModalOpen4}
                          setCandidateId={setCandidateId}
                          candidateId={candidateId}
                          uid={rowData.uid}
                          bulkCountExists={rowData?.linkinfo?.count}
                          callApi={callApi}
                          campUid={campUid}
                          campType={campType}
                          candPhoto={rowData.photo}
                          candIntId={rowData.linkinfo.id}
                          linkInfo={rowData.linkinfo}
                          downloadLinksClicked={downloadLinksClicked}
                          exp={rowData.work_experience}
                        />
                      ))
                  : currentPageData
                      ?.sort((a, b) => {
                        return a.id - b.id;
                      })
                      ?.filter(
                        (rowData) => getStatus(rowData.status) !== 'Completed'
                      )
                      .map((rowData, index) => (
                        <ViewCampaignTable
                          counts={counts}
                          clicked={clicked}
                          key={index}
                          icon={userImg}
                          jr_name={rowData?.campign_info?.jr_name}
                          name={`${rowData.first_name} ${rowData.last_name}`}
                          row12={rowData.email}
                          candId={rowData.id}
                          candVideo={rowData.video}
                          row13={rowData.mobile}
                          row17={getStatus(rowData.status)}
                          candIntId={rowData.linkinfo.id}
                          editIcon={editIcon}
                          taskId={rowData.task_id}
                          deleteIcon={deleteIcon}
                          openModal={openModalEditJobRole}
                          closeModal={closeModalEditJobRole}
                          isModalOpen4={isModalOpen4}
                          setCandidateId={setCandidateId}
                          candidateId={candidateId}
                          uid={rowData.uid}
                          campUid={campUid}
                          bulkCountExists={rowData?.linkinfo?.count}
                          callApi={callApi}
                          campType={campType}
                          candPhoto={rowData.photo}
                          candCompany={rowData.company}
                          candJobRole={rowData.campign_info.jr_name}
                          linkInfo={rowData.linkinfo}
                          downloadLinksClicked={downloadLinksClicked}
                          exp={rowData.work_experience}
                        />
                      ))}
              </tbody>
            </table>
            {/* <Pagination
              className="pagination"
              currentPage={currentPage}
              totalCount={tableData?.length}
              pageSize={itemsPerPage}
              onPageChange={handlePageChange}
            /> */}
          </div>
          <div className="overflow-x-scroll">
            <table
              className={`w-full text-sm text-[#A6AEBA] text-left rtl:text-right h-[50vh] ${
                isLeftOption ? 'hidden' : 'block'
              }`}
            >
              <thead class="w-[100%] bg-[#F9FAFB] text-[0.833vw] font-semibold text-[#A6AEBA] border-b h-[2px] border-[#E4E7EB]">
                <tr>
                  <th scope="col" class="w-[2%] px-6 py-3">
                    Name
                  </th>
                  <th scope="col" class="px-6 py-3">
                    Email
                  </th>
                  <th scope="col" class="px-6 py-3">
                    Company
                  </th>
                  <th scope="col" class="px-6 py-3">
                    Mobile number
                  </th>
                  <th scope="col" class="px-6 py-3">
                    Candidate Unique ID
                  </th>
                  <th scope="col" class="px-6 py-3">
                    Interview ID
                  </th>
                  <th scope="col" class="px-6 py-3">
                    Status
                  </th>
                  <th scope="col" class="px-6 py-3">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody className="overflow-x-scroll">
                {searchResults?.length > 0
                  ? searchResults
                      ?.filter(
                        (rowData) => getStatus(rowData.status) === 'Completed'
                      )
                      .map((rowData, index) => (
                        <ViewCampaignTable
                          counts={counts}
                          key={index}
                          jr_name={rowData?.campign_info?.jr_name}
                          icon={userImg}
                          name={`${rowData.first_name} ${rowData.last_name}`}
                          row12={rowData.email}
                          candVideo={rowData.video}
                          row13={rowData.mobile}
                          taskId={rowData.task_id}
                          clicked={clicked}
                          candId={rowData.id}
                          row17={getStatus(rowData.status)}
                          editIcon={editIcon}
                          deleteIcon={deleteIcon}
                          openModal={openModalEditJobRole}
                          closeModal={closeModalEditJobRole}
                          isModalOpen4={isModalOpen4}
                          setCandidateId={setCandidateId}
                          candidateId={candidateId}
                          uid={rowData.uid}
                          bulkCountExists={rowData?.linkinfo?.count}
                          callApi={callApi}
                          campUid={campUid}
                          campType={campType}
                          candPhoto={rowData.photo}
                          candIntId={rowData.linkinfo.id}
                          linkInfo={rowData.linkinfo}
                          downloadLinksClicked={downloadLinksClicked}
                          exp={rowData?.work_experience}
                        />
                      ))
                  : currentPageData
                      ?.filter(
                        (rowData) => getStatus(rowData.status) === 'Completed'
                      )
                      .map((rowData, index) => (
                        <ViewCampaignTable
                          counts={counts}
                          clicked={clicked}
                          key={index}
                          icon={userImg}
                          jr_name={rowData?.campign_info?.jr_name}
                          name={`${rowData.first_name} ${rowData.last_name}`}
                          row12={rowData.email}
                          candId={rowData.id}
                          candVideo={rowData.video}
                          row13={rowData.mobile}
                          row17={getStatus(rowData.status)}
                          candIntId={rowData.linkinfo.id}
                          editIcon={editIcon}
                          taskId={rowData.task_id}
                          deleteIcon={deleteIcon}
                          openModal={openModalEditJobRole}
                          closeModal={closeModalEditJobRole}
                          isModalOpen4={isModalOpen4}
                          setCandidateId={setCandidateId}
                          candidateId={candidateId}
                          uid={rowData.uid}
                          campUid={campUid}
                          bulkCountExists={rowData?.linkinfo?.count}
                          callApi={callApi}
                          campType={campType}
                          candPhoto={rowData.photo}
                          candCompany={rowData.company}
                          candJobRole={rowData.campign_info.jr_name}
                          linkInfo={rowData.linkinfo}
                          downloadLinksClicked={downloadLinksClicked}
                          exp={rowData?.work_experience}
                        />
                      ))}
              </tbody>
            </table>
            {/* <Pagination
              className="pagination"
              currentPage={currentPage}
              totalCount={tableData?.length}
              pageSize={itemsPerPage}
              onPageChange={handlePageChange}
            /> */}
          </div>
          {/* <Pagination
            className="pagination"
            currentPage={currentPage}
            totalCount={tableData?.length}
            pageSize={itemsPerPage}
            onPageChange={handlePageChange}
          />
          <div class={`${isLeftOption ? "hidden" : "block"}`}>
            <table
              class={`w-full text-sm text-[#A6AEBA] text-left rtl:text-right ${
                isLeftOption ? "hidden" : "block"
              }`}
            >
              <thead class="w-[100%] bg-[#F9FAFB] text-[0.833vw] font-semibold text-[#A6AEBA] border-b h-[2px] border-[#E4E7EB]">
                <tr>
                  <th scope="col" class="w-[2%] px-6 py-3">
                    Name
                  </th>
                  <th scope="col" class="px-6 py-3">
                    Email
                  </th>
                  <th scope="col" class="px-6 py-3">
                    Mobile number
                  </th>
                  <th scope="col" class="px-6 py-3">
                    Status
                  </th>
                  <th scope="col" class="px-6 py-3">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {currentPageData1?.map((rowData, index) => (
                  <ViewCampaignTable
                    key={index}
                    icon={userImg}
                    name={"Shubham Uniyal "}
                    row12={"abc123@gmail.com"}
                    row13={"+91 9717119299"}
                    row17={"Ongoing"}
                    editIcon={editIcon}
                    deleteIcon={deleteIcon}
                    openModal={openModalEditJobRole}
                    closeModal={closeModalEditJobRole}
                    isModalOpen4={isModalOpen4}
                  />
                ))}
              </tbody>
            </table>

            <Pagination
              className="pagination"
              currentPage={currentPage}
              totalCount={tableData?.length}
              pageSize={itemsPerPage}
              onPageChange={handlePageChange}
            />
          </div> */}
        </span>
      </div>
    </div>
  );
}

export default ViewCampaigns;
