import React, { useEffect, useState } from 'react';
import { AnalyticsPageBtn } from '../../components/buttons';
import { Table } from '../../components/AdminComponents/table';
import {
  arrowleft,
  arrowright,
  deleteIcon,
  editIcon,
  searchIcon,
  userImg,
} from '../../assets/imgs/index';
import {
  Modal,
  Modal1,
  Modal2,
  ModalAddingBulkMembers,
  ModalImportMembers,
} from '../../components/AdminComponents/modal';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
//import axios from "axios";
import { handleApiResponse } from '../../API/services';
import { toast } from 'react-toastify';
import VideoPlayer from '../../utils/VideoPlayer';
import { useNavigate } from 'react-router-dom';

function Members() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [noPermission, setNoPermission] = useState();
  const [excelData, setExcelData] = useState([]);

  useEffect(() => {
    setNoPermission(sessionStorage.getItem('notPermited'));
  }, []);

  //const base_url = 'http://65.0.139.208:8000';
  const [openImport, setOpenImport] = useState(false);
  const itemsPerPage = 6;
  const [pageNo, setPageNo] = useState(1);
  const [tableData, setTableData] = useState([]);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [editData, setEditData] = useState('');
  const [openProcess, setOpenProcess] = useState(false);
  const navigate = useNavigate();
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const [searchTerm, setSearchTerm] = useState(""); // Add searchTerm state
  const filteredData = tableData.filter(row =>
    `${row.first_name} ${row.last_name}`.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const getUser = async (userLimit, pageNo) => {
    const reqData = {
      limit: userLimit,
      page: pageNo,
      is_deleted: 0,
    };
    const response = await handleApiResponse('/api/v1/userlist/', reqData, 1);
    if (response?.code === 'token_not_valid') {
      navigate('/');
      sessionStorage.clear();
      return;
    }
    console.log(response);
    if (response.data.status_code === 200) {
      //console.log("user displayed", Object.values(response?.data?.data).flat())
      let dataToFilter = Object.values(response?.data?.data)?.flat()?.reverse();
      dataToFilter = dataToFilter.filter((ele) => {
        console.log(ele.user_type);
        if (ele.user_type !== 2) {
          return ele;
        }
      });
      console.log(dataToFilter);
      setTableData(dataToFilter);
    }
  };

  useEffect(() => {
    getUser(itemsPerPage, pageNo);
  }, [
    pageNo,
    isModalOpen1,
    openDeleteConfirmation,
    openEdit,
    openImport,
    openProcess,
  ]);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const [uuid, setUuid] = useState('');
  const setuuid = (uuid) => {
    setUuid(uuid);
  };
  const checkUid = () => {
    if (uuid.length) {
      setOpenDeleteConfirmation(true);
      console.log('open');
    }
  };
  useEffect(() => {
    checkUid();
    console.log(uuid);
  }, [uuid]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const openModal1 = () => {
    setIsModalOpen1(true);
  };
  const closeModalAdd = () => {
    setIsModalOpen1(false);
  };
  const closeModal1 = () => {
    setOpenEdit(false);
  };

  const closeModal2 = () => {
    if (openDeleteConfirmation) {
      setOpenDeleteConfirmation(false);
      console.log('close');
    }
  };
  useEffect(() => {
    console.log('no permission ', noPermission);
  }, [noPermission]);

  const openImportPopup = () => {
    setOpenImport(true);
  };

  const closeImportMember = () => {
    setOpenImport(false);
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value); // Update searchTerm on input change
  };

  const openProcessing = () => {
    if (excelData.length - 1 === 0) {
      closeProcessing();
      toast.warn('Empty file uploaded.');
    } else {
      setOpenProcess(true);
    }
  };
  const closeProcessing = () => {
    setOpenProcess(false);
    setExcelData([]);
  };

  const generateExcelFile = () => {
    const requiredHeaders = [
      'First Name',
      'Last Name',
      'Email',
      'Designation',
      'Department',
    ];

    // Create a new workbook and worksheet
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet([]);

    // Append the headers
    XLSX.utils.sheet_add_aoa(ws, [requiredHeaders], { origin: 'A1' });

    // Append the worksheet to the workbook
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    // Generate Excel file and trigger download
    const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([wbout], { type: 'application/octet-stream' });
    saveAs(blob, 'members_template.xlsx');
  };

  // Handler for the "Previous" button
  const goToPreviousPage = () => {
    if (currentPage > 1) {
      const newPage = currentPage - 1;
      handlePageChange(newPage);
      getUser(itemsPerPage, newPage); // Pass the new page number to getUser
    }
  };
  console.log(tableData)
  const totalPages = Math.ceil(tableData.length / itemsPerPage);
  // Handler for the "Next" button
  const goToNextPage = () => {
    if (currentPage < totalPages) {
      const newPage = currentPage + 1;
      handlePageChange(newPage);
      getUser(itemsPerPage, newPage); // Pass the new page number to getUser
    }
  };

  return (
    <div className="font-satoshi rounded-[30px] bg-[#FFFFFF] p-[1rem] gap-8 flex flex-col">
      {openDeleteConfirmation && (
        <Modal2 uid={uuid} onClose={closeModal2} setUuid={setUuid} />
      )}

      {openEdit ? <Modal editData={editData} onClose={closeModal1} /> : null}

      {openImport && (
        <ModalImportMembers
          onClose={closeImportMember}
          onOpen={openProcessing}
          excelData={excelData}
          setExcelData={setExcelData}
        />
      )}
      {openProcess && (
        <ModalAddingBulkMembers
          onClose={closeProcessing}
          excelData={excelData}
          closeModal={closeImportMember}
        />
      )}

      <div className="flex justify-between" onClick={closeModal2}>
        <div className="text-[24px] text-[#222850]">
          <i>Member List</i>
        </div>
        {noPermission === 'false' && (
          <div className="flex gap-2">
            <div className="relative">
              <input
                placeholder="Search"
                onChange={handleSearch}
                className="w-[261px] h-[36px] text-[12px] p-3 pl-8 outline-0  bg-[#E9F3FF] rounded-lg  placeholder-text-sm"
                // value={search}
                // onKeyDown={handleKeyDown}
              />
              <img
                src={searchIcon}
                alt="search icon"
                className="absolute cursor-pointer top-[-0.4rem] left-0 w-[16px] h-[48.2px] ml-2"
              />
            </div>
            {/*
            <AnalyticsPageBtn
              name={'Download'}
              img={4}
              onClick={generateExcelFile}
            />
            <AnalyticsPageBtn
              name={'Import Member'}
              img={4}
              onClick={openImportPopup}
            />
            <AnalyticsPageBtn
              name={'Add Member'}
              img={5}
              onClick={openModal1}
            />
            <Modal1 isOpen={isModalOpen1} onClose={closeModalAdd} /> */}
          </div>
        )}
      </div>

      <div>
        <div class="relative overflow-x-auto">
          <table class="w-full text-sm text-[#A6AEBA] text-left rtl:text-right">
            <thead class="text-[15px] h-[54px] text-[#222850] bg-[#F7FAFC] border-b border-[#E4E7EB]">
              <tr>
                <th scope="col" class="px-6 py-3">
                  S.No
                </th>
                <th scope="col" class="px-6 py-3">
                  Full Name
                </th>
                <th scope="col" class="px-6 py-3">
                  Email
                </th>
                <th scope="col" class="px-6 py-3">
                  Designation
                </th>
                <th scope="col" class="px-6 py-3">
                  Department
                </th>

                <th scope="col" class="px-6 py-3">
                  Member Status
                </th>

                {noPermission === 'false' && (
                  <th scope="col" class="px-6 py-3">
                    Action
                  </th>
                )}
              </tr>
            </thead>
            <tbody>
              {currentItems?.map((rowData, idx) => (
                <Table
                  noPermission={noPermission}
                  sno={idx}
                  all={rowData}
                  name={`${rowData['first_name']} ${rowData['last_name']}`}
                  row12={rowData['email']}
                  row13={rowData['designation']}
                  row14={rowData['department']}
                  row15={rowData['status'] === 1 ? 'Active' : 'Inactive'}
                  editIcon={editIcon}
                  deleteIcon={deleteIcon}
                  openModal={openModal}
                  closeModal={closeModal}
                  isModalOpen={isModalOpen}
                  campaigns={rowData['created_campaigns']}
                  uid={rowData['uid']}
                  setuuid={setuuid}
                  number={startIndex + idx + 1}
                  isModalOpen2={isModalOpen2}
                  setIsModalOpen2={setIsModalOpen2}
                  setOpenDeleteConfirmation={setOpenDeleteConfirmation}
                  setEditData={setEditData}
                  setOpenEdit={setOpenEdit}
                  style={{
                    backgroundColor: idx % 2 === 0 ? '#FFFFFF' : '#F9FBFD',
                  }}
                />
              ))}
            </tbody>
          </table>
          <div className="pagination flex justify-between mt-4">
            <div
              className="flex text-[#091E42] text-[14px] border-[1px] border-[#D0D5DD] h-fit rounded-[3rem] p-2 w-[110px] justify-center gap-2 cursor-pointer"
              onClick={goToPreviousPage}
            >
              <img
                src={arrowleft}
                alt="arrow left"
                className="w-[20px] h-[20px]"
              />
              Previous
            </div>
            <div>
              {tableData.length > 0 &&
                itemsPerPage > 0 &&
                [...Array(Math.ceil(totalPages)).keys()].map((page) => {
                  const pageNumber = page + 1;
                  const isCurrentPage = currentPage === pageNumber;
                  const isFirstPage = pageNumber === 1;
                  const isLastPage = pageNumber === Math.ceil(totalPages);
                  const isWithinLeftRange =
                    pageNumber >= currentPage - 3 && pageNumber < currentPage;
                  const isWithinRightRange =
                    pageNumber <= currentPage + 3 && pageNumber > currentPage;

                  if (
                    isFirstPage ||
                    isLastPage ||
                    isCurrentPage ||
                    isWithinLeftRange ||
                    isWithinRightRange
                  ) {
                    return (
                      <button
                        key={pageNumber}
                        onClick={() => {
                          handlePageChange(pageNumber);
                          getUser(itemsPerPage, pageNumber); // Pass the page number to getUser
                        }}
                        className={`px-3 py-2 mx-1 rounded-md text-[14px] ${
                          isCurrentPage
                            ? ' border border-[#197FF5] bg-[#E9F3FF] text-[#091E42]'
                            : ' text-[#091E42]'
                        }`}
                      >
                        {pageNumber}
                      </button>
                    );
                  } else if (
                    (pageNumber === currentPage - 4 && pageNumber !== 1) ||
                    (pageNumber === currentPage + 4 &&
                      pageNumber !== Math.ceil(totalPages))
                  ) {
                    return (
                      <span
                        key={pageNumber}
                        className="text-[#091E42] px-3 py-2 mx-1"
                      >
                        ...
                      </span>
                    );
                  } else {
                    return null;
                  }
                })}
            </div>
            <div
              className="flex text-[#667085] text-[14px] border-[1px] border-[#D0D5DD] h-fit rounded-[3rem] p-2 w-[110px] justify-center gap-2 cursor-pointer"
              onClick={goToNextPage}
            >
              Next
              <img
                src={arrowright}
                className="w-[20px] h-[20px]"
                alt="arrow right"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Members;
