import { useSearch } from 'rsuite/esm/internals/Picker';
import { bg2, glassEffect, interviewBg } from '../assets/imgs/index';
import { NavBar } from '../components/NavBar';
import { SideBar } from '../components/SideBar';
import { Outlet } from 'react-router-dom';
import { useEffect, useState } from 'react';

export const Layout = () => {
  const [fill, setFill] = useState(null)

  useEffect(()=>{
    setFill(sessionStorage.getItem('fillForm'))
  },[sessionStorage.getItem('fillForm')])
  
  return (
    <header
      className="flex relative h-contain  bg-cover bg-top bg-interview-bg-color"
      style={{ backgroundImage: ` ${fill?`url(${bg2})`:`url(${interviewBg})`}` }}
    >
      <div className="h-screen fill-available flex justify-center items-center ">
        <Outlet />
      </div>
    </header>
  );
};

export const SubLayout1 = () => {
  return (
    <header className="flex w-[100vw] h-[100vh] ">
      <img
        className="absolute h-full w-full left-0 top-0 z-2 "
        src={glassEffect}
        alt="bg-img"
      />
      <div className="flex flex-col w-full justify-center items-center bg-fill absolute z-10  ">
        <Outlet />
      </div>
    </header>
  );
};
