import { InputBox, InputBoxEmail } from '../pages/FillFormPage';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import React, { useState, useEffect } from 'react';
import { handleApiResponse } from '../../API/services';
import { setLocal } from '../../utils/localStorage.js';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import openEye from '../../assets/imgs/openeye.svg';
import closedEye from '../../assets/imgs/closedEye.svg';
import OtpGenerator from '../../components/AdminComponents/OtpGenerator.js';
import {
  signupRequest,
  signupSuccess,
  signupFailure,
} from '../../redux/signup/actions.js';

import { toast } from 'react-toastify';
import {
  Dropdown,
  DropdownShareType,
} from '../../components/AdminComponents/dropdown.js';
import backgroundImage from '../../assets/imgs/Background.png';
import robot from '../../assets/imgs/robot.png';
import welcome from '../../assets/imgs/welcome.png';
import rectangleSignin from '../../assets/imgs/signinRectangle.png';
import borderSignIn from '../../assets/imgs/bordersignin.png';
import { NextButton, NextButton1 } from '../../components/buttons.js';
import { clevercruit, correct, signinbg, signinvideo, wrong } from '../../assets/imgs/index.js';

export const HrSignup = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);
  const initialvalues = {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    confirmPassword: '',
    organization: '',
    department: '',
    otp: '',
  };
  const [formvalues, setFormValues] = useState(initialvalues);
  const [showEmailVerification, setShowEmailVerification] = useState(false);
  const [organizationError, setOrganizationError] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [existError, setExistError] = useState(false);
  const [otpError, setOtpError] = useState(false);
  const [mobile, setMobile] = useState('');
  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState({});
  const [toggle, setToggle] = useState({
    jobRole: false,
    CampType: false,
  });
  const [isExist, setIsExist] = useState(false);
  const [showOtpInput, setShowOtpInput] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [linkTypeValue, setLinkTypeValue] = useState();
  const [linkTypeString, setLinkTypeString] = useState();
  const nameRegex = /^[A-Za-z][A-Za-z\s]{2,199}$/;
  const emailRegex = /^[^\s@]+([^\s@\.](\.[^\s@\.])?)*@[^\s@]+\.[^\s@]+$/;
  const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{8,}$/;
  const [otp, setOtp] = useState(null);
  const [verified, setVerified] = useState(false);
  const [error, setError] = useState(false);
  const [error1, setError1] = useState(false);
  const [isBubbleVisible, setBubbleVisible] = useState(false);

  useEffect(() => {
    checkExist();
  })

  const handleChange = (event) => {
    setVerified(false);
    setOtpError(false);
    const { name, value } = event.target;
    if (value.trim() !== '') {
      setFormValues({ ...formvalues, [name]: value });
    } else {
      setFormValues({ ...formvalues, [name]: '' });
    }
    if (name === 'firstName') {
      validateName(value);
    } else if (name === 'lastName') {
      validateLastName(value);
    } else if (name === 'email') {
      validateEmail(value);
    }
    // else if(name ==="otp"){
    //   validateOtp(value);
    // }
    if (name === 'password') {
      validatePassword(value);
    } else if (name === 'confirmPassword') {
      validateConfirmPassword(value);
    }
  };

  console.log(errors);
  console.log(formvalues.email);

  const handleBlur = (event) => {
    const { name, value } = event.target;
    setTouched({ ...touched, [name]: true });
    if (name === 'email' && value.trim() === '') {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: 'Email is required.',
      }));
    } else if (name === "password" && value.trim() === "") {
      validatePassword();
    } else if (name === "confirmPassword" && value.trim() === "") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        confirmPassword: 'Confirm password is required.',
      }));
    }
    // else if (name === "mobile" && value.trim() === "") {
    //   setErrors((prevErrors) => ({
    //     ...prevErrors,
    //     mobile: "Mobile number is required.",
    //   }));
    // }
  };

  const validateName = (firstName) => {
    setErrors((prev) => ({ ...prev, firstName: '' }));
    return true;
  };

  const validateLastName = (lastName) => {
    setErrors((prev) => ({ ...prev, lastName: '' }));
    return true;
  };
  console.log(isExist);

  const checkExist = async () => {
    console.log('validating value');
    console.log(formvalues.email);
    const reqData = { email: formvalues.email };
    console.log(reqData)
    try {
      const response = await handleApiResponse("/api/v1/auth/get_userexistance/", reqData, 0);
      console.log(response);
      if (response.status_code === 200) {
        console.log(response.data.is_exist)
        if (response.data.is_exist === true) {
          setExistError(true);
        }
        else if (response.data.is_exist === false) {
          setExistError(false);
        }
        else {
          console.log('response not ok');
        }
      }
      else {
        console.log('response not 200');
      }
    } catch (e) {
      console.log(e)
    }
  }
  const conditions = [
    { isValid: formvalues.password.length >= 8, text: '8 Characters' },
    { isValid: /[A-Z]/.test(formvalues.password), text: '1 Uppercase Letter' },
    { isValid: /[a-z]/.test(formvalues.password), text: '1 Lowercase Letter' },
    { isValid: /\d/.test(formvalues.password), text: '1 Number' },
    { isValid: /[!@#$%^&*]/.test(formvalues.password), text: '1 Special Character' }
  ];

  const validateEmail = (email) => {
    if (!emailRegex.test(email)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: 'Invalid email address',
      }));
      return false;
    }

    else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: '',
      }));
      return true;
    }
  };

  const validatePassword = (password) => {
    let errorMessage = [];

    // Check for minimum length
    if (password?.length < 8) {
      errorMessage.push("Password must be at least 8 characters long.");
    }

    // Check for uppercase letter
    if (!/[A-Z]/.test(password)) {
      errorMessage.push("At least one uppercase letter.");
    }

    // Check for lowercase letter
    if (!/[a-z]/.test(password)) {
      errorMessage.push("At least one lowercase letter.");
    }

    // Check for number
    if (!/\d/.test(password)) {
      errorMessage.push("At least one number.");
    }

    // Check for special character
    if (!/[!@#$%^&*(),.?":{}|<>]/.test(password)) {
      errorMessage.push("At least one special character.");
    }

    // Set errors if there are any
    setErrors((prevError) => ({
      ...prevError,
      password: errorMessage.length > 0 ? errorMessage.join("\n") : "",
    }));

    // Return true if there are no errors
    return errorMessage.length === 0;
  };


  const validateConfirmPassword = (confirmpassword) => {
    if (confirmpassword !== formvalues.password) {
      setErrors((prevError) => ({
        ...prevError,
        confirmpassword: "Confirm password didn't match !",
      }));
    } else {
      setErrors((prevError) => ({ ...prevError, confirmpassword: '' }));
      return true;
    }
  };

  const handleMobileChange = (e) => {
    const { name, value } = e.target;

    // Check if the value starts with a digit between 6 and 9 and has a maximum length of 10
    if (/^[6-9]\d{0,9}$/.test(value) || value === '') {
      setMobile(value);
    }

    // Perform validation if the input name is "mobile" and there's at least one digit
    if (name === 'mobile' && value.length >= 1) {
      mobileValidate(value);
    } else if (name === "mobile" && value.length < 1) {
      setErrors((prevError) => ({ ...prevError, mobile: "" }));
      return true;
    }
  };

  const mobileValidate = (mobile) => {
    if (mobile.length <= 9) {
      setErrors((prevError) => ({
        ...prevError,
        mobile: 'Enter 10 digits number !',
      }));
      return false;
    } else {
      setErrors((prevError) => ({ ...prevError, mobile: '' }));
      return true;
    }
  };
  console.log(formvalues.otp);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const togglePasswordVisibility1 = () => {
    setShowPassword1(!showPassword1);
  };

  const handleKeyDown = (e) => {
    const key = e.key;
    if (key.match(/[^\w.@-]/)) {
      e.preventDefault();
    }
  };

  const handleKeyDownOTP = (e) => {
    if (
      e.key === 'e' ||
      e.key === 'E' ||
      e.key === '+' ||
      e.key === '-' ||
      (e.target.value.length >= 6 &&
        e.key !== 'Backspace' &&
        e.key !== 'Delete')
    ) {
      e.preventDefault();
    }
  };

  const handleKeyDown3 = (e) => {
    const key = e.key;
    const disallowedChars = ['.', ',', ':', ';', "'", '"', '(', ')', ' '];
    if (disallowedChars.includes(key)) {
      e.preventDefault();
    }
  };

  const handleKeyDown2 = (e) => {
    const key = e.key;
    const isAlphabetic = key.match(/[a-zA-Z]/);
    const isSpace = key === ' ';
    if (!isAlphabetic && !isSpace) {
      e.preventDefault();
    }
  };

  const handleEmailVerification = async () => {
    setError(false);
    setError1(false);
    setEmailError(false);
    setOtpError(false);
    const {
      firstName,
      lastName,
      email,
      password,
      confirmPassword,
      organization,
      department,
      otp,
    } = formvalues;
    if (!validateName(firstName)) {
      return;
    }
    if (!validateLastName(lastName)) {
      return;
    }
    if (!validateEmail(email)) {
      return;
    }
    if (!validatePassword(password)) {
      return;
    }
    if (!validateConfirmPassword(confirmPassword)) {
      return;
    }
    if (!mobileValidate) {
      return;
    }
    if (
      firstName === '' ||
      // lastName === '' ||
      email === '' ||
      password === '' ||
      confirmPassword === '' ||
      department === '' ||
      organization === ''
    ) {
      toast.warning('Please fill all the mandatory fields.',{
        toastStyle: {
          borderLeft: "5px solid #ffeb3b",
        },
      });
    } else {
      dispatch(
        signupRequest({
          first_name: firstName,
          last_name: lastName,
          organization: organization,
          email: email,
          password: password,
          cpassword: confirmPassword,
          mobile: mobile,
          department: department,
          mail_share_type: linkTypeValue,
        })
      );
      try {
        const response = await handleApiResponse('/api/v1/auth/register/', {
          first_name: firstName,
          last_name: lastName,
          organization: organization,
          email: email,
          password: password,
          cpassword: confirmPassword,
          mobile: mobile,
          department: department,
          mail_share_type: linkTypeValue,
        });
        if (response?.code === 'token_not_valid') {
          navigate('/');
          sessionStorage.clear();
          return;
        }
        console.log('API Response:', response, response.data);
        if (response.status_code === 200) {
          dispatch(signupSuccess(response.data));
          setLocal('adminToken', response.data.access);
          setLocal('first_name', response.data.first_name);
          setLocal('last_name', response.data.last_name);
          setLocal('uid', response.data.uid);
          setLocal('parentUid', response.data.parent);
          setLocal('companyId', response?.data?.id);
          setLocal('mail_share_type', response?.data?.mail_share_type);
          sessionStorage.setItem('organization', response.data.organization);
          const tkn = response?.data?.access;
          console.log(tkn);
          sessionStorage.setItem('accesTKN', tkn);
          console.log('organisation', response.data.organization);
          setLocal(
            'notPermited',
            response?.data?.user_type === 3 ? true : false
          );
          navigate('/admin/dashboard');
          // if (!response?.data.is_email_verified) {
          //   setShowEmailVerification(true);
          // }
        } else if (
          response.status_code === 400 &&
          response?.msg === 'OTP is required!'
        ) {
          setEmailError(true);
        } else if (
          response.status_code === 400 &&
          response?.msg === 'User already exists'
        ) {
          setError(true);
        } else if (
          response.status_code === 400 &&
          response?.msg === 'Incorrect otp'
        ) {
          setOtpError(true);
        } else {
          if (response.status_code === 400) {
            setError1(true);
          }
        }
      } catch (error) {
        dispatch(signupFailure(error.message));
        console.error('API Error:', error);
      }
    }
  };
  console.log(!formvalues.email === '');
  console.log(formvalues.email);

  const handleVerifyClick = () => {
    // setShowOtpInput(true);
  };

  const generateOtp = async () => {
    setEmailError(false);
    setShowEmailVerification(true);
    try {
      const response = await handleApiResponse('/api/v1/generateotp/', {
        email: formvalues.email,
        otp_type: 1
      });
      if (response?.code === 'token_not_valid') {
        navigate('/');
        sessionStorage.clear();
        return;
      }
      console.log('API Response:', response);
      if (response.status_code === 200) {
        toast.success('OTP has been sent to your registered email.',{
          toastStyle: {
             borderLeft: "5px solid #4caf50", backgroundColor: "#00112D", color: "white"
          },
        });
        // setOtp(response?.data.otp);
        //   console.log("GOINGGGGG");
        //   setShowEmailVerification(true);
        // }
        // dispatch(signupSuccess(response.data));
        // console.log(response.data.access);
        // setLocal('authToken', response?.data?.access);
        // toast.success('Signup successful.');
        // navigate('/', { state: { email: response?.data?.username } });
      } else if (response.status_code === 400) {
        toast.error('There was an error sending mail to your email.',{
          toastStyle: {
            borderLeft: "5px solid #f44336",
          },
        });
        // setPasswordError('User already exists.');
      }
    } catch (error) {
      // dispatch(signupFailure(error.message));
      console.error('API Error:', error);
    }
  };

  return (
    <div
      className="flex flex-col p-2 pl-4 h-[100vh] "
      style={{
        backgroundColor: '#000D26'
      }}
    >
      {showEmailVerification ? (
        <div className="z-50 fixed top-0 left-0 w-full h-full flex items-center justify-center backdrop-blur">
          <OtpGenerator
            email={formvalues.email}
            setShowEmailVerification={setShowEmailVerification}
            setVerified={setVerified}
          />
        </div>
      ) : null}
      <div className='text-white '><img src={clevercruit} alt="clevercruit logo" /></div>
      <div className='flex'>
        <div
          className="mt-2 items-center font-satoshi flex flex-col w-[51%] " >
          <p className="text-white text-[29px] font-medium mb-2">
            Sign Up
          </p>
          {/* <p className="text-white text-[16px] line-[38px] tracking-2 mb-[10px]">
          Hello Let’s get started
        </p> */}
          {/* <div className=""> */}
          <div className="flex gap-2 max-w-[403px]">
            <div>
              <div className="font-normal mb-[1.11vh]">
                <InputBox
                  type={'text'}
                  head={'First Name'}
                  name={'firstName'}
                  holder={'Enter first name'}
                  onChange={handleChange}
                  value={formvalues.firstName}
                  handleBlur={handleBlur}
                  required
                />
              </div>
              <div className="relative">
                {errors.firstName && (
                  <div
                    style={{
                      color: 'red',
                      position: 'absolute',
                      top: '100%', // Position it just below the input
                      left: '0',
                      marginBottom: '0', // Ensure no space is taken
                      whiteSpace: 'pre-line',
                      zIndex: 10, // Increase z-index for visibility
                      backgroundColor: 'white', // Add white background
                      padding: '5px', // Optional: Add padding for better readability
                      borderRadius: '4px',
                      textAlign: 'left'
                    }}
                  >
                    {errors.firstName}
                  </div>
                )}
              </div>
            </div>
            <div>
              <div className="font-normal mb-[10px]">
                <InputBox
                  type={'text'}
                  head={'Last Name'}
                  name={'lastName'}
                  holder={'Enter last name'}
                  onChange={handleChange}
                  value={formvalues.lastName}
                  handleBlur={handleBlur}
                  required
                />
              </div>
              <div className="relative">
                {errors.lastName && (
                  <div
                    style={{
                      color: 'red',
                      position: 'absolute',
                      top: '100%', // Position it just below the input
                      left: '0',
                      marginBottom: '0', // Ensure no space is taken
                      whiteSpace: 'pre-line',
                      zIndex: 10, // Increase z-index for visibility
                      backgroundColor: 'white', // Add white background
                      padding: '5px', // Optional: Add padding for better readability
                      borderRadius: '4px',
                      textAlign: 'left'
                    }}
                  >
                    {errors.lastName}
                  </div>
                )}
              </div>
              {/* {errors.lastName && (
                <div style={{ color: "red", marginBottom: "10px" }}>
                  {errors.lastName}
                </div>
              )} */}
            </div>
          </div>
          <div className="relative font-normal w-[410px] mb-[10px] flex flex-col">
            <InputBoxEmail
              type={'text'}
              head={'Email'}
              name={'email'}
              holder={'Enter email'}
              onChange={handleChange}
              value={formvalues.email}
              onKeyDown={handleKeyDown}
              handleBlur={handleBlur}
              verified={verified}
              generateOtp={generateOtp}
              errors={errors}
              formvalues={formvalues}
              existError={existError}
            />
            {/* <div className="absolute">
              {existError && (
                <div
                  style={{
                    color: 'red',
                    // position: 'absolute',
                    top: '100%', // Position it just below the input
                    left: '0',
                    marginBottom: '0', // Ensure no space is taken
                    whiteSpace: 'pre-line',
                    zIndex: 10, // Increase z-index for visibility
                    backgroundColor: 'white', // Add white background
                    padding: '5px', // Optional: Add padding for better readability
                    borderRadius: '4px',
                    textAlign: 'left'
                  }}
                  className='w-max'
                >
                  User Already Exists.
                </div>
              )}
            </div> */}
            <div className="absolute">
              {emailError && (
                <div
                  style={{
                    color: 'red',
                    top: '100%', // Position it just below the input
                    left: '0',
                    marginBottom: '0', // Ensure no space is taken
                    whiteSpace: 'pre-line',
                    zIndex: 10, // Increase z-index for visibility
                    backgroundColor: 'white', // Add white background
                    padding: '5px', // Optional: Add padding for better readability
                    borderRadius: '4px',
                    textAlign: 'left'
                  }}
                  className='w-max'
                >
                  Please verify your email.
                </div>
              )}
            </div>
            <div className='absolute top-[100%] left-0 z-30'>
              {(touched.email && errors.email && (
                <div
                  style={{
                    color: 'red',
                    top: '100%', // Position it just below the input
                    left: '0',
                    marginBottom: '0', // Ensure no space is taken
                    whiteSpace: 'pre-line',
                    zIndex: 10, // Increase z-index for visibility
                    backgroundColor: 'white', // Add white background
                    padding: '5px', // Optional: Add padding for better readability
                    borderRadius: '4px',
                    textAlign: 'left'
                  }}
                  className='w-max'
                >
                  {errors.email}
                </div>
              )) ||
                (errors.email && (
                  <div
                    style={{
                      color: 'red',
                      top: '100%', // Position it just below the input
                      left: '0',
                      marginBottom: '0', // Ensure no space is taken
                      whiteSpace: 'pre-line',
                      zIndex: 10, // Increase z-index for visibility
                      backgroundColor: 'white', // Add white background
                      padding: '5px', // Optional: Add padding for better readability
                      borderRadius: '4px',
                      textAlign: 'left',
                    }}
                    className='w-max'
                  >
                    {errors.email}
                  </div>
                ))}
            </div>
            {/* {emailError && (
              <div style={{ color: "red", marginBottom: "10px", marginTop: "10px" }}>
                Please verify your email.
              </div>
            )} */}
            {/* {(!errors.email && formvalues.email !== "") && (
            <p
              className={`flex justify-end mt-2 text-[0.9em] cursor-pointer ${verified ? 'text-green-500' : ''}`}
              onClick={!verified ? generateOtp : undefined} // Disable click if already verified
              style={{ width: 'fit-content', alignSelf: 'flex-end', display: 'flex' }}
            >
              {verified ? 'Verified' : 'Verify'}
            </p>
          )} */}
          </div>
          {/* <div className='absolute top-[100%] left-0'>
            {(touched.email && errors.email && (
              <div
                style={{
                  color: 'red',
                  top: '100%', // Position it just below the input
                  left: '0',
                  marginBottom: '0', // Ensure no space is taken
                  whiteSpace: 'pre-line',
                  zIndex: 10, // Increase z-index for visibility
                  backgroundColor: 'white', // Add white background
                  padding: '5px', // Optional: Add padding for better readability
                  borderRadius: '4px',
                  textAlign: 'left',
                  zIndex: 4
                }}
                className='w-max'
              >
                {errors.email}
              </div>
            )) ||
              (errors.email && (
                <div
                  style={{
                    color: 'red',
                    top: '100%', // Position it just below the input
                    left: '0',
                    marginBottom: '0', // Ensure no space is taken
                    whiteSpace: 'pre-line',
                    zIndex: 10, // Increase z-index for visibility
                    backgroundColor: 'white', // Add white background
                    padding: '5px', // Optional: Add padding for better readability
                    borderRadius: '4px',
                    textAlign: 'left',
                    zIndex: 4
                  }}
                  className='w-max'
                >
                  {errors.email}
                </div>
              ))}
          </div> */}

          {/* {(showOtpInput && formvalues.email!=="") && (
        <div className="font-normal mb-[10px]">
          <InputBox
            type={"number"}
            head={"Verify OTP"}
            name={"otp"}
            holder={"Enter OTP"}
            onChange={handleChange}
            value={formvalues.otp}
            onKeyDown={(e) => handleKeyDownOTP(e)}
            handleBlur={handleBlur}
          />
           {otpError && (
          <div style={{ color: "red", marginBottom: "10px", marginTop: "10px" }}>
            Incorrect OTP.
          </div>
        )}
        {formvalues.otp==="" && (
          <div style={{ color: "red", marginBottom: "10px", marginTop: "10px" }}>
            OTP is required.
          </div>
        )}
        </div>
      )} */}
          <div className="w-[403px] flex font-normal mb-[10px] relative items-center">
            <span className="flex flex-col items-center w-screen">
              <span className="flex flex-col fill-available">
                <div className="flex">
                  <label
                    className="text-white text-[13px] mb-[8px]"
                    htmlFor={'password'.toLowerCase().replace(/\s+/g, '')}
                  >
                    Password
                  </label>
                  {'password' !== 'mobile' && <span className="text-red-500">*</span>}
                </div>
                <div className="w-full h-[42px] relative p-[1px] rounded-[15px] border-2 bg-white border-white">
                  <input
                    type={
                      'password' === 'password'
                        ? showPassword
                          ? 'text'
                          : 'password'
                        : 'password' === 'confirmPassword'
                          ? showPassword1
                            ? 'text'
                            : 'password'
                          : 'password'
                    }
                    id={'password'.toLowerCase().replace(/\s+/g, '')}
                    name={'password'}
                    placeholder={'Create password'}
                    onChange={handleChange}
                    value={formvalues.password}
                    onKeyDown={handleKeyDown3}
                    onBlur={(e) => {
                      handleBlur(e);
                      setBubbleVisible(false); // Hide bubble on blur
                    }}
                    className=" w-[85%] h-[37px] p-3.5 rounded-[15px] bg-[#000D26] text-white placeholder:text-white placeholder:opacity-50 focus:outline-none"
                    onFocus={() => {
                      setBubbleVisible(true);
                      validatePassword(formvalues.password)
                    }}
                  />
                  {isBubbleVisible && (
                    <div className="absolute top-0 z-[100] right-[-210px] w-[200px] p-2 rounded-md bg-white border border-gray-300 shadow-lg">
                      <p className="text-black-600 text-sm font-medium">
                        Password must contain at least:
                      </p>
                      <ul className="text-left text-gray-700 text-xs">
                        {conditions.map((condition, index) => (
                          <li key={index} className={`flex gap-2 ${condition.isValid ? 'text-green-600' : 'text-red-600'}`}>
                            <img src={condition.isValid ? correct : wrong} alt={condition.isValid ? 'correct' : 'wrong'} />
                            {condition.text}
                          </li>
                        ))}</ul>
                    </div>
                  )}

                  {/* <div className='absolute z-10 w-[330px]'>
                    {(touched.password && errors.password && (
                      <div
                        style={{
                          color: 'red',
                          // position: 'absolute',
                          top: '100%', // Position it just below the input
                          left: '0',
                          marginBottom: '0', // Ensure no space is taken
                          whiteSpace: 'pre-line',
                          // zIndex: 10, // Increase z-index for visibility
                          backgroundColor: 'white', // Add white background
                          padding: '5px', // Optional: Add padding for better readability
                          borderRadius: '4px', // Optional: Add border-radius for better styling
                          textAlign: 'left'
                        }}
                      >
                        {errors.password}
                      </div>
                    )) ||
                      (errors.password && (
                        <div
                          style={{
                            color: 'red',
                            position: 'absolute',
                            top: '100%', // Position it just below the input
                            left: '0',
                            marginBottom: '0', // Ensure no space is taken
                            whiteSpace: 'pre-line',
                            zIndex: 10, // Increase z-index for visibility
                            backgroundColor: 'white', // Add white background
                            padding: '5px', // Optional: Add padding for better readability
                            borderRadius: '4px', // Optional: Add border-radius for better styling
                            textAlign: 'left'
                          }}
                        >
                          {errors.password}
                        </div>
                      ))}
                  </div> */}

                </div>
              </span>
              {/* <InputBox
                type={'password'}
                head={'Password'}
                name={'password'}
                holder={'Create password'}
                onChange={handleChange}
                value={formvalues.password}
                onKeyDown={handleKeyDown3}
                showPassword={showPassword}
                handleBlur={handleBlur}
                onFocus={() => validatePassword(formvalues.password)}
              /> */}
            </span>
            <button
              onClick={togglePasswordVisibility}
              className="mr-[10px] mt-[15px] absolute inset-y-0 right-0 h-full flex items-center px-2"
            >
              {showPassword ? (
                <img src={openEye} alt="view password" />
              ) : (
                <img src={closedEye} alt="hide password" />
              )}
            </button>
          </div>

          <div className="w-[403px] flex font-normal relative mb-[10px]">
            <span className="flex flex-col w-screen">
              <span className="flex flex-col fill-available">
                <div className="flex">
                  <label
                    className="text-white text-[13px] mb-[8px]"
                    htmlFor={'confirmPassword'.toLowerCase().replace(/\s+/g, '')}
                  >
                    Confirm Password
                  </label>
                  {'confirmPassword' !== 'mobile' && <span className="text-red-500">*</span>}
                </div>
                <div className="w-full relative h-[42px] max-w-md p-[1px] rounded-[15px] border-2 bg-white">
                  <input
                    type={
                      'confirmPassword' === 'password'
                        ? showPassword
                          ? 'text'
                          : 'password'
                        : 'confirmPassword' === 'confirmPassword'
                          ? showPassword1
                            ? 'text'
                            : 'password'
                          : 'password'
                    }
                    id={'confirmPassword'.toLowerCase().replace(/\s+/g, '')}
                    name={'confirmPassword'}
                    placeholder={'Re-enter password'}
                    onChange={handleChange}
                    value={formvalues.confirmPassword}
                    onKeyDown={handleKeyDown3}
                    onBlur={handleBlur}
                    className="h-[37px] w-[85%] p-3.5 rounded-[15px] bg-[#000D26] text-white placeholder:text-white placeholder:opacity-50 focus:outline-none"
                  />

                  <div className='absolute'>
                    {(touched.confirmpassword && errors.confirmpassword && (
                      <div
                        style={{
                          color: 'red',
                          position: 'absolute',
                          top: '100%', // Position it just below the input
                          left: '0',
                          marginBottom: '0', // Ensure no space is taken
                          whiteSpace: 'pre-line',
                          zIndex: 10, // Increase z-index for visibility
                          backgroundColor: 'white', // Add white background
                          padding: '5px', // Optional: Add padding for better readability
                          borderRadius: '4px',
                          textAlign: 'left'
                        }}
                        className='w-max'
                      >
                        {errors.confirmpassword}
                      </div>
                    )) ||
                      (errors.confirmpassword && (
                        <div
                          style={{
                            color: 'red',
                            position: 'absolute',
                            top: '100%', // Position it just below the input
                            left: '0',
                            marginBottom: '0', // Ensure no space is taken
                            whiteSpace: 'pre-line',
                            zIndex: 10, // Increase z-index for visibility
                            backgroundColor: 'white', // Add white background
                            padding: '5px', // Optional: Add padding for better readability
                            borderRadius: '4px',
                            textAlign: 'left'
                          }}
                          className='w-max'
                        >
                          {errors.confirmpassword}
                        </div>
                      ))}
                  </div>

                </div>
              </span>
              {/* <InputBox
                type={'password'}
                head={'Confirm Password'}
                name={'confirmPassword'}
                holder={'Re-enter password'}
                onChange={handleChange}
                value={formvalues.confirmPassword}
                showPassword1={showPassword1}
                onKeyDown={handleKeyDown3}
                handleBlur={handleBlur}
              /> */}
            </span>
            <button
              onClick={togglePasswordVisibility1}
              className="mr-[10px] mt-[15px] absolute inset-y-0 right-0 h-full flex items-center px-2"
            >
              {showPassword1 ? (
                <img src={openEye} alt="view password" />
              ) : (
                <img src={closedEye} alt="hide password" />
              )}
            </button>
          </div>
          {/* <div>
            {(touched.confirmpassword && errors.confirmpassword && (
              <div
                style={{
                  color: 'red',
                  position: 'absolute',
                  top: '100%', // Position it just below the input
                  left: '0',
                  marginBottom: '0', // Ensure no space is taken
                  whiteSpace: 'pre-line',
                  zIndex: 10, // Increase z-index for visibility
                  backgroundColor: 'white', // Add white background
                  padding: '5px', // Optional: Add padding for better readability
                  borderRadius: '4px',
                  textAlign: 'left'
                }}
                className='w-max'
              >
                {errors.confirmpassword}
              </div>
            )) ||
              (errors.confirmpassword && (
                <div
                  style={{
                    color: 'red',
                    position: 'absolute',
                    top: '100%', // Position it just below the input
                    left: '0',
                    marginBottom: '0', // Ensure no space is taken
                    whiteSpace: 'pre-line',
                    zIndex: 10, // Increase z-index for visibility
                    backgroundColor: 'white', // Add white background
                    padding: '5px', // Optional: Add padding for better readability
                    borderRadius: '4px',
                    textAlign: 'left'
                  }}
                  className='w-max'
                >
                  {errors.confirmpassword}
                </div>
              ))}
          </div> */}
          <div className="relative font-normal w-[403px] mb-[10px]">
            <InputBox
              head={"Mobile Number"}
              name={"mobile"}
              holder={"Enter mobile number"}
              value={mobile}
              onChange={(e) => handleMobileChange(e)}
              handleBlur={handleBlur}
            />
            <div className='absolute'>
              {(touched.mobile && errors.mobile && (
                <div style={{
                  color: 'red',
                  position: 'absolute',
                  top: '100%', // Position it just below the input
                  left: '0',
                  marginBottom: '0', // Ensure no space is taken
                  whiteSpace: 'pre-line',
                  zIndex: 10, // Increase z-index for visibility
                  backgroundColor: 'white', // Add white background
                  padding: '5px', // Optional: Add padding for better readability
                  borderRadius: '4px',
                  textAlign: 'left'
                }}
                  className='w-max'>
                  {errors.mobile}
                </div>
              )) ||
                (errors.mobile && (
                  <div style={{
                    color: 'red',
                    position: 'absolute',
                    top: '100%', // Position it just below the input
                    left: '0',
                    marginBottom: '0', // Ensure no space is taken
                    whiteSpace: 'pre-line',
                    zIndex: 10, // Increase z-index for visibility
                    backgroundColor: 'white', // Add white background
                    padding: '5px', // Optional: Add padding for better readability
                    borderRadius: '4px',
                    textAlign: 'left'
                  }}
                    className='w-max'>
                    {errors.mobile}
                  </div>
                ))}
            </div>
          </div>
          {/* {(touched.mobile && errors.mobile && (
          <div style={{ color: "red", marginBottom: "10px", textAlign: 'left' }}>
            {errors.mobile}
          </div>
        )) ||
          (errors.mobile && (
            <div style={{ color: "red", marginBottom: "10px", textAlign: 'left' }}>
              {errors.mobile}
            </div>
          ))} */}
          {/* <div className="font-normal mb-[10px]">
          <label className="mt-4" htmlFor="">
            Link Share Type<span className="text-red-500">*</span>
          </label>
          <span>
            <DropdownShareType
              holder="Select link share type"
              linkTypeValue={linkTypeValue}
              setLinkTypeValue={setLinkTypeValue}
              setLinkTypeString={setLinkTypeString}
              linkTypeString={linkTypeString}
              // setCampType={setCampType}
              toggle={toggle}
              setToggle={setToggle}
            />
          </span>
        </div> */}

          <div className="font-normal mb-[10px] w-[403px]">
            <InputBox
              type={"text"}
              head={"Designation Name"}
              name={"department"}
              holder={"Enter designation name"}
              onChange={handleChange}
              value={formvalues.department}
            />
          </div>

          <div className="font-normal mb-2 w-[403px]">
            <InputBox
              type={'text'}
              head={'Organization Name'}
              name={'organization'}
              holder={'Enter organization name'}
              onChange={handleChange}
              value={formvalues.organization}
            />
          </div>
          {/* <div className="font-normal mb-[10px]">
          <InputBox
            head={"Mobile Number"}
            name={"mobile"}
            holder={"Enter mobile number"}
            value={mobile}
            onChange={(e) => handleMobileChange(e)}
            handleBlur={handleBlur}
          />
        </div>
        {(touched.mobile && errors.mobile && (
          <div style={{ color: "red", marginBottom: "10px" }}>
            {errors.mobile}
          </div>
        )) ||
          (errors.mobile && (
            <div style={{ color: "red", marginBottom: "10px" }}>
              {errors.mobile}
            </div>
          ))} */}
          {/* <div className="font-normal mb-[10px]">
          <InputBox
            type={"text"}
            head={"Department Name"}
            name={"department"}
            holder={"Enter department name"}
            onChange={handleChange}
            value={formvalues.department}
          />
        </div> */}
          <div style={{ color: 'red', marginBottom: '10px', textAlign: 'left' }}>
            {organizationError}
          </div>
          {error && (
            <div style={{ color: 'red', marginBottom: '10px', textAlign: 'left' }}>
              User already exists.
            </div>
          )}
          {error1 && (
            <div style={{ color: 'red', marginBottom: '10px', textAlign: 'left' }}>
              Email not verified.
            </div>
          )}

          {/* <div className="font-normal mb-[10px]">
        <label className="mt-4" htmlFor="">
            Link Share Type<span className="text-red-500">*</span>
          </label>
          <span>
            <DropdownShareType
              holder="Select link share type"
              // campTypeValue={campTypeValue}
              // setCampTypeValue={setCampTypeValue}
              // setCampType={setCampType}
               toggle={toggle}
               setToggle={setToggle}
            />
          </span>
          </div> */}
          <div
            className="w-[28.819vw]"
            onClick={() => {
              handleEmailVerification();
            }}
          >
            <NextButton1 />
          </div>
          <p className="text-center font-normal w-full text-white text-[12px] mt-[10px] mb-[10px]">
            Already have an account?
            <Link to="/" className="font-semibold">
              Sign In
            </Link>
          </p>
          {/* </div> */}
        </div>
        <div
          className="justify-start items-end flex flex-col w-[49%] mr-4 relative"
        >
          <div style={{
            position: 'relative',
            width: '100%',
            height: '88vh',
            overflow: 'hidden',
            borderRadius: '20px',
          }}>
            <video
              autoPlay
              loop
              muted
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                width: '100%',
                height: '95vh',
                borderRadius: '20px',
                objectFit: 'cover',
                marginRight: '10px',
                transform: 'translate(-50%, -50%)'
              }}
            >
              <source src={signinvideo} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
          <img className='absolute top-0 right-0 h-[100%] object-cover fill-available' src={signinbg} alt="sign in  background" />
          {/* <Box1 /> */}
        </div>
      </div>
      {/* <div className="flex flex-col justify-center w-[80%] items-center ">
        <div className="w-[463px]">
          <img src={welcome} alt="welcome image" className="mb-[30px]" />
          <p className="text-white text-base font-satoshi">
            In publishing and graphic design, Lorem ipsum is a placeholder text
            commonly used to demonstrate the visual form...
          </p>
          <img src={robot} alt="robot image" className="mx-auto" />
        </div>
      </div> */}

      {/* <div
        className="justify-center flex flex-col rounded-[35px] w-[35.4vw] m-8 p-[45px] text-text-color-code1 font-sans1 text-base"
        style={{
          backgroundImage: `url(${borderSignIn}), url(${rectangleSignin})`,
          backgroundSize: 'cover, cover', // Adjust sizes if necessary
          backgroundPosition: 'center, center', // Adjust positions if necessary
          backgroundRepeat: 'no-repeat, no-repeat', // Ensure images don't repeat
        }}
      >
        <p className="text-white text-[36px] line-[38px] mb-[20px] font-medium">
          Sign Up
        </p>
        <p className="text-white text-[20px] line-[38px] tracking-2 mb-[20px]">
          Hello Let’s get started
        </p>
        <div className="">
          <div className="flex gap-2">
            <div>
              <div className="font-normal mb-[10px]">
                <InputBox
                  type={'text'}
                  head={'First Name'}
                  name={'firstName'}
                  holder={'Enter first name'}
                  onChange={handleChange}
                  value={formvalues.firstName}
                  handleBlur={handleBlur}
                  required
                />
              </div>
              <div className="relative">
                {errors.firstName && (
                  <div
                    style={{
                      color: 'red',
                      position: 'absolute',
                      top: '100%', // Position it just below the input
                      left: '0',
                      marginBottom: '0', // Ensure no space is taken
                      whiteSpace: 'pre-line',
                      zIndex: 10, // Increase z-index for visibility
                      backgroundColor: 'white', // Add white background
                      padding: '5px', // Optional: Add padding for better readability
                      borderRadius: '4px',
                    }}
                  >
                    {errors.firstName}
                  </div>
                )}
              </div>
            </div>
            <div>
              <div className="font-normal mb-[10px]">
                <InputBox
                  type={'text'}
                  head={'Last Name'}
                  name={'lastName'}
                  holder={'Enter last name'}
                  onChange={handleChange}
                  value={formvalues.lastName}
                  handleBlur={handleBlur}
                  required
                />
              </div>
              <div className="relative">
                {errors.lastName && (
                  <div
                    style={{
                      color: 'red',
                      position: 'absolute',
                      top: '100%', // Position it just below the input
                      left: '0',
                      marginBottom: '0', // Ensure no space is taken
                      whiteSpace: 'pre-line',
                      zIndex: 10, // Increase z-index for visibility
                      backgroundColor: 'white', // Add white background
                      padding: '5px', // Optional: Add padding for better readability
                      borderRadius: '4px',
                    }}
                  >
                    {errors.lastName}
                  </div>
                )}
              </div> */}
      {/* {errors.lastName && (
                <div style={{ color: "red", marginBottom: "10px" }}>
                  {errors.lastName}
                </div>
              )} */}
      {/* </div>
          </div>
          <div className="font-normal mb-[10px] flex flex-col">
            <InputBoxEmail
              type={'text'}
              head={'Email'}
              name={'email'}
              holder={'Enter email'}
              onChange={handleChange}
              value={formvalues.email}
              onKeyDown={handleKeyDown}
              handleBlur={handleBlur}
              verified={verified}
              generateOtp={generateOtp}
              errors={errors}
              formvalues={formvalues}
            />
            <div className="relative">
              {emailError && (
                <div
                  style={{
                    color: 'red',
                    position: 'absolute',
                    top: '100%', // Position it just below the input
                    left: '0',
                    marginBottom: '0', // Ensure no space is taken
                    whiteSpace: 'pre-line',
                    zIndex: 10, // Increase z-index for visibility
                    backgroundColor: 'white', // Add white background
                    padding: '5px', // Optional: Add padding for better readability
                    borderRadius: '4px',
                  }}
                >
                  Please verify your email.
                </div>
              )}
            </div> */}
      {/* {emailError && (
              <div style={{ color: "red", marginBottom: "10px", marginTop: "10px" }}>
                Please verify your email.
              </div>
            )} */}
      {/* {(!errors.email && formvalues.email !== "") && (
            <p
              className={`flex justify-end mt-2 text-[0.9em] cursor-pointer ${verified ? 'text-green-500' : ''}`}
              onClick={!verified ? generateOtp : undefined} // Disable click if already verified
              style={{ width: 'fit-content', alignSelf: 'flex-end', display: 'flex' }}
            >
              {verified ? 'Verified' : 'Verify'}
            </p>
          )} */}
      {/* </div>
          <div className="relative">
            {(touched.email && errors.email && (
              <div
                style={{
                  color: 'red',
                  position: 'absolute',
                  top: '100%', // Position it just below the input
                  left: '0',
                  marginBottom: '0', // Ensure no space is taken
                  whiteSpace: 'pre-line',
                  zIndex: 10, // Increase z-index for visibility
                  backgroundColor: 'white', // Add white background
                  padding: '5px', // Optional: Add padding for better readability
                  borderRadius: '4px',
                }}
              >
                {errors.email}
              </div>
            )) ||
              (errors.email && (
                <div
                  style={{
                    color: 'red',
                    position: 'absolute',
                    top: '100%', // Position it just below the input
                    left: '0',
                    marginBottom: '0', // Ensure no space is taken
                    whiteSpace: 'pre-line',
                    zIndex: 10, // Increase z-index for visibility
                    backgroundColor: 'white', // Add white background
                    padding: '5px', // Optional: Add padding for better readability
                    borderRadius: '4px',
                  }}
                >
                  {errors.email}
                </div>
              ))}
          </div> */}

      {/* {(showOtpInput && formvalues.email!=="") && (
        <div className="font-normal mb-[10px]">
          <InputBox
            type={"number"}
            head={"Verify OTP"}
            name={"otp"}
            holder={"Enter OTP"}
            onChange={handleChange}
            value={formvalues.otp}
            onKeyDown={(e) => handleKeyDownOTP(e)}
            handleBlur={handleBlur}
          />
           {otpError && (
          <div style={{ color: "red", marginBottom: "10px", marginTop: "10px" }}>
            Incorrect OTP.
          </div>
        )}
        {formvalues.otp==="" && (
          <div style={{ color: "red", marginBottom: "10px", marginTop: "10px" }}>
            OTP is required.
          </div>
        )}
        </div>
      )} */}
      {/* <div className="w-auto flex font-normal mb-[10px] relative">
            <span className="flex flex-col w-screen">
              <InputBox
                type={'password'}
                head={'Password'}
                name={'password'}
                holder={'Create password'}
                onChange={handleChange}
                value={formvalues.password}
                onKeyDown={handleKeyDown3}
                showPassword={showPassword}
                handleBlur={handleBlur}
                onFocus={() => validatePassword(formvalues.password)}
              />
            </span>
            <button
              onClick={togglePasswordVisibility}
              className="mr-[10px] mt-[15px] absolute inset-y-0 right-0 h-full flex items-center px-2"
            >
              {showPassword ? (
                <img src={openEye} alt="view password" />
              ) : (
                <img src={closedEye} alt="hide password" />
              )}
            </button>
          </div>
          <div style={{ position: 'relative' }}>
            {(touched.password && errors.password && (
              <div
                style={{
                  color: 'red',
                  position: 'absolute',
                  top: '100%', // Position it just below the input
                  left: '0',
                  marginBottom: '0', // Ensure no space is taken
                  whiteSpace: 'pre-line',
                  zIndex: 10, // Increase z-index for visibility
                  backgroundColor: 'white', // Add white background
                  padding: '5px', // Optional: Add padding for better readability
                  borderRadius: '4px', // Optional: Add border-radius for better styling
                }}
              >
                {errors.password}
              </div>
            )) ||
              (errors.password && (
                <div
                  style={{
                    color: 'red',
                    position: 'absolute',
                    top: '100%', // Position it just below the input
                    left: '0',
                    marginBottom: '0', // Ensure no space is taken
                    whiteSpace: 'pre-line',
                    zIndex: 10, // Increase z-index for visibility
                    backgroundColor: 'white', // Add white background
                    padding: '5px', // Optional: Add padding for better readability
                    borderRadius: '4px', // Optional: Add border-radius for better styling
                  }}
                >
                  {errors.password}
                </div>
              ))}
          </div>

          <div className="w-auto flex font-normal relative mb-[10px]">
            <span className="flex flex-col w-screen">
              <InputBox
                type={'password'}
                head={'Confirm Password'}
                name={'confirmPassword'}
                holder={'Re-enter password'}
                onChange={handleChange}
                value={formvalues.confirmPassword}
                showPassword1={showPassword1}
                onKeyDown={handleKeyDown3}
                handleBlur={handleBlur}
              />
            </span>
            <button
              onClick={togglePasswordVisibility1}
              className="mr-[10px] mt-[15px] absolute inset-y-0 right-0 h-full flex items-center px-2"
            >
              {showPassword1 ? (
                <img src={openEye} alt="view password" />
              ) : (
                <img src={closedEye} alt="hide password" />
              )}
            </button>
          </div>
          <div className="relative">
            {(touched.confirmpassword && errors.confirmpassword && (
              <div
                style={{
                  color: 'red',
                  position: 'absolute',
                  top: '100%', // Position it just below the input
                  left: '0',
                  marginBottom: '0', // Ensure no space is taken
                  whiteSpace: 'pre-line',
                  zIndex: 10, // Increase z-index for visibility
                  backgroundColor: 'white', // Add white background
                  padding: '5px', // Optional: Add padding for better readability
                  borderRadius: '4px',
                }}
              >
                {errors.confirmpassword}
              </div>
            )) ||
              (errors.confirmpassword && (
                <div
                  style={{
                    color: 'red',
                    position: 'absolute',
                    top: '100%', // Position it just below the input
                    left: '0',
                    marginBottom: '0', // Ensure no space is taken
                    whiteSpace: 'pre-line',
                    zIndex: 10, // Increase z-index for visibility
                    backgroundColor: 'white', // Add white background
                    padding: '5px', // Optional: Add padding for better readability
                    borderRadius: '4px',
                  }}
                >
                  {errors.confirmpassword}
                </div>
              ))}
          </div> */}

      {/* <div className="font-normal mb-[10px]">
          <label className="mt-4" htmlFor="">
            Link Share Type<span className="text-red-500">*</span>
          </label>
          <span>
            <DropdownShareType
              holder="Select link share type"
              linkTypeValue={linkTypeValue}
              setLinkTypeValue={setLinkTypeValue}
              setLinkTypeString={setLinkTypeString}
              linkTypeString={linkTypeString}
              // setCampType={setCampType}
              toggle={toggle}
              setToggle={setToggle}
            />
          </span>
        </div> */}

      {/* <div className="font-normal mb-[22px]">
            <InputBox
              type={'text'}
              head={'Organization Name'}
              name={'organization'}
              holder={'Enter organization name'}
              onChange={handleChange}
              value={formvalues.organization}
            />
          </div> */}
      {/* <div className="font-normal mb-[10px]">
          <InputBox
            head={"Mobile Number"}
            name={"mobile"}
            holder={"Enter mobile number"}
            value={mobile}
            onChange={(e) => handleMobileChange(e)}
            handleBlur={handleBlur}
          />
        </div>
        {(touched.mobile && errors.mobile && (
          <div style={{ color: "red", marginBottom: "10px" }}>
            {errors.mobile}
          </div>
        )) ||
          (errors.mobile && (
            <div style={{ color: "red", marginBottom: "10px" }}>
              {errors.mobile}
            </div>
          ))} */}
      {/* <div className="font-normal mb-[10px]">
          <InputBox
            type={"text"}
            head={"Department Name"}
            name={"department"}
            holder={"Enter department name"}
            onChange={handleChange}
            value={formvalues.department}
          />
        </div> */}
      {/* <div style={{ color: 'red', marginBottom: '10px' }}>
            {organizationError}
          </div>
          {error && (
            <div style={{ color: 'red', marginBottom: '10px' }}>
              User already exists.
            </div>
          )}
          {error1 && (
            <div style={{ color: 'red', marginBottom: '10px' }}>
              Email not verified.
            </div>
          )} */}

      {/* <div className="font-normal mb-[10px]">
        <label className="mt-4" htmlFor="">
            Link Share Type<span className="text-red-500">*</span>
          </label>
          <span>
            <DropdownShareType
              holder="Select link share type"
              // campTypeValue={campTypeValue}
              // setCampTypeValue={setCampTypeValue}
              // setCampType={setCampType}
               toggle={toggle}
               setToggle={setToggle}
            />
          </span>
          </div> */}
      {/* <div
            className="w-[28.819vw]"
            onClick={() => {
              handleEmailVerification();
            }}
          >
            <NextButton />
          </div>
          <p className="text-center font-normal w-full text-white text-[16px] mt-[10px] mb-[10px]">
            Already have an account?
            <Link to="/" className="font-semibold">
              Sign In
            </Link>
          </p>
        </div>
      </div> */}
    </div>
  );
};
