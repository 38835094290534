import { NavBar } from '../components/AdminComponents/navbar';
import { SideBar } from '../components/AdminComponents/sidebar';
import { Outlet, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';

export const AdminLayout = () => {
  const [logout, setLogout] = useState(false);
  const navigate = useNavigate();
  const [isShowPopup, setIsShowPopup] = useState(false);
  const [openNotification, setOpenNotification] = useState(false);

  return (
    <header className="h-[100vh] text-black w-full bg-[#F8FBFF] flex flex-col">
      {logout && (
        // <div className="absolute z-10 w-[100vw] flex justify-center items-center text-[#718096] h-[100vh] backdrop-blur">
        //   <span className=" p-[2.2rem] bg-white border-2 w-[22rem] h-[10rem]">
        //     <p className="text-center text-lg">Do you want to Exit?</p>
        //     <span className="flex gap-[4rem] justify-center mt-6">
        //       <button
        //         className="border-2 px-4 py-1"
        //         onClick={() => {
        //           navigate('/');
        //           sessionStorage.clear();
        //         }}
        //       >
        //         Yes
        //       </button>
        //       <button
        //         className="border-2 px-4 py-1"
        //         onClick={() => setLogout(false)}
        //       >
        //         No
        //       </button>
        //     </span>
        //   </span>
        // </div>
        <div className="fixed backdrop-blur inset-0 overflow-auto flex justify-end z-50">
          <div
            className="fill-available"
            onClick={() => setLogout(false)}
          ></div>
          <div className="absolute gap-8 justify-center flex flex-col items-center top-[30%] left-[30%] h-auto w-[702px] z-50 bg-white rounded-lg p-8 shadow-md ">
            <div className="rounded-[15px] flex flex-col bg-[#F5F8FF] w-full items-center justify-center py-8">
              <div className="text-center self-center w-[35vw] text-[28px] font-semibold tracking-[0.15px]">
                oh no! you're leaving... are you sure?
              </div>
              {/* <div className="text-[18px] text-center mt-5 w-[40vw] font-normal self-center">
                Hello Let’s get started. lorem ipsum lorem
              </div> */}

              {/* <img
              src={closeButton}
              alt="close button"
              onClick={onClose}
              className="cursor-pointer w-[40px] h-[40px]"
            /> */}
            </div>
            <div className="w-full gap-12 flex justify-center">
              {/* <CustomWhiteButton text={'Cancel'} onClick={setClose} /> */}
              <div
                className={`w-[50%] text-[14px] h-[62px] rounded-lg text-[#22284E] flex justify-center items-center cursor-pointer border-2 border-[#22284E]`}
                onClick={() => setLogout(false)}
              >
                Go Back
              </div>
              {/* <div
              className={`w-[384px] text-[14px] h-[52px] rounded-[16px] text-[#22284E] mt-4 flex justify-center items-center cursor-pointer ${hovered ? 'border-2 border-[#22284E]' : ''
                }`}
              onClick={handleAdd}
            >
              Save
            </div> */}
              <button
                className={`w-[50%] text-[18px] justify-end text-white h-[62px] rounded-lg bg-[#22284E]`}
                onClick={() => {
                  navigate('/');
                  sessionStorage.clear();
                }}
              >
                Logout
              </button>
              {/* <button
              className="h-12 w-[12.5rem] border-2"
              style={{
                backgroundColor: 'white',
                transition: 'background-color 0.3s',
              }}
              onMouseEnter={(e) => {
                e.target.style.backgroundColor = '#F1F2F4';
              }}
              onMouseLeave={(e) => {
                e.target.style.backgroundColor = '';
              }}
              onClick={handleAdd}
            >
              Update
            </button> */}
            </div>
            {/* <div className="justify-center flex gap-4 self-center">
            <CustomButtonWhite text={'Cancel'} onClick={onClose} />
            <CustomButton text={'Yes'} onClick={deleteMemeber} />
          </div> */}
          </div>
        </div>
      )}
      <>
        <NavBar
          setIsShowPopup={setIsShowPopup}
          openNotification={openNotification}
          setOpenNotification={setOpenNotification}
        />
      </>
      <div
        className="flex  bg-[#F8FBFF] fill-available fill-available-firefox h-[88%]"
        onClick={()=>setOpenNotification(false)}
      >
        <aside className="bg-[#F8FBFF] w-[20rem] flex flex-col items-center ">
          <SideBar
            setLogout={setLogout}
            isShowPopup={isShowPopup}
            setIsShowPopup={setIsShowPopup}
          />
        </aside>

        <main
          onClick={() => setIsShowPopup(false)}
          className="flex fill-available rounded-tl-[2rem] px-8 py-6 bg-[#EFF5FF] flex-col overflow-auto"
        >
          <Outlet />
        </main>
      </div>
    </header>
  );
};
