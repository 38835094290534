import React, { useState, useEffect } from 'react';
import { AnalyticsPageBtn, CustomButtonFilter } from '../../components/buttons';
import {
  deleteIcon,
  editIcon,
  userImg,
  searchIcon,
  arrowleft,
  arrowright,
  clockSvg,
} from '../../assets/imgs/index';
import { CampaignTable } from '../../components/AdminComponents/table';
import { useNavigate } from 'react-router-dom';
import { handleApiResponse } from '../../API/services';
import { ModalDeleteCampaign } from '../../components/AdminComponents/modal';
import { CustomButtonWhite } from '../../components/buttons';

function Campaign() {
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState();
  const navigate = useNavigate();
  const [deleteUid, setDeleteUid] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [searchResults, setSearchResults] = useState([]);
  let creator = sessionStorage.getItem("uid");
  let userType = sessionStorage.getItem("userType");
  const [tableData, setTableData] = useState([]);
  const [totalData, setTotalData] = useState();

  // Handler for the "Previous" button
  const goToPreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const goToNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };




  useEffect(() => {
    sessionStorage.removeItem('uploadResume');
    sessionStorage.removeItem('excelData');
    sessionStorage.removeItem('prevEmail');
    sessionStorage.removeItem('singleAddCandidate');
    sessionStorage.removeItem('checkedFilteredData');
    sessionStorage.removeItem('hrQuestion');
    sessionStorage.removeItem('camp_type');
    sessionStorage.removeItem('coding_ques');
    sessionStorage.removeItem('endDate1');
    sessionStorage.removeItem('createdDate');
    sessionStorage.removeItem('skills');
    sessionStorage.removeItem('campName');
    sessionStorage.removeItem('JobRole');
    sessionStorage.removeItem('selectedCheckboxes');
    sessionStorage.removeItem('savedAllSelcted');
    sessionStorage.removeItem('JobRole');


  }, [])

  const itemsPerPage = 7;

  const handleSearch = async (search) => {
    const reqData = {
      q: search,
      is_deleted: 0,
      creator: userType === '3' ? creator : '',
    };
    const response = await handleApiResponse(
      '/api/v1/interview/campaign_list/',
      reqData,
      1
    );
    if (response?.code === 'token_not_valid') {
      navigate('/');
      sessionStorage.clear();
      return;
    }
    console.log(response.data.data.data);
    setSearchResults(response.data.data.data);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' || e.keyCode === 13) {
      handleSearch();
    }
  };

  const findStatus = (start, end) => {
    const monthMap = {
      Jan: '1',
      Feb: '2',
      Mar: '3',
      Apr: '4',
      May: '5',
      Jun: '6',
      Jul: '7',
      Aug: '8',
      Sep: '9',
      Oct: '10',
      Nov: '11',
      Dec: '12',
    };
    function isSecDateBigger(date1, date2, pos) {
      // console.log(date1, date2);
      let [month1, day1, year1] = date1.split(' ');
      let [year2, month2, day2] = date2.split('-').map(Number);
      month1 = monthMap[month1];

      month1 = Number(month1);
      day1 = Number(day1);
      year1 = Number(year1);
      // console.log("today", year1, month1, day1);
      // console.log("start", year2, month2, day2);

      if (day1 === day2 && month1 === month2 && year1 === year2 && pos) {
        // console.log("true");
        return true;
      }

      if (year1 > year2) {
        return true;
      } else if (year1 < year2) {
        return false;
      }

      if (month1 > month2) {
        return true;
      } else if (month1 < month2) {
        return false;
      }

      return day1 > day2;
    }

    const todayDate = new Date();
    let thisDate = String(todayDate).slice(4, 15);
    let result = isSecDateBigger(thisDate, start, 1);
    if (result) {
      const res = isSecDateBigger(thisDate, end);
      if (res) {
        return 'Completed';
      } else {
        return 'Ongoing';
      }
    } else {
      return 'Upcoming';
    }
  };

  const getUser = async (userLimit, pageNo) => {
    const reqData = {
      "_limit": userLimit,
      "_page": pageNo,
      // campaign_type: 1,
      // q: search,
      is_deleted: 0,
      // creator: "",
      creator: userType === "3" ? creator : "",
    };
    const response = await handleApiResponse(
      '/api/v1/interview/campaign_list/',
      reqData,
      1
    );
    if (response?.code === 'token_not_valid') {
      navigate('/');
      sessionStorage.clear();
      return;
    }
    console.log(response);
    if (response.data.status_code === 200) {
      console.log("camp displayed", Object.values(response.data.data).flat());
      console.log('response.data.data.total', response.data.data.total)
      setTotalData(response.data.data.total)
      setTableData(Object.values(response.data.data.data).flat());
    }
  };

  console.log('tableData', tableData)

  useEffect(() => {
    sessionStorage.setItem("currentAddCampaign", 0);

    getUser(itemsPerPage, currentPage);

    console.log('in camp', deleteUid);
  }, [isDeleteOpen, currentPage]);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const [search, setSearch] = useState("");
  const currentPageData = tableData;
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isTypeOpen, setIsTypeOpen] = useState(false);
  const [isSortOpen, setIsSortOpen] = useState(false);
  const [isSortingOpen, setIsSortingOpen] = useState(false);
  const [selectedSorting, setSelectedSorting] = useState(null);
  const [selectedSort, setSelectedSort] = useState(null);
  const [selectedType, setSelectedType] = useState(null);
  const [filteredData, setFilteredData] = useState([]);
  const [filteredData1, setFilteredData1] = useState([]);
  const [filteredData2, setFilteredData2] = useState([]);
  const [currentData, setCurrentData] = useState([]);

  
  // console.log(filteredData1.length)
  const handleTypeToggle = () => {
    setIsTypeOpen(!isTypeOpen);
  };

  const handleSortToggle = () => {
    setIsSortOpen(!isSortOpen);
  };

  const handleSortingToggle = () => {
    setIsSortingOpen(!isSortingOpen);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const openModalEditJobRole = () => {
    setIsModalOpen4(true);
  };

  const closeModalEditJobRole = () => {
    setIsModalOpen4(false);
  };

  const openDelete = () => {
    setIsDeleteOpen(true);
  };
  const closeDelete = () => {
    setIsDeleteOpen(false);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  // const handleTypeOptionClick = (typeOption) => {
  //   console.log(`Selected type option: ${typeOption}`);
  //   setIsTypeOpen(false); // Optionally close the type dropdown after selection
  // };

  // const handleOptionClick = (option) => {
  //   console.log(`Selected option: ${option}`);
  //   setIsOpen(false); // Optionally close the dropdown after selection
  // };

  const handleTypeOptionClick = (typeOption) => {
    setSelectedType(typeOption);
    if (typeOption === 'Mass') {
      setFilteredData(tableData.filter((item) => item.campaign_type !== 1));
    } else if (typeOption === 'Regular') {
      setFilteredData(tableData.filter((item) => item.campaign_type === 1));
    } else {
      setFilteredData(tableData);
    }
    setIsTypeOpen(false);
  };
  console.log(tableData);

  const getDataForPage = (dataSource, startIndex, endIndex) => {
    if (dataSource) {
      return dataSource.slice(startIndex, endIndex);
    }
    return [];
  };

  useEffect(()=>{
    console.log("working")
    let currentData=[]
    if (filteredData1?.length > 0) {
      console.log("working 1")
      currentData = getDataForPage(filteredData1, startIndex, endIndex);
    } else if (filteredData?.length > 0) {
      console.log("working 2")
      currentData = getDataForPage(filteredData, startIndex, endIndex);
    } else if (filteredData2?.length > 0) {
      console.log("working 3")
      currentData = getDataForPage(filteredData2, startIndex, endIndex);
    } else if (searchResults?.length > 0) {
      console.log("working 4")
      currentData = getDataForPage(searchResults, startIndex, endIndex);
    } else {
      console.log("working 5",getDataForPage(currentPageData, 0, 7),currentPageData,startIndex, endIndex)
      currentData = getDataForPage(currentPageData, 0, 7)
    }
    setCurrentData(currentData)
  },[currentPage,currentData])

 


  const handleSortOptionClick = (sortOption) => {
    setSelectedSort(sortOption);
    let filteredArray = [];

    if (sortOption === 'Upcoming') {
      filteredArray = tableData.filter((item) => {
        let start_date = new Date(item?.start_date);
        return start_date > new Date();
      });
    } else if (sortOption === 'Ongoing') {
      console.log('-------------------------');
      filteredArray = tableData.filter((item) => {
        let start_date = new Date(item?.start_date);
        let end_date = new Date(item?.end_date);
        let currentDate = new Date();
        return start_date <= currentDate && currentDate <= end_date;
      });
    } else if (sortOption === 'Completed') {
      filteredArray = tableData.filter((item) => {
        let end_date = new Date(item?.end_date);
        return end_date < new Date();
      });
    }

    setFilteredData1(filteredArray);
    setIsSortOpen(false);
  };

  const handleSortingOptionClick = (sortOption) => {
    setSelectedSorting(sortOption);
    let filteredArray = [];

    if (sortOption === 'Name') {
      filteredArray = [...tableData].sort((a, b) => {
        let nameA = a.name.toUpperCase(); // ignore upper and lowercase
        let nameB = b.name.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0; // names must be equal
      });
    } else if (sortOption === 'Ongoing') {
      filteredArray = tableData.filter((item) => {
        let start_date = new Date(item?.start_date);
        let end_date = new Date(item?.end_date);
        let currentDate = new Date();
        return start_date <= currentDate && currentDate <= end_date;
      });
    } else if (sortOption === 'Completed') {
      filteredArray = tableData.filter((item) => {
        let end_date = new Date(item?.end_date);
        return end_date < new Date();
      });
    }

    setFilteredData2(filteredArray);
    setIsSortOpen(false);
  };

  console.log(currentPageData);

  let dataSource ='';

  if (filteredData1?.length > 0) {
    dataSource = filteredData1;
  } else if (filteredData?.length > 0) {
    dataSource = filteredData;
  } else if (filteredData2?.length > 0) {
    dataSource = filteredData2;
  } else if (searchResults?.length > 0) {
    dataSource = searchResults;
  }

const totalPages = Math.ceil(totalData / itemsPerPage);

React.useEffect(() => {
  if (dataSource) {
    setTotalData(dataSource.length);
  }
}, [dataSource]);
console.log(totalData);
console.log(itemsPerPage)
console.log(totalPages)
  return (
    <div className="text-[#A6AEBA] font-satoshi rounded-[30px] bg-[#FFFFFF] p-[1rem] gap-8 flex flex-col">
      {isDeleteOpen && (
        <ModalDeleteCampaign
          isOpen={openDelete}
          deleteUid={deleteUid}
          onClose={closeDelete}
        />
      )}
      <div className="flex items-center justify-between">
        <div className="text-[24px] text-[#222850]"><i>Campaign List</i></div>
        <div className='flex gap-2 justify-center items-center'>
          <div className="relative">
            <input
              placeholder="Search"
              onChange={(e) => {
                console.log(e.target.value)
                // setSearch(e.target.value)
                handleSearch(e.target.value);
              }}
              className="w-[261px] h-[36px] text-[12px] p-5 pl-8 outline-0  bg-[#E9F3FF] rounded-lg mt-2 placeholder-text-sm"
              // value=
              onKeyDown={handleKeyDown}
            />
            <img
              src={searchIcon}
              alt="search icon"
              className="absolute cursor-pointer top-0 left-0 w-[16px] h-[51.2px] ml-2"
            />
          </div>
          <div className="relative">
            <CustomButtonFilter text={'Filter'} onClick={toggleDropdown} />
            {isDropdownOpen && (
              <div className="">
                <div
                  className="py-1 mt-2 absolute w-full h-full z-[1000]"
                  role="menu"
                  aria-orientation="vertical"
                  aria-labelledby="options-menu"
                >
                  <div className='rounded-lg bg-[#E8E8E8]'>
                  <div className="px-2 bg-white relative z-1000">
                    <button
                      onClick={handleTypeToggle}
                      className="inline-flex justify-between w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none"
                    >
                      {selectedType ? selectedType : 'Type'}
                    </button>
                    {isTypeOpen && (
                      <div className="mt-2">
                        <div
                          className="py-1"
                          role="menu"
                          aria-orientation="vertical"
                          aria-labelledby="type-options-menu"
                        >
                          <div
                            className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 cursor-pointer"
                            role="menuitem"
                            onClick={() => handleTypeOptionClick('Mass')}
                          >
                            Mass
                          </div>
                          <div
                            className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 cursor-pointer"
                            role="menuitem"
                            onClick={() => handleTypeOptionClick('Regular')}
                          >
                            Regular
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="px-2 bg-white relative z-1000">
                    <button
                      onClick={handleSortToggle}
                      className="inline-flex justify-between w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none"
                    >
                      {selectedSort ? selectedSort : 'Status'}
                    </button>
                    {isSortOpen && (
                      <div className="mt-2">
                        <div
                          className="py-1"
                          role="menu"
                          aria-orientation="vertical"
                          aria-labelledby="type-options-menu"
                        >
                          <div
                            className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 cursor-pointer"
                            role="menuitem"
                            onClick={() => handleSortOptionClick('Upcoming')}
                          >
                            Upcoming
                          </div>
                          <div
                            className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 cursor-pointer"
                            role="menuitem"
                            onClick={() => handleSortOptionClick('Ongoing')}
                          >
                            Ongoing
                          </div>
                          <div
                            className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 cursor-pointer"
                            role="menuitem"
                            onClick={() => handleSortOptionClick('Completed')}
                          >
                            Completed
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="px-2 bg-white relative z-1000">
                    <button
                      onClick={handleSortingToggle}
                      className="inline-flex justify-between w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none"
                    >
                      {selectedSorting ? selectedSorting : 'Sort By'}
                    </button>
                    {isSortingOpen && (
                      <div className="mt-2">
                        <div
                          className="py-1"
                          role="menu"
                          aria-orientation="vertical"
                          aria-labelledby="type-options-menu"
                        >
                          <div
                            className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 cursor-pointer"
                            role="menuitem"
                            onClick={() => handleSortingOptionClick('Name')}
                          >
                            Name
                          </div>
                          {/* <div
                              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 cursor-pointer"
                              role="menuitem"
                              onClick={() => handleSortOptionClick("Ongoing")}
                            >
                              Ongoing
                            </div>
                            <div
                              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 cursor-pointer"
                              role="menuitem"
                              onClick={() => handleSortOptionClick("Completed")}
                            >
                              Completed
                            </div> */}
                        </div>
                      </div>
                    )}
                  </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          {userType !== "2" && (
          <AnalyticsPageBtn
            name={'Add Campaign'}
            img={5}
            onClick={() => navigate('/campaign/addCampaign')}
          />
          )}
        </div>
      </div>
      <div>
        <div class="relative overflow-x-auto">
          <div className="flex">
            {/* <div className="flex p-4">
              <input
                placeholder="Search campaign"
                onChange={(e) => {setSearch(e.target.value)
                  handleSearch();
                } }
                className="w-[70vw] h-10 p-5 outline-0 border-[#F1F2F4] border-2 bg-[#FFFFFF] rounded-lg mt-2 placeholder-text-sm"
                value={search}
                onKeyDown={handleKeyDown}
              />
              <img
                src={searchIcon}
                alt="search icon"
                className="cursor-pointer right-8 relative w-[16px] h-[51.2px]"
              />
            </div> */}
            {/* <div className="mt-4 relative">
              <CustomButtonWhite text={'Filter'} onClick={toggleDropdown} />
              {isDropdownOpen && (
                <div className="">
                  <div
                    className="py-1 mt-2 absolute w-full h-full"
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="options-menu"
                  >
                    <div className="p-4 border-2 bg-white relative z-1000">
                      <button
                        onClick={handleTypeToggle}
                        className="inline-flex justify-between w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none"
                      >
                        {selectedType ? selectedType : 'Type'}
                      </button>
                      {isTypeOpen && (
                        <div className="mt-2">
                          <div
                            className="py-1"
                            role="menu"
                            aria-orientation="vertical"
                            aria-labelledby="type-options-menu"
                          >
                            <div
                              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 cursor-pointer"
                              role="menuitem"
                              onClick={() => handleTypeOptionClick('Mass')}
                            >
                              Mass
                            </div>
                            <div
                              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 cursor-pointer"
                              role="menuitem"
                              onClick={() => handleTypeOptionClick('Regular')}
                            >
                              Regular
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="p-4 border-2 bg-white relative z-1000">
                      <button
                        onClick={handleSortToggle}
                        className="inline-flex justify-between w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none"
                      >
                        {selectedSort ? selectedSort : 'Status'}
                      </button>
                      {isSortOpen && (
                        <div className="mt-2">
                          <div
                            className="py-1"
                            role="menu"
                            aria-orientation="vertical"
                            aria-labelledby="type-options-menu"
                          >
                            <div
                              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 cursor-pointer"
                              role="menuitem"
                              onClick={() => handleSortOptionClick('Upcoming')}
                            >
                              Upcoming
                            </div>
                            <div
                              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 cursor-pointer"
                              role="menuitem"
                              onClick={() => handleSortOptionClick('Ongoing')}
                            >
                              Ongoing
                            </div>
                            <div
                              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 cursor-pointer"
                              role="menuitem"
                              onClick={() => handleSortOptionClick('Completed')}
                            >
                              Completed
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="p-4 border-2 bg-white relative z-1000">
                      <button
                        onClick={handleSortingToggle}
                        className="inline-flex justify-between w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none"
                      >
                        {selectedSorting ? selectedSorting : 'Sort By'}
                      </button>
                      {isSortingOpen && (
                        <div className="mt-2">
                          <div
                            className="py-1"
                            role="menu"
                            aria-orientation="vertical"
                            aria-labelledby="type-options-menu"
                          >
                            <div
                              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 cursor-pointer"
                              role="menuitem"
                              onClick={() => handleSortingOptionClick('Name')}
                            >
                              Name
                            </div> */}
            {/* <div
                              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 cursor-pointer"
                              role="menuitem"
                              onClick={() => handleSortOptionClick("Ongoing")}
                            >
                              Ongoing
                            </div>
                            <div
                              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 cursor-pointer"
                              role="menuitem"
                              onClick={() => handleSortOptionClick("Completed")}
                            >
                              Completed
                            </div> */}
            {/* </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div> */}
          </div>
          <table class="w-full text-sm text-[#A6AEBA] text-left rtl:text-right">
            <thead class="text-[15px] h-[54px] text-[#222850] bg-[#F7FAFC] border-b border-[#E4E7EB]">
              <tr>
                <th scope="col" class="px-6">
                  S.No.
                </th>
                <th scope="col" class="px-6">
                  Campaign Name
                </th>
                <th scope="col" class="px-6">
                  Created By
                </th> 
                {/* {userType !== "2" && (<th scope="col" class="px-6 ">
                  Type
                </th>
                )} */}
                <th scope="col" class="px-6 py-2">
                  Type
                </th>
                <th scope="col" class="px-6">
                  Job Role
                </th>
                <th scope="col" class="px-6">
                  Start Date
                </th>
                <th scope="col" class="px-6">
                  End Date
                </th>
                {/* {userType !== "2" && (  <th scope="col" class="px-6 py-3">
                  Edited By
                </th>
                )} */}
                <th scope="col" class="px-8">
                  Status
                </th>
                <th scope="col" class="px-6">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
            {currentData?.map((rowData, index) => (
            <CampaignTable
              key={index}
              icon={userImg}
              number={startIndex + index}
              row12={rowData?.name}
              row13={rowData?.campaign_type === 1 ? "Regular" : "Mass"}
              row14={rowData?.jr_name}
              row15={rowData?.start_date?.slice(0, 10)}
              row16={rowData?.end_date.slice(0, 10)}
              row17={rowData?.creater_name}
              row19={rowData?.updater_name}
              row18={findStatus(
                rowData?.start_date?.slice(0, 10),
                rowData?.end_date.slice(0, 10)
              )}
              launched={rowData?.status}
              skills={rowData?.required_skills}
              description={rowData?.description}
              editIcon={editIcon}
              resumeOn={rowData?.is_candidate_resume_allow}
              codingOn={rowData?.is_coding_quest_allow}
              deleteIcon={deleteIcon}
              openModal={openModalEditJobRole}
              closeModal={closeModalEditJobRole}
              isModalOpen4={isModalOpen4}
              uid={rowData.uid}
              type={rowData.campaign_type}
              openDelete={openDelete}
              setDeleteUid={setDeleteUid}
              jobRoleUid={rowData.jobrole}
              creator={rowData.creator}
              style={{ backgroundColor: index % 2 === 0 ? "#FFFFFF" : "#F9FBFD" }}
            />
          ))}
            </tbody>
          </table>
          {/* <div className="pagination flex justify-between mt-4">
            <div className='flex text-[#091E42] text-[14px] border-[1px] border-[#D0D5DD] h-fit rounded-[3rem] p-2 w-[110px] justify-center gap-2 cursor-pointer' onClick={goToPreviousPage}>
              <img src={arrowleft} alt="arrow left" className="w-[20px] h-[20px]" />
              Previous
            </div>
            <div>
              {totalData > 0 && itemsPerPage > 0 && (
                [...Array(Math.ceil(totalData / itemsPerPage)).keys()].map((page) => {
                  const pageNumber = page + 1;
                  const isCurrentPage = currentPage === pageNumber;
                  const isFirstPage = pageNumber === 1;
                  const isLastPage = pageNumber === Math.ceil(totalData / itemsPerPage);
                  const isWithinLeftRange = pageNumber >= currentPage - 3 && pageNumber < currentPage;
                  const isWithinRightRange = pageNumber <= currentPage + 3 && pageNumber > currentPage;

                  if (isFirstPage || isLastPage || isCurrentPage || isWithinLeftRange || isWithinRightRange) {
                    return (
                      <button
                        key={pageNumber}
                        onClick={() => {
                          handlePageChange(pageNumber);
                          getUser(itemsPerPage, pageNumber); // Pass the page number to getUser
                        }}
                        className={`px-3 py-2 mx-1 rounded-md text-[14px] ${isCurrentPage
                          ? " border border-[#197FF5] bg-[#E9F3FF] text-[#091E42]"
                          : " text-[#091E42]"
                          }`}
                      >
                        {pageNumber}
                      </button>
                    );
                  } else if (
                    (pageNumber === currentPage - 4 && pageNumber !== 1) ||
                    (pageNumber === currentPage + 4 && pageNumber !== Math.ceil(totalData / itemsPerPage))
                  ) {
                    return (
                      <span key={pageNumber} className="text-[#091E42] px-3 py-2 mx-1">...</span>
                    );
                  } else {
                    return null;
                  }
                })
              )}
            </div>
            <div className='flex text-[#667085] text-[14px] border-[1px] border-[#D0D5DD] h-fit rounded-[3rem] p-2 w-[110px] justify-center gap-2 cursor-pointer' onClick={goToNextPage}>
              Next
              <img src={arrowright} className="w-[20px] h-[20px]" alt="arrow right" />
            </div>
          </div> */}
          <div className="pagination flex justify-between mt-4">
        <div className='flex text-[#091E42] text-[14px] border-[1px] border-[#D0D5DD] h-fit rounded-[3rem] p-2 w-[110px] justify-center gap-2 cursor-pointer' onClick={goToPreviousPage}>
          <img src={arrowleft} alt="arrow left" className="w-[20px] h-[20px]" />
          Previous
        </div>

        <div>
          {totalPages > 0 && (
            [...Array(totalPages).keys()].map((page) => {
              const pageNumber = page + 1;
              const isCurrentPage = currentPage === pageNumber;
              const isFirstPage = pageNumber === 1;
              const isLastPage = pageNumber === totalPages;
              const isWithinLeftRange = pageNumber >= currentPage - 3 && pageNumber < currentPage;
              const isWithinRightRange = pageNumber <= currentPage + 3 && pageNumber > currentPage;

              if (isFirstPage || isLastPage || isCurrentPage || isWithinLeftRange || isWithinRightRange) {
                return (
                  <button
                    key={pageNumber}
                    onClick={() => handlePageChange(pageNumber)}
                    className={`px-3 py-2 mx-1 rounded-md text-[14px] ${isCurrentPage
                      ? " border border-[#197FF5] bg-[#E9F3FF] text-[#091E42]"
                      : " text-[#091E42]"
                      }`}
                  >
                    {pageNumber}
                  </button>
                );
              } else if (
                (pageNumber === currentPage - 4 && pageNumber !== 1) ||
                (pageNumber === currentPage + 4 && pageNumber !== totalPages)
              ) {
                return (
                  <span key={pageNumber} className="text-[#091E42] px-3 py-2 mx-1">...</span>
                );
              } else {
                return null;
              }
            })
          )}
        </div>

        <div className='flex text-[#667085] text-[14px] border-[1px] border-[#D0D5DD] h-fit rounded-[3rem] p-2 w-[110px] justify-center gap-2 cursor-pointer' onClick={goToNextPage}>
          Next
          <img src={arrowright} className="w-[20px] h-[20px]" alt="arrow right" />
        </div>
      </div>

        </div>
      </div>
    </div>
  );
}

export default Campaign;
