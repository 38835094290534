import { downloadIcon, shareIcon, importIcon, addIcon } from '../assets/imgs';
import { FiLogOut } from 'react-icons/fi';
import browseIcon from '../assets/imgs/browseicon1.svg';
import { current } from '@reduxjs/toolkit';

export const Nextbutton = ({ isFinal, campaignType, current }) => {
  const final = campaignType === 2 && current === 1 ? true : isFinal;
  return (
    <button className="border-2 border-solid h-12 w-[12.5rem] bg-transparent hover:bg-fill-btn-color-code1 ">
      {final ? 'Finish' : 'Next'}
    </button>
  );
};

export const CustomButton = (props) => {
  return (
    <button
      className="h-12 w-[12.5rem] border-2"
      onChange={props?.onChange}
      type={props.type}
      style={{ backgroundColor: 'white', transition: 'background-color 0.3s' }}
      onMouseEnter={(e) => {
        e.target.style.backgroundColor = '#F1F2F4';
      }}
      onMouseLeave={(e) => {
        e.target.style.backgroundColor = '';
      }}
      onClick={props.onClick}
    >
      {props.text}
    </button>
  );
};

export const CustomButtonWhite = (props) => {
  return (
    <button
      className="h-12 w-[12.5rem] rounded-lg border-2 text-[#A6AEBA]"
      style={{
        backgroundColor: '#22284E',
        transition: 'background-color 0.3s',
      }}
      onMouseEnter={(e) => {
        e.target.style.backgroundColor = '#F1F2F4';
      }}
      onMouseLeave={(e) => {
        e.target.style.backgroundColor = '';
      }}
      onClick={props.onClick}
    >
      {props.text}
    </button>
  );
};

export const CustomButtonFilter = (props) => {
  return (
    <button
      className="h-[36px] w-[127px] rounded-[8px] text-white bg-[#0875F4] mt-2"
      onClick={props.onClick}
    >
      {props.text}
    </button>
  );
};

export const Finishbutton = ({ isAllCheck }) => {
  return (
    <button
      className={`h-12 w-[12.5rem] bg-fill-btn-color-code1 ${
        isAllCheck ? 'bg-red-300 text-white' : null
      }`}
    >
      Accept
    </button>
  );
};

export const Backbutton = () => {
  return (
    <button className="border-2 border-solid h-12 w-[12.5rem] bg-transparent hover:bg-fill-btn-color-code1">
      Back
    </button>
  );
};

export const AnalyticsPageBtn = ({ img, name, onClick }) => {
  return (
    <button
      className="h-[36px] w-[129px] border-[1px] border-[#0875F4] bg-[#E9F3FF] text-[#0875F4] text-[12px] flex items-center justify-center gap-[0.4rem] rounded-lg"
      onClick={onClick}
    >
      {img === 1 ? (
        <img src={downloadIcon} alt="" />
      ) : img === 4 ? (
        <img src={importIcon} alt="" />
      ) : img === 5 ? (
        <img src={addIcon} alt="" />
      ) : img === 3 ? (
        <FiLogOut />
      ) : (
        <img src={shareIcon} alt="" />
      )}
      <p>{name}</p>
    </button>
  );
};

export const Button = ({ name, onClick }) => {
  return (
    <button
      className="h-12 w-[10.7rem] bg-fill-btn-color-code1 flex items-center justify-center gap-[0.4rem] rounded-lg"
      onClick={onClick}
    >
      {name}
    </button>
  );
};

export const BrowseButton = ({ img, id, name, onClick }) => {
  return (
    <button
      className="w-[118px] h-[26px] bg-[#0075FF] w-[10.7rem] text-white flex items-center justify-center gap-[0.4rem] rounded-lg"
      onClick={onClick}
      id={id}
    >
      {img === 1 ? (
        <img src={browseIcon} alt="upload icon" />
      ) : (
        <img src={shareIcon} alt="" />
      )}
      {name}
    </button>
  );
};

export const NextButton = ({ current, onClick, isAllCheck }) => {
  const isDisabled = current === 2 && !isAllCheck;

  return (
    <button
      className={`w-[100%] text-[18px] justify-end text-white h-[57px] rounded-lg bg-[#22284E] ${
        isDisabled ? 'opacity-50 cursor-not-allowed' : ''
      }`}
      onClick={onClick}
      disabled={isDisabled}
    >
      Next
    </button>
  );
};

export const NextButton1 = ({ current, onClick, isAllCheck }) => {
  const isDisabled = current === 2 && !isAllCheck;

  return (
    <button
      className={`w-[100%] text-[18px] justify-end text-white h-[42px] rounded-lg bg-[#0194FE] ${
        isDisabled ? 'opacity-50 cursor-not-allowed' : ''
      }`}
      onClick={onClick}
      disabled={isDisabled}
    >
      Next
    </button>
  );
};

export const NextButton2 = ({ current, onClick, isAllCheck }) => {
  const isDisabled = current === 2 && !isAllCheck;

  return (
    <div className="p-[1px] rounded-[24px] bg-gradient-to-b from-[#01FFFF] via-transparent to-transparent ">
      <button
        className={`w-[100%] text-[18px] justify-end text-white h-[56px] rounded-[24px] bg-gradient-to-l from-[#00C3FD] to-[#0194FE] ${
          isDisabled ? 'opacity-50 cursor-not-allowed' : ''
        }`}
        onClick={onClick}
        disabled={isDisabled}
      >
        Next
      </button>
    </div>
  );
};

export const BackButton = ({ onClick }) => {
  return (
    <button
      className="justify-end text-white h-[57px] w-[187px] rounded-[24px] border-[1px] border-[#055298]  text-[20px] bg-gradient-to-l from-[rgb(0,195,253,0.15)] to-[rgb(1,148,254,0.15)]"
      onClick={onClick}
    >
      Back
    </button>
  );
};



export const DownloadButton = ({ onClick, text }) => {
  return (
    <button
      className="justify-end text-white h-[57px] w-[187px] rounded-lg bg-gradient-to-r from-[#00C3FD] text-[20px] via-[#0194FE] to-[#018CF0]"
      onClick={onClick}
    >
      {text}
    </button>
  );
};

// export const ShareButton = ({onClick}) => {
//   return (
//     <button
//       className={`flex gap-2 w-[100%] items-center justify-center text-black h-[56px] rounded-[30px] bg-white text-[18px]`}
//       onClick={onClick}
//     >
//       <img src={shareIcon} alt="share icon"/>
//       Share
//     </button>
//   );
// };

export const DownloadButton1 = ({ onClick }) => {
  return (
    <button
      className={`flex gap-2 w-[100%] items-center justify-center text-white h-[57px] rounded-[30px] bg-gradient-to-r from-[#00C3FD] text-[18px] via-[#0194FE] to-[#018CF0]`}
      onClick={onClick}
    >
      <img src={downloadIcon} alt="download icon" />
      Download
    </button>
  );
};

export const CustomBlueButton = ({ onClick, text }) => {
  return (
    <button
      className={`flex gap-2 w-[100%] items-center justify-center text-white h-[57px] rounded-[30px] bg-gradient-to-r from-[#00C3FD] via-[#0194FE] to-[#018CF0]`}
      onClick={onClick}
    >
      {text}
    </button>
  );
};

export const CustomWhiteButton = ({ onClick, text }) => {
  return (
    <button
      className={`border-2 border-[#FFFFFF] flex gap-2 w-[100%] items-center justify-center text-white h-[57px] rounded-[30px]`}
      onClick={onClick}
    >
      {text}
    </button>
  );
};
