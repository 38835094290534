import {
  ModalGenerateSelfAnalytics,
  ModalPopup,
  ModalViewJobRole,
  ModalViewJobRole1,
} from './modal';
import { ModalEditJobRole } from './modal';
import { ModalDeleteJobRole } from './modal';
import { ModalDeleteCampaign } from './modal';
import { useState, useEffect } from 'react';
import { Modal2 } from './modal';
import { LinkIcon, downloadButton, eyeButton } from '../../assets/imgs';
import { useNavigate } from 'react-router-dom';
import { handleApiResponse } from '../../API/services';
import { toast } from 'react-toastify';
import { AiTwotoneVideoCamera } from 'react-icons/ai';
import jsPDF from 'jspdf';
import { getLocal } from '../../utils/localStorage';
import { Loader } from '../loader';

const base_url = process.env.REACT_APP_FRONEND;

const ProgressBar = ({ score, total }) => {
  const filled = Math.round((score / total) * total);

  return (
    <div className="flex flex-col">
      <div className="flex space-x-1">
        {[...Array(total)].map((_, index) => (
          <div key={index} className="relative">
            <div
              className={`w-4 h-4 ${
                index < filled ? 'bg-green-500' : 'bg-gray-300'
              }`}
            />
            {(index + 1) % 10 === 0 && (
              <div className="absolute top-6 left-1/2 transform -translate-x-1/2 text-xs text-center">
                {index + 1}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export const Table = (props) => {
  console.log(props);
  const openModal2 = () => {
    props.setuuid(props.uid);
    props.setOpenDeleteConfirmation(true);
    console.log('this is openUID', props.uid);
  };

  const handleEditData = () => {
    props.setEditData(props.all);
    props.setOpenEdit(true);
  };

  const [score, setScore] = useState(3);
  const total = 20;

  // Example to change score dynamically
  const increaseScore = () => setScore((prev) => Math.min(prev + 1, total));
  const decreaseScore = () => setScore((prev) => Math.max(prev - 1, 0));

  return (
    <tr
      class="text-[0.972vw] bg-white border-b border-dashed cursor-pointer"
      style={props.style}
    >
      <td class="px-3 py-4">{props?.number}</td>
      <td class="px-3 py-4">{props?.name}</td>
      <td class="px-3 py-4">{props?.row12}</td>
      <td class="px-3 py-4">{props?.row13}</td>
      <td class="px-3 py-4">{props?.row14}</td>
      <td class="px-3 py-4">
        <p
          className={`h-[2.031vw] w-[7vw] border-[1px] ${
            props.row15 === 'Inactive'
              ? 'text-[#FC7F29] border-[#FC7F29]'
              : 'text-[#48C885] border-[#48C885]'
          } flex items-center justify-center rounded-[1.111vw] text-[0.972vw]`}
        >
          {props?.row15}
        </p>
      </td>

      {props.noPermission === 'false' && (
        <td class="px-3 py-4 flex gap-2">
          <span className="bg-[#0875F4] flex justify-center px-3 rounded-lg">
            <img
              src={props?.editIcon}
              alt="edit icon"
              onClick={handleEditData}
            />
          </span>
          <span className="bg-[#FC7F29] flex justify-center px-3 rounded-lg">
            <img
              src={props?.deleteIcon}
              alt="delete icon"
              onClick={openModal2}
            />
          </span>
        </td>
      )}
    </tr>
  );
};

export const JobRoleTable = (props) => {
  const [showTooltip, setShowTooltip] = useState(false);
  let creator = sessionStorage.getItem('uid');
  let userType = sessionStorage.getItem('userType');
  let parentId = sessionStorage.getItem('parentId');
  const navigate = useNavigate();
  const isClickable = creator === props?.rowData?.creator;
  const truncatedText =
    props.row13.length > 300 ? props.row13.slice(0, 300) + '...' : props.row13;
  console.log(truncatedText);
  const [showView, setShowView] = useState(false);

  const openModal5 = () => {
    if (isClickable) {
      props.setuuid(props.uid);
      props.setOpenWhat('Delete');
      props.setOpenDeleteConfirmation(true);
      console.log('this is openUID', props.uid);
    } else {
      toast.info("You can't Delete this job !");
    }
  };

  const openModalEditJobRole = () => {
    props.setuuid(props.uid);
    console.log(props?.rowData);
    props.setOpenEditConfirmation(props?.rowData);
    console.log('this is openUID', props.uid);
  };

  const closeModal5 = () => {
    props.setIsModalOpen5(false);
  };
  console.log(props.uid);

  const editJobRole = async () => {
    console.log(props.uid);
    try {
      const formData = new FormData();
      formData.append('uid', props.uid);
      formData.append('is_deleted', '1');
      const response = await handleApiResponse(
        '/api/v1/interview/add_jobrole/',
        formData,
        1
      );
      if (response?.code === 'token_not_valid') {
        navigate('/');
        sessionStorage.clear();
        return;
      }
      console.log(response?.data.status_code);
      if (response?.data.status_code) {
        closeModal5();
        toast.success('Deleted successfully.', {
          toastStyle: {
            borderLeft: '5px solid #4caf50',
            backgroundColor: '#00112D',
            color: 'white',
          },
        });
      } else {
        closeModal5();
        toast.error('Something went wrong.', {
          toastStyle: {
            borderLeft: '5px solid #f44336',
          },
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleDownload = async () => {
    try {
      // Construct the URL using the environment variable and props.row17
      const fileUrl = `${process.env.REACT_APP_URL_DOC}/jds/${props.row17}`;
      console.log(fileUrl);
      const response = await fetch(fileUrl);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const blob = await response.blob();

      // Create a hidden anchor element
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = fileUrl.split('/').pop(); // Extract the file name from the URL
      link.style.display = 'none';

      // Append the link to the body
      document.body.appendChild(link);

      // Programmatically click the link to trigger the download
      link.click();

      // Remove the link from the body
      document.body.removeChild(link);
    } catch (error) {
      console.error('There was an error downloading the file:', error);
    }
  };
  console.log(props.row14);
  return (
    <tr
      className="text-[0.972vw] py-2 font-satoshi border-b border-dashed cursor-pointer"
      style={props.style}
    >
      <th
        scope="row"
        class="flex gap-2 items-center px-3 font-medium text-[12px] text-[#F2F9FC] whitespace-nowrap dark:text-white"
      >
        <span className="text-[0.972vw] text-[#A6AEBA] ml-4">
          {props.number}
        </span>
      </th>
      {showView && (
        <ModalViewJobRole1
          fileUrl={`${process.env.REACT_APP_URL_DOC}/jds/${props.row17}`}
          setShowView={setShowView}
        />
      )}
      <td class="px-3">{props.row12}</td>
      <td
        className="w-[26%] px-6 py-4 relative"
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
      >
        {truncatedText}
        {showTooltip && (
          <div className="z-10 bg-black text-white text-xs rounded-lg py-1 px-2 absolute top-full left-0 ml-2 overflow-hidden">
            {props.row13}
          </div>
        )}
      </td>
      <td class="px-3">{props.row14}</td>
      <td class="px-3">{props.row15}</td>
      <td class="px-3">
        <p className="flex items-center justify-center text-[0.972vw]">
          {props.row16}
        </p>
      </td>
      {/* <td class="px-6 py-4">
        <p className="flex items-center justify-center text-[0.972vw]">
          {props.row18}
        </p>
      </td> */}
      <td class="px-3 flex gap-2 mt-2 w-max">
        <img
          src={eyeButton}
          alt="eye icon"
          onClick={() => {
            setShowView(true);
            // const fileUrl = `${process.env.REACT_APP_URL_DOC}/jds/${props.row17}`;
            // console.log(fileUrl);
            // navigate('/admin/jobRolePage', { state: { fileUrl: fileUrl } });
          }}
        />
        {/* <ModalViewJobRole
          isOpen={props.isModalOpenResume}
          onClose={props.closeModalResume}
        /> */}
        {/* <img
          src={downloadButton}
          alt="download icon"
          onClick={handleDownload}
        /> */}
        {/* {userType !== "2" && (
          <img
            src={props.editIcon}
            alt="edit icon"
            // onClick={openModalEditJobRole}
            onClick={() => {
              if (userType !== "2") {
                openModalEditJobRole();
              }
            }}
            editJobRole={editJobRole}
          />
        )} */}
        {userType !== '2' && (
          <img
            src={props.editIcon}
            alt="edit icon"
            // onClick={openModalEditJobRole}
            onClick={() => {
              // if (userType !== "2") {
              openModalEditJobRole();
            }}
            // }
            editJobRole={editJobRole}
          />
        )}

        {isClickable && userType !== '2' && (
          <img src={props.deleteIcon} alt="delete icon" onClick={openModal5} />
        )}
      </td>
    </tr>
  );
};

export const CampaignTable = (props) => {
  const navigate = useNavigate();
  let creator = sessionStorage.getItem('uid');
  console.log('creator', creator);
  console.log('props.creator', props.creator);
  const isClickable = creator === props.creator;
  let userType = sessionStorage.getItem('userType');
  const openModal6 = () => {
    if (isClickable) {
      props.setDeleteUid(props.uid);
      props.openDelete();
    } else {
      toast.info("You can't Delete this campaign !");
    }
  };
  const getStatus = (launched, row13) => {
    console.log('launched', launched);
    console.log('row13', row13);

    if (row13 === 'Mass' && launched === 2) {
      console.log('inside getstatus');
      return 'Completed';
    }

    const statusMap = {
      0: 'Not Completed',
      1: 'Not Completed',
      2: 'Not Completed',
      3: 'Completed',
    };

    return statusMap[launched];
  };

  console.log(props.launched);
  return (
    <tr
      style={props.style}
      className="text-[0.972vw] py-2 font-satoshi border-b border-dashed cursor-pointer"
    >
      <td className="text-[0.972vw] text-[#A6AEBA] px-8 ml-4">{props.number + 1}</td>

      <td class="px-3 py-4 ">{props.row12}</td>
      {/* <td class="px-3 ">{props.row13}</td> */}
       <td class="px-3">{props.row17}</td>
      {!props.isDash && <td class="px-3 ">{props.row13}</td>}
      {/* <td class="px-3 ">{props.row13}</td> */}
      {/* <td className="px-3 ">{getStatus(props.launched, props.row13)}</td> */}
      {!props.isDash && <td class="px-3 ">{props.row14}</td>}
      {/* <td class="px-3 ">{props.row14}</td> */}
      <td class="px-3 ">{props.row15}</td>
      {!props.isDash && <td class="px-3 ">{props.row16}</td>}

      {/* {userType !== "2" && <td class="px-6 py-4">{props.row19}</td>} */}
      <td class="px-3 ">
        <p
          className={`h-[2.031vw] w-[7vw] border-[1px] ${
            props.row18 === 'Completed'
              ? 'text-[#FC7F29] border-[#FC7F29]'
              : 'text-[#48C885] border-[#48C885]'
          } flex items-center justify-center rounded-[1.111vw] text-[0.972vw]`}
        >
          {props.row18}
        </p>
      </td>
      {!props.isDash && (
        <td class="px-3  justify-center flex gap-2 pt-2 w-max">
          <img
            src={eyeButton}
            alt="view icon"
            onClick={() => {
              navigate('/admin/view-campaigns', {
                state: {
                  uid: props.uid,
                  jr_name: props.row14,
                  start_date: props.row15,
                  end_date: props.row16,
                  campaign_type: props.row13,
                  name: props.row12,
                  desc: props.description,
                  status: props.row18,
                },
              });
            }}
          />
          {isClickable && userType !== '2' && (
            <img
              src={props.editIcon}
              alt="edit icon"
              aria-disabled={!isClickable}
              onClick={() => {
                if (isClickable) {
                  navigate('/editcampaign/addCampaign', {
                    state: {
                      uid: props.uid,
                      jr_name: props.row14,
                      start_date: props.row15,
                      end_date: props.row16,
                      campaign_type: props.row13,
                      name: props.row12,
                      jr_uid: props.jobRoleUid,
                      desc: props.description,
                      resumeOn: props.resumeOn,
                      codingOn: props.codingOn,
                      creator: props.creator,
                      skills: props.skills,
                    },
                  });
                }
              }}
            />
          )}

          {isClickable && userType !== '2' && (
            <img
              src={props.deleteIcon}
              aria-disabled={!isClickable}
              alt="delete icon"
              onClick={openModal6}
            />
          )}
        </td>
      )}
    </tr>
  );
};

export const ViewCampaignTable = (props) => {
  const [isModalOpen6, setIsModalOpen6] = useState(false);
  const [loader1, setLoader1] = useState(false);
  const [generateSelfAnalyticsPopup, setGenerateSelfAnalyticsPopup] =
    useState(false);
  console.log(props, 'ropssssssssssss');
  const navigate = useNavigate();
  const [showHover, setShowHover] = useState(false);
  const organization = sessionStorage.getItem('organization');
  const [messages, setMessages] = useState([]);
  //  console.log(props.row14)
  //   console.log(props.bulkCountExists, "bulkCountExists");
  const closeModal6 = () => {
    setIsModalOpen6(false);
  };
  const [redirectLink, setRedirectLink] = useState();
  const [count, setCount] = useState(0);

  const handleClick = (linkInfo) => {
    console.log(linkInfo, 'copyLink');
    if (linkInfo?.link) {
      let link = `${base_url}/interview/candidate/` + linkInfo?.link;
      copyToClipboard(link);
      toast.success('link copied', {
        toastStyle: {
          borderLeft: '5px solid #4caf50',
          backgroundColor: '#00112D',
          color: 'white',
        },
      });
    } else {
      toast.success('generate your link first', {
        toastStyle: {
          borderLeft: '5px solid #4caf50',
          backgroundColor: '#00112D',
          color: 'white',
        },
      });
    }
  };

  console.log(props.candidateId);
  const handleRegenerate = (id) => {
    console.log(id);
    props?.callApi();
    if (!props.candidateId.includes(id)) {
      props.setCandidateId((prev) => [...prev, id]);
    }
    generateUniqueLink(id);
  };
  // console.log('props.candVideo',props.candVideo)
  // const handleViewVideo = async () => {
  //   console.log('props.candVideo', props.candVideo);

  //   if (!props.candVideo || props.candVideo === "null") {
  //     const warningMessage = !props.candVideo ? "Recording not available." : "Recording is under progress.";
  //     toast.warning(warningMessage);
  //     return;
  //   }

  //   toast.warning("Recording is getting prepared to load.");

  //   const fullUrls = props.candVideo.map(videoUrl =>
  //     `${process.env.REACT_APP_URL_DOC.replace(/\/?$/, '/')}${videoUrl.replace(/^\//, '')}`
  //   );

  //   try {
  //     // Check if all URLs are valid
  //     for (const url of fullUrls) {
  //       const response = await fetch(url);
  //       if (!response.ok) {
  //         throw new Error("Network response was not ok for URL: " + url);
  //       }
  //     }

  //     // Navigate to the video page with all video URLs once they are confirmed to be valid
  //     navigate("/videopage", { state: { fileUrls: fullUrls } });
  //   } catch (error) {
  //     // Handle errors if any of the URLs are not valid
  //     toast.error(`Failed to load video: ${error.message}`);
  //   }
  // };
  const handleVideoOpen = async () => {
    setLoader1(true);
    const response = await handleApiResponse(
      '/api/v1/interview/get_or_merge_video/',
      {
        candidate_uid: props.uid,
      },
      1
    );
    console.log('API Response:', response, response.data);
    if (response.data.status_code === 200) {
      try {
        console.log(response?.data?.data?.video);
        setLoader1(false);
        //window.open(props.candVideo);
        window.open(`videopage?video=${response?.data.data?.video}`, '_blank');
      } catch (error) {
        setLoader1(false);
        toast.error(`Failed to load video: ${error.message}`, {
          toastStyle: {
            borderLeft: '5px solid #f44336',
          },
        });
      }
    } else if (response.data.status_code === 400) {
      setLoader1(false);
      toast.error(response.data.msg, {
        toastStyle: {
          borderLeft: '5px solid #f44336',
        },
      });
    }
  };

  const handleViewVideo = async () => {
    console.log('props.candVideo', props.candVideo);

    if (
      !props.candVideo ||
      props.candVideo.length === 0 ||
      props.candVideo === '[null]'
    ) {
      const warningMessage =
        !props.candVideo || props.candVideo.length === 0
          ? 'Recording not available.'
          : 'Recording is under progress.';
      toast.warning(warningMessage, {
        toastStyle: {
          borderLeft: '5px solid #ffeb3b',
        },
      });
      return;
    }

    const fullUrls = props.candVideo
      .map((videoUrl) => {
        if (!videoUrl) {
          console.error('Invalid video URL:', videoUrl);
          return null;
        }

        // return `${process.env.REACT_APP_URL_DOC}/${videoUrl}`;
        return `${videoUrl}`;
      })
      .filter((url) => url !== null);

    if (fullUrls.length === 0) {
      toast.warning('Recording not available.', {
        toastStyle: {
          borderLeft: '5px solid #ffeb3b',
        },
      });
      return;
    }

    try {
      // const videoBlobs = await Promise.all(
      //   fullUrls.map(async (url) => {
      //     const response = await fetch(url);
      //     if (!response.ok) {
      //       throw new Error("Network response was not ok for URL: " + url);
      //     }
      //     return await response.blob();
      //   })
      // );

      // const videoUrls = videoBlobs.map(blob => URL.createObjectURL(blob));
      // console.log('videoUrls', videoUrls);

      // Use sessionStorage to pass video URLs to the new tab
      // sessionStorage.setItem('fileUrls', JSON.stringify(videoUrls));
      sessionStorage.setItem('fileUrls', JSON.stringify(fullUrls));

      // Open the video page in a new tab
      window.open('/videopage', '_blank');
    } catch (error) {
      toast.error(`Failed to load video: ${error.message}`, {
        toastStyle: {
          borderLeft: '5px solid #f44336',
        },
      });
    }
  };

  const copyToClipboard = (link) => {
    const textarea = document.createElement('textarea');
    textarea.value = link;
    console.log(link);
    textarea.style.position = 'absolute';
    textarea.style.left = '-9999px';

    document.body.appendChild(textarea);
    textarea.select();

    try {
      const successful = document.execCommand('copy');
      if (successful) {
        console.log('Link copied to clipboard:', link);
      } else {
      }
    } catch (error) {
      toast.error(`Error copying link to clipboard: ${error}`, {
        toastStyle: {
          borderLeft: '5px solid #f44336',
        },
      });
    } finally {
      document.body.removeChild(textarea);
    }
  };
  console.log('uid', props.uid);
  const handleAnalyticsGeneration = async () => {
    
    try {
      setLoader1(true);
      const response = await handleApiResponse(
        '/api/v1/get_performance_status/',
        {
          task_id: props.taskId,
          uid: props.uid,
        },
        1
      );
      setLoader1(false);
      if (response?.code === 'token_not_valid') {
        navigate('/');
        sessionStorage.clear();
        return;
      }
      console.log(response?.data);
      console.log(response?.data?.data?.status);
      console.log(response?.data?.data?.status === 'SUCCESS');
      // console.log("isha", response?.data?.data?.result.overall_score)
      if (response?.data?.status_code === 200) {
        // console.log(response?.data?.data?.status==="PENDING")
        if (response?.data?.data?.status === 'PENDING') {
          toast.warning('Analytics is getting generated.', {
            toastStyle: {
              borderLeft: '5px solid #ffeb3b',
            },
          });
        } else if (response?.data?.data?.status === 'SUCCESS') {
          navigate(
            `/admin/analytics?uid=${encodeURIComponent(
              props.taskId
            )}&candPhoto=${encodeURIComponent(
              props.candPhoto
            )}&candName=${encodeURIComponent(
              props.name
            )}&candCompany=${encodeURIComponent(
              props.candCompany
            )}&candJobRole=${encodeURIComponent(
              props.candJobRole
            )}&candId=${encodeURIComponent(props.uid)}&candExp=${encodeURIComponent(props.exp)}`
          );
        } else if (response?.data?.data?.status === 'FAILURE') {
          // toast.warning("Analytics generation failed.");
          setGenerateSelfAnalyticsPopup(true);
        } else {
          toast.warning('Interview not completed by candidate.', {
            toastStyle: {
              borderLeft: '5px solid #ffeb3b',
            },
          });
        }
      } else if (response?.data.status_code === 400) {
        // setLoader(true);
        toast.warning('Interview is not completed.', {
          toastStyle: {
            borderLeft: '5px solid #ffeb3b',
          },
        });
        // setGenerateSelfAnalyticsPopup(true);
      } else {
        toast.error(response?.msg, {
          toastStyle: {
            borderLeft: '5px solid #f44336',
          },
        });
      }
     
    } catch (error) {
      // Handle errors here
    }
  };

  const downloadPDF = (messages) => {
    if (!messages || messages.length === 0) {
      toast.warning('Transcript not available.', {
        toastStyle: {
          borderLeft: '5px solid #ffeb3b',
        },
      });
      return;
    }

    const doc = new jsPDF();
    const pageHeight = doc.internal.pageSize.getHeight();
    const pageWidth = doc.internal.pageSize.getWidth();
    const margin = 20;
    const contentWidth = pageWidth - 2 * margin;
    const maxLineWidth = contentWidth * 0.7;
    const lineHeight = 8;
    const fontSize = 10;
    const messageGap = 10;
    const headerFontSize = 14;
    const headerGap = 15;
    let y = margin + 10;

    const drawPageBorder = () => {
      doc.setDrawColor(0, 0, 0);
      doc.roundedRect(
        margin,
        margin,
        contentWidth,
        pageHeight - 2 * margin,
        10,
        10
      );
    };

    const addWatermark = () => {
      const watermarkText = 'clevercruit';
      const fontSize = 50;
      const angle = 45;

      const x = pageWidth / 2;
      const y = pageHeight / 2;

      doc.setTextColor(230, 230, 230);
      doc.setFontSize(fontSize);
      doc.setFont('helvetica', 'bold');

      // Calculate the text width to center it properly
      const textWidth = doc.getTextWidth(watermarkText);
      const textHeight = fontSize / 2; // Approximate height of the text

      // Adjust coordinates to center the text
      const centerX = x - textWidth / 2;
      const centerY = y + textHeight / 2;

      doc.text(watermarkText, centerX, centerY, { angle });
    };

    const addHeader = () => {
      const interviewer = `Candidate Name: ${props.name}`;
      const jobRole = `Job Role: ${props.jr_name}`;

      doc.setFontSize(headerFontSize);
      doc.setFont('helvetica', 'bold');
      doc.setTextColor(0, 0, 0);

      const interviewerWidth = doc.getTextWidth(interviewer);
      const jobRoleWidth = doc.getTextWidth(jobRole);

      const totalHeaderWidth = interviewerWidth + jobRoleWidth + 20; // 20 is a buffer space

      const interviewerX = margin + 10;
      const jobRoleX = pageWidth - margin - jobRoleWidth - 10;

      if (totalHeaderWidth > contentWidth) {
        // If the total width exceeds the content width, display in two lines
        doc.text(interviewer, interviewerX, y);
        y += headerFontSize + 5; // Move to the next line with some gap
        doc.text(jobRole, interviewerX, y);
      } else {
        doc.text(interviewer, interviewerX, y);
        doc.text(jobRole, jobRoleX, y);
      }

      doc.setFontSize(fontSize);
      doc.setFont('helvetica', 'normal');

      y += headerGap;
    };

    const replaceCodingQuest = (text) => {
      return text.replace(/Coding_quest #:/g, '');
    };

    const addContent = () => {
      let lastMessageType = null;

      messages.forEach((message, index) => {
        const isAI = message.is_ai;
        const sender = isAI ? 'AI' : 'Candidate';
        const text = replaceCodingQuest(message.msg);
        const lines = doc.splitTextToSize(text, maxLineWidth - 10);

        const blockHeight = lines.length * lineHeight;

        if (y + blockHeight + messageGap > pageHeight - margin) {
          doc.addPage();
          addWatermark();
          drawPageBorder();
          y = margin + 10;
          addHeader();
          lastMessageType = null;
        }

        const labelX = isAI
          ? margin + 5
          : pageWidth - margin - 5 - doc.getTextWidth(`${sender}:`);
        const labelY = y;

        doc.setFont('helvetica', 'bold');
        doc.setFontSize(8);
        doc.setTextColor(100);
        doc.text(`${sender}:`, labelX, labelY);

        y += 8;

        const rectX = isAI ? margin + 5 : pageWidth - maxLineWidth - margin - 5;
        const rectWidth = maxLineWidth;
        let rectY = y - 5;

        if (rectY + blockHeight + 10 > pageHeight - margin) {
          doc.addPage();
          addWatermark();
          drawPageBorder();
          y = margin + 10;
          rectY = y - 5;
        }

        if (isAI) {
          doc.setFillColor(255, 248, 240);
        } else {
          doc.setFillColor(230, 255, 230);
        }

        doc.roundedRect(rectX, rectY, rectWidth, blockHeight + 10, 5, 5, 'F');

        const textX = rectX + 5;
        doc.setTextColor(0);
        doc.setFontSize(fontSize);
        doc.setFont('helvetica', 'normal');
        lines.forEach((line, lineIndex) => {
          doc.text(line, textX, y + 5);
          y += lineHeight;
        });

        y += messageGap;
      });
    };

    drawPageBorder();
    addWatermark();
    addHeader();
    addContent();

    doc.save(`${props.name}.pdf`);
  };

  const generateUniqueLink = async (id) => {
    const reqData = {
      campaign_uid: props.campUid,
      candidate_uid: id,
    };
    const response = await handleApiResponse(
      '/api/v1/interview/generate_unique_link/',
      reqData,
      1
    );
    console.log('this is status', response?.data?.status_code);
    if (response?.data?.status_code === 200) {
      let link =
        `${base_url}/interview/candidate/` + response?.data?.data?.link;

      //navigator.clipboard.writeText(link)
      copyToClipboard(link);
      if (getLocal('mail_share_type') !== '1') {
        toast.success('link has been sent to email !', {
          toastStyle: {
            borderLeft: '5px solid #4caf50',
            backgroundColor: '#00112D',
            color: 'white',
          },
        });
      }
      if (getLocal('mail_share_type') === '1') {
        toast.success('Regenerated and Link Copied.', {
          toastStyle: {
            borderLeft: '5px solid #4caf50',
            backgroundColor: '#00112D',
            color: 'white',
          },
        });
      }
      setRedirectLink(link);
      setCount((prev) => prev + 1);
    } else {
      toast.error(response?.data?.msg, {
        toastStyle: {
          borderLeft: '5px solid #f44336',
        },
      });
    }
  };

  const handleDownloadTranscript = async (e) => {
    try {
      const response = await handleApiResponse(
        '/api/v1/interview/data/get_candidate_transcript/',
        {
          candidate_uid: props.uid,
        },
        1
      );
      console.log('API Response:', response.data);
      if (response.data.status_code === 200) {
        console.log(response.data.data);
        const sortedMessages = response.data.data.sort((a, b) => a.id - b.id);
        setMessages(sortedMessages);
        downloadPDF(sortedMessages);
      }
    } catch (e) {}
  };
  console.log(props.counts);
  const allCountsGreaterOrEqualOne = props.counts.every((count) => count >= 1);
  return (
    <tr class="text-[0.972vw] bg-white border-b border-dashed cursor-pointer relative">
      {loader1 && <Loader />}
      {generateSelfAnalyticsPopup && (
        <ModalGenerateSelfAnalytics
          taskId={props.taskId}
          candPhoto={props.candPhoto}
          candUid={props.uid}
          name={props.name}
          candCompany={props.candCompany}
          candJobRole={props.candJobRole}
          onClose={() => {
            setGenerateSelfAnalyticsPopup(false);
          }}
        />
      )}
      <th
        scope="row"
        class="w-[15vw] flex gap-2 items-center px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
      >
        <img src={props.icon} alt="user image" />{' '}
        <span className="text-[0.972vw] text-[#A6AEBA]">{props.name}</span>
      </th>
      <td class="px-6 py-4">{props.row12}</td>
      <td class="px-6 py-4">{organization}</td>
      <td class="px-6 py-4">{props.row13}</td>
      <td class="px-6 py-4">{props.candId}</td>
      <td class="px-6 py-4">{props.candIntId || 'NA'}</td>
      <td class="px-6 py-4">
        <p className="w-[5.333vw] h-[2.731vw] bg-[#A6AEBA1A] flex items-center justify-center rounded-[1.111vw] font-semibold text-[0.972vw]">
          {props.row17}
        </p>
      </td>
      <td class="px-6 py-4 flex gap-2 w-max">
        <img
          src={eyeButton}
          alt="eye icon"
          onClick={handleAnalyticsGeneration}
          // onClick={
          //   // () =>
          //   // handleAnalyticsGeneration()
          //   // navigate(
          //   //   `/admin/analytics?uid=${encodeURIComponent(
          //   //     props.uid
          //   //   )}&candPhoto=${encodeURIComponent(
          //   //     props.candPhoto
          //   //   )}&candName=${encodeURIComponent(
          //   //     props.name
          //   //   )}&candCompany=${encodeURIComponent(
          //   //     props.candCompany
          //   //   )}&candJobRole=${encodeURIComponent(props.candJobRole)}`
          //   // )
          // }
        />
        {showHover && (
          <div className="absolute">
            <span className="absolute top-[-3.3rem] bg-fill-color-code1 text-text-color-code1 text-center p-1 rounded-xl w-[5.5rem] left-[-0.3rem]">
              Click to copy link
            </span>
          </div>
        )}
        {/* {props.candVideo && ( */}
        <AiTwotoneVideoCamera
          onClick={handleVideoOpen}
          className="w-[28px] h-[28px] border-2"
        />
        {/* )} */}
        <img
          src={downloadButton}
          alt="download icon"
          onClick={() => {
            handleDownloadTranscript();
          }}
        />
        {/* <CiCamera onClick={handleDownloadVideo}/> */}
        {props.campType === 'Regular' &&
          getLocal('mail_share_type') !== '1' && (
            <img
              src={LinkIcon}
              alt="link icon"
              onClick={() => handleClick(props.linkInfo)}
              onMouseEnter={() => setShowHover(true)}
              onMouseLeave={() => setShowHover(false)}
            />
          )}
        {/* {props?.candidateId?.includes(props?.uid) && */}
        {props.campType === 'Regular' &&
          getLocal('mail_share_type') !== '1' && (
            <button
              className="border-2 p-1"
              onClick={() => handleRegenerate(props.uid)}
            >
              Re-generate Link
            </button>
          )}
        {props.campType === 'Regular' &&
          getLocal('mail_share_type') === '1' &&
          (props.downloadLinksClicked || allCountsGreaterOrEqualOne) && (
            <>
              <img
                src={LinkIcon}
                alt="link icon"
                onClick={() => handleClick(props.linkInfo)}
                onMouseEnter={() => setShowHover(true)}
                onMouseLeave={() => setShowHover(false)}
              />
              <button
                className="border-2 p-1"
                onClick={() => handleRegenerate(props.uid)}
              >
                Re-generate Link
              </button>
            </>
          )}
      </td>
    </tr>
  );
};
