import React from 'react';
import { PieChart, Pie, Cell, Tooltip, Label } from 'recharts';

const SuspiciousChart = ({ totalData }) => {
  console.log(totalData, 'totaddff');
  const data = [
    {
      name: "Don't manipulate",
      value: Number(totalData?.dontManPer),
      color: '#d57d00',
    },
    { name: 'No face', value: Number(totalData?.noFacePer), color: '#975ced' },
    {
      name: 'Multi-face',
      value: Number(totalData?.multiface),
      color: '#9c9898',
    },
    // { name: 'overAll', value: Number(totalData?.overAll), color: '#d5b1a4' },
  ];

  const title = totalData?.total;
  return (
    <div style={{ width: '100%', height: 250, position: 'relative' }}>
      <PieChart width={250} height={250}>
        <Pie
          data={data}
          dataKey="value"
          innerRadius="55%"
          outerRadius="80%"
          startAngle={65}
          endAngle={-300}
          cornerRadius={18}
          labelLine={false}
          paddingAngle={0.1}
          strokeWidth={15}
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry.color} />
          ))}
          <Label
            value={title}
            position="center"
            style={{
              fontSize: '30px',
              fontWeight: 'bold',
              textAnchor: 'middle',
            }}
          />
        </Pie>
        {/* <Tooltip /> */}
      </PieChart>
    </div>
  );
};

export default SuspiciousChart;
