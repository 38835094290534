import React, { useState } from 'react';
import { group1, clevercruit } from '../assets/imgs';
import { Outlet } from 'react-router-dom';
import { getLocal } from '../utils/localStorage';

const BackgroundLayout = () => {
  const fname = getLocal('candFName');
  const lname = getLocal('candLName');

  return (
    <div className="h-screen items-center">
      <div className=" flex flex-col h-screen text-white flex justify-center items-center">
        {/* <div className=''
          style={{
            backgroundImage:
              'linear-gradient(12.06deg, rgba(0, 77, 244, 0) 13.7%, rgba(0, 77, 244, 0.4) 27.55%, #004DF4 36.4%, #26DDFF 53.88%, rgba(38, 221, 255, 0) 89.18%)',
          }}
        > */}
        <div
          className=" w-[97vw] h-[96vh] rounded-[24px]  overflow-hidden "
          style={{
            border: '1px solid',
            borderImageSource:
              'linear-gradient(3.06deg, rgba(0, 77, 244, 0) 13.7%, rgba(0, 77, 244, 0.4) 27.55%, #004DF4 36.4%, #26DDFF 53.88%, rgba(38, 221, 255, 0) 89.18%)',
            borderImageSlice: 1,
          }}
        >
          <div className="flex justify-around gap-4 min-h-[4.5rem]">
            <div className="w-full rounded-t-[24px] flex items-center justify-between py-4 px-4 border-x-[1.125rem] border-t-[1.125rem] border-[#286497] border-opacity-[27%] ">
              <div
                className="  rounded-[8px] shadow-2xl p-2 w-full  flex felx-col fill-available justify-between items-center relative"
                style={{
                  background:
                    'linear-gradient(to bottom, rgb(27,51,81,0.3) 16%, rgb(40,100,151,0.3) 93%)',
                  border: '1px solid',
                  borderImageSource:
                    'linear-gradient( #144B8C 7%, rgba(0, 0, 0, 0.15) 10%,  rgba(0, 0, 0, 0.15) 73%, rgba(0, 0, 0, 0.15) 93%',
                  borderImageSlice: 1,
                  backgroundClip: 'border-box',
                }}
              >
                <img
                  src={clevercruit}
                  alt="clevercruit logo"
                  className="pl-2 h-8 "
                />
                {fname && (
                  <p className="ml-2 font-extralight mr-2 text-[18px]">
                    Hi, {fname} {lname}!
                  </p>
                )}
              </div>
            </div>
          </div>

          <div className="  backdrop-blur bg-[rgb(40,100,151,0.27)] h-available flex flex-col items-center justify-between  rounded-b-[24px] overflow-auto ">
            <Outlet />
          </div>
        </div>
        {/* </div> */}
      </div>
    </div>
  );
};

export default BackgroundLayout;
