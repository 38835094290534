import { builderButton, campaignbg, candidateButton, clevercruit, clevercruitlogo, detailsButton } from "../assets/imgs";
import { CampaignHeader } from "../components/AdminComponents/campaignHeader";
import { Outlet } from "react-router-dom";

export const CampaignLayout = () => {
  return (
    <header className="h-[100vh] w-full flex"  style={{
      backgroundImage: `url(${campaignbg})`,
      backgroundSize: 'cover, cover', // Adjust sizes if necessary
      backgroundPosition: 'center, center', // Adjust positions if necessary
      backgroundRepeat: 'no-repeat, no-repeat', // Ensure images don't repeat
    }}>
      {/* <div className="flex flex-col">
        <img src={clevercruitlogo} className="w-[160px] h-[32px] m-6"/>
        <img src={detailsButton} className="w-[430px] h-[124px] m-6"/>
        <img src={builderButton} className="w-[430px] h-[124px] m-6"/>
        <img src={candidateButton} className="w-[430px] h-[124px] m-6"/>
        </div> */}
      <div className="flex flex-col gap-[1.1875rem] fill-available fill-available-firefox">
      <CampaignHeader heading={"Add Campaign"} />
      <div className="flex flex-col h-full">
      <Outlet />
      </div>
      </div>
    </header>
  );
};
