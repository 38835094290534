import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { certificatebg, logo, signature } from "../../assets/imgs";
import { useLocation } from "react-router-dom";

const Certificatepage = () => {
  const location = useLocation();
  const firstName = sessionStorage.getItem("candFName");
  const jobRole = sessionStorage.getItem("roleName");
  const lastName = sessionStorage.getItem("candLName");
  const warningCount = sessionStorage.getItem("wc");
  const on_face_detect = sessionStorage.getItem("no_face_detect_count");
  const networkError = sessionStorage.getItem("networkError");

  const first_name = useSelector(
    (state) => state?.certificateData?.data?.first_name
  );
  const last_name = useSelector(
    (state) => state?.certificateData?.data?.last_name
  );
  const job_role = useSelector(
    (state) => state?.certificateData?.data?.campign_info?.jr_name
  );

  const date = useSelector((state) => state?.certificateData.data.created_date);

  const [formattedDateTime, setFormattedDateTime] = useState("");

  useEffect(() => {
    sessionStorage.removeItem("newExp");
    sessionStorage.removeItem("newExpMonths");
    sessionStorage.removeItem("file");
  });
  useEffect(() => {
    const currentDate = new Date();
    const formattedDate = `${currentDate
      .getDate()
      .toString()
      .padStart(2, "0")}/${(currentDate.getMonth() + 1)
        .toString()
        .padStart(2, "0")}/${currentDate.getFullYear()}`;
    const formattedTime = `${currentDate
      .getHours()
      .toString()
      .padStart(2, "0")}:${currentDate
        .getMinutes()
        .toString()
        .padStart(2, "0")}`;
    setFormattedDateTime(`${formattedDate} - ${formattedTime} IST`);
  }, []);

  return (

    <div
      className="w-[495.2px] h-[350px] relative mb-4"
    >
      <img src={certificatebg} className='h-[350px]' />
      <div className="absolute top-10 left-10 gap-[12px]">
        <div className="text-[#0875F4] text-[36px] text-bold">Certificate of Completion</div>
        <div className="font-light text-[#414752] text-[16px]">{new Date().toLocaleDateString('en-GB')} 
          {/* <span className="text-[10px]">in</span> 30 Hours */}
        </div>
        <div className="font-medium text-[20px] text-[#00112D]">
          {first_name ? first_name : firstName}{" "}
          {last_name ? last_name : lastName}
        </div>
        <div className="text-[#414752] text-[10px]">has successfully completed</div>
        <div className="text-[#414752] text-[10px]">interview for {job_role ? job_role : jobRole}
        </div>
      </div>
      <div className="absolute bottom-14 left-[30%]">
        <div className="font-medium text-[10px] text-[#414752]">Mohsen Ghalenavi</div>
        <div className="font-light text-[8px]">Head of the Clevercruit</div>
      </div>
    </div>

  );
};

export default Certificatepage;


{/* <div className="mb-6"> */ }
{/* <img
      src={logo}
      alt="Cloud Academy Logo"
      className="w-60 mx-auto mb-2"
    /> */}
{/* <h2 className="text-3xl text-blue-600 font-semibold mb-4">
      {first_name ? first_name : firstName}{" "}
      {last_name ? last_name : lastName}
    </h2> */}
{/* <h1 className="text-4xl font-bold">GOT INTERVIEWED BY AI</h1> */ }
{/* <p className="text-xl">OF ACHIEVEMENT</p> */ }
{/* </div>
  <p className="text-lg">for {job_role}</p>
  <div className="flex justify-between items-center mt-24">
    <p className="text-md">{formattedDateTime}</p>
    <div className="text-center flex flex-col justify-center">
      <img
        src={signature}
        alt="Cloud Academy Logo"
        className="w-48 mx-auto mb-2"
      />
      <p className="text-md">Authorized</p>
      <p className="text-sm">Signature</p>
    </div>
  </div> 
</div> */}